var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height noprint",staticStyle:{"width":"100% !important"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-4",attrs:{"cols":_vm.GET_panelLayout.leftPanel}},[_c('page-title',{attrs:{"icon":"icons8-skyscrapers","pageTitle":"Sites","description":"Add Sites as required. If Users are required to SWAPP in to a site, it must also have a location.","divider":true}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.width < 600 ? 12 : 6}},[_c('app-input',{attrs:{"input-type":"textInput","label":"Site Name...","append-icon":'icons8-search'},model:{value:(_vm.searchBySiteName),callback:function ($$v) {_vm.searchBySiteName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchBySiteName"}})],1)],1),(_vm.$vuetify.breakpoint.width >= 600 && _vm.GET_currentUser.userLevel === 'SA')?_c('div',{staticClass:"d-flex justify-end align-end mt-4"},[_c('app-btn',{attrs:{"color":"primary mr-4","hide-label-below":"1060","icon":"icons8-select-column","label":"Columns"},nativeOn:{"click":function($event){_vm.columnsDialog = true}}}),_c('app-btn',{attrs:{"color":"primary","hide-label-below":"1060","icon":"icons8-export-csv","label":"Export"},nativeOn:{"click":function($event){return _vm.MIX_exportDocuments(_vm.headersCSV,'sites',_vm.formatExport)}}})],1):_vm._e(),(_vm.$vuetify.breakpoint.width >= 600)?_c('v-data-table',{staticClass:"rounded-lg mt-4",attrs:{"headers":_vm.computedHeaders,"items":_vm.computedTableData,"item-key":"id"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.id))])]}},{key:"item.siteName",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.siteName))])]}},{key:"item.siteTelephone",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.siteTelephone))])]}},{key:"item.siteContact.contactName",fn:function(ref){
var item = ref.item;
return [_c('sites-manager-selection-modal',{attrs:{"site":item,"users":_vm.usersData},on:{"updateManager":_vm.updateWithNewManager}})]}},{key:"item.siteAddress.addressLine1",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.siteAddress.addressLine1))])]}},{key:"item.siteAddress.addressLine2",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.siteAddress.addressLine2))])]}},{key:"item.siteAddress.addressLine3",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.siteAddress.addressLine3))])]}},{key:"item.siteAddress.town",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.siteAddress.town))])]}},{key:"item.siteAddress.county",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.siteAddress.county))])]}},{key:"item.siteAddress.postcode",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.siteAddress.postcode))])]}},{key:"item.siteContact.contactPosition",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.siteContact.contactPosition))])]}},{key:"item.siteContact.contactTelephone",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.siteContact.contactTelephone))])]}},{key:"item.siteContact.contactMobile",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.siteContact.contactMobile))])]}},{key:"item.siteContact.contactEmail",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.siteContact.contactEmail))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"primary--text white",attrs:{"depressed":""},on:{"click":function($event){return _vm.openItem(item.id, 'View', false)}}},[_c('v-icon',{staticClass:"icons8-forward",attrs:{"color":"primary"}})],1)]}}],null,true)}):_vm._e(),(_vm.$vuetify.breakpoint.width < 600)?_c('div',_vm._l((_vm.computedTableData),function(site){return _c('v-card',{key:site.id,staticClass:"rounded-lg mt-4 pa-4",attrs:{"flat":""},on:{"click":function($event){return _vm.openItem(site.id, 'View', false)}}},[_c('app-text',{attrs:{"category":"text-default-bold"}},[_vm._v(_vm._s(site.siteName))]),_c('app-text',[_vm._v(_vm._s(_vm.computedSiteAddress(site)))])],1)}),1):_vm._e()],1),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn animated__faster","leave-active-class":"animate__animated animate__fadeOut animated__faster","mode":"out-in"}},[_c('rightpanel')],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"300px"},model:{value:(_vm.columnsDialog),callback:function ($$v) {_vm.columnsDialog=$$v},expression:"columnsDialog"}},[_c('v-card',[_c('app-text',{staticClass:"primary--text ma-4",attrs:{"category":"text-medium"}},[_vm._v("Show/Hide Columns")]),_c('v-divider'),_c('v-card-text',[_c('v-list',_vm._l((_vm.headers.slice(0, -1)),function(header,index){return _c('v-list-item',{key:index},[_c('app-text',[_c('v-checkbox',{attrs:{"color":"grey darken-1","hide-details":"","false-value":true,"true-value":false,"label":header.text},model:{value:(_vm.headers[index].hidden),callback:function ($$v) {_vm.$set(_vm.headers[index], "hidden", $$v)},expression:"headers[index].hidden"}})],1)],1)}),1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"text-right"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.columnsDialog = false}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }