var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('page-loading',{attrs:{"is-page-loading":_vm.isPageLoading}}),(!_vm.computedIsPageLoading)?_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-4"},[_c('page-title',{attrs:{"icon":"icons8-fires","page-title":"Fire Roll Call","description":"Initiate a new, or view past Fire Roll Calls.","divider":true}}),_c('div',{staticClass:"d-flex justify-end"},[(_vm.$vuetify.breakpoint.width >= 600)?_c('v-spacer'):_vm._e(),(!_vm.isFireRegisterVisible)?_c('fire-roll-call-start-dialog',{attrs:{"sitesData":_vm.sitesCollectionData},on:{"emitHandleStartRegister":_vm.emittedHandleStartRegister}}):_vm._e(),(_vm.isFireRegisterVisible)?_c('fire-roll-call-cancel-dialog',{on:{"emitHandleCancelRegister":_vm.emittedHandleCancelRegister}}):_vm._e()],1),(_vm.$vuetify.breakpoint.width >= 600 && !_vm.isFireRegisterVisible)?_c('v-data-table',{staticClass:"rounded-lg mt-4",attrs:{"headers":_vm.tableHeaders,"items":_vm.fireRollCallCollectionData},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.title))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.type))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(" "+_vm._s(_vm.$moment(Number(item.createdDateTime)).format('dddd Do MMMM YYYY @ HH:mm'))+" ")])]}},{key:"item.open",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"primary--text white pa-4",attrs:{"depressed":""},on:{"click":function($event){return _vm.openDocument(item.id, 'View', false)}}},[_c('v-icon',{staticClass:"icons8-forward",attrs:{"color":"primary"}})],1)]}}],null,false,2948609933)}):_vm._e(),(_vm.$vuetify.breakpoint.width < 600 && !_vm.isFireRegisterVisible)?_c('div',[(!_vm.fireRollCallCollectionData.length)?_c('app-text',{staticClass:"justify-center text-center mt-4",attrs:{"category":"text-default"}},[_vm._v(" There are no Fire Roll Calls to show ")]):_vm._e(),_vm._l((_vm.fireRollCallCollectionData),function(rollCall){return _c('div',[_c('v-card',{staticClass:"rounded-lg mt-4 pa-4",attrs:{"flat":""},on:{"click":function($event){return _vm.openDocument(rollCall.id, 'View', false)}}},[_c('app-text',{attrs:{"category":"text-default-bold"}},[_vm._v(_vm._s(rollCall.title))]),_c('div',{staticClass:"d-flex"},[_c('app-text',{attrs:{"category":"text-default"}},[_vm._v(" "+_vm._s(rollCall.type)+" ")]),_c('v-spacer'),_c('app-text',{attrs:{"category":"text-default"}},[_vm._v(" "+_vm._s(_vm.$moment(Number(rollCall.createdDateTime)).format('DD MMMM YYYY @ HH:mm'))+" ")])],1)],1)],1)})],2):_vm._e(),(_vm.isFireRegisterVisible)?_c('fire-roll-call-register',{attrs:{"users":_vm.computedSiteUsersData},on:{"emitCloseRegister":function($event){_vm.isFireRegisterVisible = false}}}):_vm._e()],1),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__slideIn animated__faster","leave-active-class":"animate__animated animate__fadeOut animated__faster","mode":"out-in"}},[_c('rightpanel')],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }