<!--
Concerns

Renders the Concerns page displaying a list of concerns (table on tablets and desktops, cards on mobile).
-->
<template>
    <v-row no-gutters class="fill-height" style="height: 100% !important">

        <!--Left Panel-->
        <v-col :cols="GET_panelLayout.leftPanel" class="pa-4">

            <!--Page title-->
            <page-title icon="icons8-eye"
                        pageTitle="Observations"
                        description="Create, view and edit Observations. If you discover an issue, report it so it can be investigated."
                        :divider="true"/>

            <!--Searches-->
            <v-row no-gutters>

                <!--Issue-->
                <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                       :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'">
                    <app-input inputType="textInput"
							   append-icon="icons8-search"
                               :clearable="true"
                               label="Issue..."
                               v-model="searchByIssue"/>

                </v-col>

                <!--Type-->
                <v-col v-if="$vuetify.breakpoint.width >= 600 && ['SA'].includes(GET_currentUser.userLevel)"
                       :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                       :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'">

                    <app-input inputType="select"
                               :clearable="true"
                               :items="observationTypesOptionsData"
                               label="Type..."
                               v-model="filter.type"/>

                </v-col>

            </v-row>

            <!--Status filters | Action buttons-->
            <div class="d-flex align-center mt-4" style="width: 100%">

                <!--Status filters-->
                <v-btn-toggle active-class="white"
                              background-color="grey"
                              class="rounded-lg"
                              color="white"
                              multiple
                              style="height: 48px"
                              :style="$vuetify.breakpoint.width < 600 && 'width: 100%'"
                              v-model="filter.status">

                    <!--Pending-->
                    <v-btn :height="buttonSizeDefault"
                           :style="$vuetify.breakpoint.width < 600 && 'width: calc(100% / 3)'"
                           value="Pending">
                        <v-icon color="error" class="icons8-inactive-state"/>
                        <app-text v-if="$vuetify.breakpoint.width >= 416"
                                  category="text-small" class="grey--text">
                            {{ $t(`status.pending`) }}
                        </app-text>
                    </v-btn>

                    <!--In Progress-->
                    <v-btn :height="buttonSizeDefault"
                           :style="$vuetify.breakpoint.width < 600 && 'width: calc(100% / 3)'"
                           value="In Progress">
                        <v-icon color="orange" class="icons8-circle"/>
                        <app-text v-if="$vuetify.breakpoint.width >= 416"
                                  category="text-small" class="grey--text">
                            In Progress
                        </app-text>
                    </v-btn>

                    <!--Resolved-->
                    <v-btn :height="buttonSizeDefault"
                           :style="$vuetify.breakpoint.width < 600 && 'width: calc(100% / 3)'"
                           value="Resolved">
                        <v-icon color="success" class="icons8-checkmark-yes"/>
                        <app-text v-if="$vuetify.breakpoint.width >= 416"
                                  category="text-small" class="grey--text">
                            {{ $t(`status.resolved`) }}
                        </app-text>
                    </v-btn>

                </v-btn-toggle>

                <v-spacer/>

                <!--Add Button-->
                <app-btn v-if="$vuetify.breakpoint.width > 864"
                         @click.native="openItem('', 'New', false)"
                         icon="icons8-eye"
                         label="New"/>

                <!--Columns | Export-->
                <div v-if="['SA'].includes(GET_currentUser.userLevel) && $vuetify.breakpoint.width > 600"
                     class="d-flex align-center ml-4">

                    <!--Columns-->
                    <app-btn @click.native="columnsDialog = true"
                             class="mr-4"
                             color="primary"
                             hideLabelBelow="1032"
                             icon="icons8-select-column"
                             label="Columns"/>

                    <!--Export-->
                    <app-btn @click.native="MIX_exportDocuments(headersCSV,'Observations',formatExport)"
                             color="primary"
                             hideLabelBelow="1032"
                             icon="icons8-export-csv"
                             label="Export"/>

                </div>

            </div>

            <!--Add Observation-->
            <app-btn v-if="$vuetify.breakpoint.width <= 864"
                     @click.native="openItem('', 'New', false)"
                     block
                     class="primary mt-4"
                     icon="icons8-eye"
                     label="New Observation"/>

            <!--Table-->
            <v-data-table v-if="$vuetify.breakpoint.width >= 600"
                          class="rounded-lg mt-4"
                          :headers="computedHeaders"
                          :items="computedTableData"
                          item-key="id">

                <!--ID-->
                <template v-slot:[`item.id`]="{ item }">
                    <app-text category="text-small"
                              v-if="item.delete !== null && item.delete !== '' && item.delete !== undefined"
                              class="error--text d-inline-block text-truncate" style="max-width: 200px;">
                        <v-icon color="error" class="icons8-trash"/>
                        <app-text category="text-small">{{ item.id }}</app-text>
                    </app-text>
                    <app-text category="text-small" v-else class="d-inline-block text-truncate"
                              style="max-width: 200px;">{{ item.id }}
                    </app-text>
                </template>

                <!--Status-->
                <template v-slot:[`item.observationStatus`]="{ item }">
                    <v-icon v-if="item.observationStatus === 'Pending'"
                            color="error"
                            class="icons8-inactive-state display-1 d-flex justify-start"/>
                    <v-icon v-if="item.observationStatus === 'In Progress'"
                            color="orange"
                            class="icons8-circle display-1 d-flex justify-start"/>
                    <v-icon v-if="item.observationStatus === 'Resolved'"
                            color="success"
                            class="icons8-checkmark-yes display-1 d-flex justify-start"/>
                </template>

                <!--Short Description (issue)-->
                <template v-slot:[`item.observationReportingShortDescription`]="{ item }">
                    <app-text category="text-small"
                              v-if="item.delete !== null && item.delete !== '' && item.delete !== undefined"
                              class="error--text d-inline-block text-truncate ">
                        <v-icon color="error" class="icons8-trash"/>
                        {{ item.observationReportingShortDescription }}
                    </app-text>
                    <app-text v-else category="text-small" class="">
                        {{ item.observationReportingShortDescription }}
                    </app-text>
                </template>

                <!--Type-->
                <template v-slot:[`item.observationReportingType`]="{ item }">
                    <app-text category="text-small">{{ item.observationReportingType }}</app-text>
                </template>

                <!--Created User Data-->
                <template v-slot:[`item.createdUserData`]="{ item }">
                    <app-text category="text-small">{{ item.createdUserData.userName }}</app-text>
                </template>

                <!--Created Date-->
                <template v-slot:[`item.createdDateTime`]="{ item }">
                    <app-text category="text-small">
                        {{ item.createdDateTime }}
                    </app-text>
                </template>

                <!--Modified Date-->
                <template v-slot:[`item.modifiedDateTime`]="{ item }">
                    <app-text category="text-small">
                        {{ item.modifiedDateTime }}
                    </app-text>
                </template>

                <!--Modified User Data-->
                <template v-slot:[`item.modifiedUserData`]="{ item }">
                    <app-text category="text-small">{{ item.modifiedUserData.userName }}</app-text>
                </template>

                <!--Action-->
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn @click="openItem(item.id, 'View', false)"
                           depressed class="primary--text white">
                        <v-icon color="primary" class="icons8-forward pa-0"/>
                    </v-btn>
                </template>

            </v-data-table>

            <!--Mobile Cards-->
            <div v-if="$vuetify.breakpoint.width < 600">

                <app-text v-if="!computedTableData.length" class="text-center mt-4">
                    There are no Observations to show
                </app-text>

                <div v-for="item in computedTableData"
                     @click="openItem(item.id, 'View', false)" :key="item.id"
                     class="white rounded-lg">

                    <div class="d-flex align-center mt-4 pa-4">

                        <!--Status icons-->
                        <div class="flex-grow-0 mr-4">

                            <v-icon v-if="item.observationStatus === 'Pending'"
                                    color="error"
                                    class="icons8-inactive-state"
                                    size="32"/>

                            <v-icon v-if="item.observationStatus === 'In Progress'"
                                    color="orange"
                                    class="icons8-circle"
                                    size="32"/>

                            <v-icon v-if="item.observationStatus === 'Resolved'"
                                    color="success"
                                    class="icons8-checkmark-yes"
                                    size="32"/>

                        </div>

                        <!--Observation Details - (Name | Type | Date)-->
                        <div class="flex-grow-1">

                            <!--Observation name-->
                            <app-text category="text-default-bold">
                                {{ item.observationReportingShortDescription }}
                            </app-text>

                            <!--Observation type-->
                            <app-text category="text-small" class="grey--text">
                                {{ item.observationType }}
                            </app-text>

                            <!--Observation date-->
                            <app-text category="text-small-bold" class="grey--text noselect">
                                {{ item.createdDateTime }}
                            </app-text>

                        </div>

                        <!--Action button-->
                        <div class="flex-grow-0">
                            <v-icon @click="openItem(item.id, 'View', false)"
                                    class="icons8-forward" color="primary"/>
                        </div>

                    </div>

                </div>
            </div>

        </v-col>

        <!--Right Panel-->
        <transition name="custom-classes-transition"
                    enter-active-class="animate__animated animate__fadeIn animate__faster"
                    leave-active-class="animate__animated animate__fadeOut animate__faster"
                    mode="out-in">
            <rightpanel></rightpanel>
        </transition>

        <!--Columns Dialog Box-->
        <v-dialog v-model="columnsDialog" scrollable max-width="300px">
            <v-card>

                <!--Title-->
                <app-text category="text-large" class="primary--text pa-4">{{ $t('miscHeadings.showHideColumns') }}
                </app-text>

                <v-divider/>

                <!--List items-->
                <v-card-text>
                    <v-list>
                        <v-list-item v-for="(header, index) in headers.slice(0, -1)" :key="index">
                            <app-text>
                                <v-checkbox color="grey darken-1" hide-details v-model="headers[index].hidden"
                                            :false-value="true" :true-value="false"
                                            :label="header.text"></v-checkbox>
                            </app-text>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-divider/>

                <!--Close button-->
                <v-card-actions class="text-right">
                    <v-spacer/>
                    <v-btn color="primary" text @click="columnsDialog = false">Close</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

    </v-row>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {

    name: "Observations",

    data: () => ({
        title: "Observation",
        collection: "observations",
        collectionItem: "observation",
        collectionTitle: "Observations",
        collectionItemTitle: "Observation",
        columnsDialog: false,
        exportObservations: [],
        filter: {
            sortAsc: false,
            status: ['Pending', 'In Progress'],
            type: '',
        },
        headers: [
            {text: 'ID', value: 'id', sortable: false, align: 'left', hidden: true, hide: false, width: '20px'},
            {
                text: 'Status',
                value: 'observationStatus',
                sortable: false,
                align: 'left',
                hidden: false,
                hide: false,
                width: '69px'
            },
            {
                text: 'Issue',
                value: 'observationReportingShortDescription',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: 'Type',
                value: 'observationReportingType',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: 'Reported By',
                value: 'createdUserData',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: true,
            },
            {
                text: 'Reported On',
                value: 'createdDateTime',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: 'Modified',
                value: 'modifiedDateTime',
                align: 'left',
                sortable: false,
                hidden: true,
                hide: false,
            },
            {
                text: 'Modified By',
                value: 'modifiedUserData',
                align: 'left',
                sortable: false,
                hidden: true,
                hide: false,
            },
            {text: '', value: 'actions', align: 'right', sortable: false, hidden: false, width: '96px'},
        ],
        headersCSV: {
            observationStatus: 'Observation Status',
            observationReportingShortDescription: 'Observation Name',
            observationType: 'Observation Type',
            observationSite: 'Observation Site',
            observationReportedBy: 'Reported By',
            observationReportedOn: 'Reported On',
            observationModified: 'Modified',
            observationModifiedBy: 'Modified By',
        },
        langPath: "observations",
        observationSeverities: [],
        observationTypes: [],
        searchByIssue: '',
        showDeleted: false,
        tableData: [],
    }),

    computed: {
        ...mapGetters({
            GET_panelLayout: 'GET_panelLayout',
            GET_currentUser: 'GET_currentUser',
            GET_currentUserData: 'GET_currentUserData',
            GET_lookupValues: 'GET_lookupValues',
            GET_help: 'GET_help'
        }),

        //Format of the Export File
        formatExport() {
            const t = this
            const observations = JSON.parse(JSON.stringify(t.computedTableData));
            t.exportObservations = [];

            for (let i = 0; i < observations.length; i++) {
                const observationStatus = "";
                if (observations[i].observationStatus) {
                    observationStatus = observations[i].observationStatus;
                } else {
                    observationStatus = "UNKNOWN"
                }
                const observationName = "";
                if (observations[i].observationReportingShortDescription) {
                    observationName = observations[i].observationReportingShortDescription;
                } else {
                    observationName = "UNKNOWN"
                }
                const observationType = "";
                if (observations[i].observationReportingType) {
                    observationType = observations[i].observationReportingType;
                } else {
                    observationType = "UNKNOWN"
                }
                const observationReportedBy = "";
                if (observations[i].createdUserData.userName) {
                    observationReportedBy = observations[i].createdUserData.userName;
                } else {
                    observationReportedBy = "UNKNOWN"
                }
                const observationReportedOn = "";
                if (observations[i].createdDateTime) {
                    observationReportedOn = observations[i].createdDateTime;
                } else {
                    observationReportedOn = "UNKNOWN"
                }
                const observationModified = "";
                if (observations[i].modifiedDateTime) {
                    observationModified = observations[i].modifiedDateTime;
                } else {
                    observationModified = "UNKNOWN"
                }
                const observationModifiedBy = "";
                if (observations[i].modifiedUserData.userName) {
                    observationModifiedBy = observations[i].modifiedUserData.userName;
                } else {
                    observationModifiedBy = "UNKNOWN"
                }

                t.exportObservations.push({
                    observationStatus: observationStatus,
                    observationName: observationName,
                    observationReportingType: observationType,
                    observationReportedBy: observationReportedBy,
                    observationReportedOn: observationReportedOn,
                    observationModified: observationModified,
                    observationModifiedBy: observationModifiedBy,
                })
            }
            return t.exportObservations;
        },

        // Computed Table Headers
        computedHeaders() {
            const t = this;
            const headers = t.headers;
            return (t.$filter(headers, {
                hidden: false, // filter headers based on initial hidden value
            }));
        },

        // Computed Table Data
        computedTableData() {
            const t = this
            let tableData = t.tableData

            // Tenants can only view their own reports
            if (t.GET_currentUser.userType === 'Tenant') {
                tableData = tableData.filter(observation =>
                    observation.createdUserData.userId === t.GET_currentUser.id)
            }

            // Filter by status
            if (t.filter.status.length) {
                tableData = tableData.filter(observation =>
                    t.filter.status.includes(observation.observationStatus))
            }

            // Filter by type
            if (t.filter.type) {
                tableData = tableData.filter(observation =>
                    observation.observationReportingType === t.filter.type)
            }

            // Search By Issue
            if (t.searchByIssue) {
                tableData = tableData.filter(observation =>
                    observation.observationReportingShortDescription.toUpperCase().match(t.searchByIssue.toUpperCase()))
            }

            let tableDataList = tableData.map(e => {
                return {
                    id: e.id,
                    observationReportingShortDescription: e.observationReportingShortDescription,
                    observationReportingType: e.observationReportingType,
                    observationStatus: e.observationStatus,
                    createdDateTime: t.MIX_formatDateTime(e.createdDateTime, 'x', 'Do MMM YY @ HH:mm'),
                    createdUserData: e.createdUserData,
                    modifiedDateTime: t.MIX_formatDateTime(e.modifiedDateTime, 'x', 'Do MMM YY @ HH:mm'),
                    modifiedUserData: e.modifiedUserData,
                };
            })

			// Sort by date order, newest first
			tableDataList = tableDataList.sort((a, b) => {
				return a.createdDateTime < b.createdDateTime ? 1 : -1
			})

            return (tableDataList)
        },

    },

    methods: {
        ...mapActions({
            ACT_openItem: "ACT_openObservation",
            ACT_openObservation: "ACT_openObservation",
            ACT_areas: "ACT_areas",
        }),

        // UPDATE BREADCRUMBS
        updateBreadcrumbs() {
            const t = this
            const breadcrumbs = []

            breadcrumbs.push({name: `${t.collectionTitle}`, path: `/${t.collection}`}) // Set the Main Path
            t.MIX_breadcrumbs(breadcrumbs)
        },

        // OPEN ITEM
        async openItem(id, process, popup) {
            const t = this
            const item = {}

            t.dynamicComponentKey++
            item.process = process
            item.popup = popup

            if (item.process !== 'New') {
                const itemResult = await t.MIX_readDocumentById('observations', id)
                item.data = itemResult.data
            } else {
                item.data = null
            }

            t.ACT_openItem(item)

            if (t.$vuetify.breakpoint.lgAndDown) {
                t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem)
                t.MIX_setPanelLayout(12, 0, false, '')
            } else {
                t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem)

                // * HIDE HEADERS
                for (let i = 0; i < t.headers.length; i++) {
                    if (t.headers[i].hide === true) {
                        t.headers[i].hidden = true
                    }
                }
            }

        },

        /**
         * Get Table Data
         *
         * Get all Concerns data from the database and add it to the tableData array for rendering.
         * A 'Staff User' can only view their own Concerns, but a 'Staff Admin' can view all the concerns.
         *
         * @returns {Promise<void>}
         */
        async getTableData() {
            const t = this
            let collectionData = []

            await t.$firebase.db.collection('observations')
                .onSnapshot(snapshot => {

                    collectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        if (!document.hasOwnProperty('delete')) collectionData.push(document)
                    })

                    t.tableData = collectionData
                })
        },

    },

    watch: {

        GET_currentUser: {
            handler() {
                this.getTableData()
            }, deep: true
        },

        GET_panelLayout: {
            handler() {
                const t = this

                if (t.GET_panelLayout.show === false) {
                    for (let i = 0; i < t.headers.length; i++) {
                        if (t.headers[i].hide === true) {
                            t.headers[i].hidden = false
                        }
                    }
                } else {
                    for (let i = 0; i < t.headers.length; i++) {
                        if (t.headers[i].hide === true) {
                            t.headers[i].hidden = true
                        }
                    }
                }
            }, deep: true
        },

    },

    async mounted() {
        const t = this

        t.MIX_setPanelLayout(12, 0, false, ''); // Hide Right Panel
        t.updateBreadcrumbs(); // Update Breadcrumbs in Title

        await t.getTableData(); // Get Table Data
        await t.MIX_sites(); // UPDATE SITES LIST IN STORE

        // * AUTO OPEN SITE IF PATH CONTAINS SITE ID
        if ((window.location.pathname !== `/${t.collection}` && window.location.pathname !== `/${t.collection}/`)) {
            const urlPath = window.location.pathname.split('/');
            const id = urlPath[2]
            if (id === 'new') {
                t.openItem('', 'New', false)
            } else {
                t.openItem(id, 'View', false)
            }
        }

    },

}
</script>
