<template>
	<v-row no-gutters>

		<!--Page Loader-->
		<page-loading :is-page-loading="isPageLoading"/>

		<!--Page Content-->
		<v-row v-if="!computedIsPageLoading" no-gutters style="height: 100%">

			<!--Left Panel-->
			<v-col v-if="computedSplitScreenColumns.left"
				   :cols="computedSplitScreenColumns.left"
				   class="pa-4">

				<!--Title-->
				<page-title icon="icons8-image-document"
							page-title="Documentation"
							description="Upload and view Tenant's own documentation, or those from One Central Square."
							:divider="true"/>

				<!--Searches-->
				<v-row no-gutters>

					<!--Document Name-->
					<v-col :class="$vuetify.breakpoint.width <= 600 ? '' : 'pr-2'"
						   :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6">

						<app-input v-model.trim="searchByDocumentName"
								   append-icon="icons8-search"
								   :clearable="true"
								   input-type="textInput"
								   label="Document Name..."/>

					</v-col>

					<!--Posted By-->
					<v-col :class="$vuetify.breakpoint.width <= 600 ? 'mt-4' : 'pl-2'"
						   :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6">

						<app-input v-model.trim="searchByPostedBy"
								   append-icon="icons8-search"
								   :clearable="true"
								   input-type="textInput"
								   label="Posted By..."/>

					</v-col>

				</v-row>

				<!--Action Buttons-->
				<div class="d-flex justify-end mt-4">

					<!--New-->
					<app-btn :block="$vuetify.breakpoint.width <= 600"
							 icon="icons8-image-document"
							 label="New"
							 @click.native="openRightPanel()"/>

				</div>

				<!--Table-->
				<v-data-table v-if="$vuetify.breakpoint.width >= 600"
							  :headers="computedTableHeaders"
							  :items="computedTableData"
							  class="rounded-lg my-4">

					<!--Id-->
					<template v-slot:item.id="{item}">
						<app-text category="text-small">{{ item.id }}</app-text>
					</template>

					<!--Icon-->
					<template v-slot:item.documentFileType="{item}">
						<div class="d-flex justify-center pa-2">
							<v-img v-if="item.documentFileType === 'application/pdf'"
								   :src="require('@/assets/images/icons8-pdf.svg')" width="32"></v-img>
							<v-img
								v-if="item.documentFileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
								:src="require('@/assets/images/icons8-microsoft-excel-2019.svg')" width="32"></v-img>
							<v-img
								v-if="item.documentFileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
								:src="require('@/assets/images/icons8-microsoft-powerpoint-2019.svg')"
								width="32"></v-img>
							<v-img
								v-if="item.documentFileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
								:src="require('@/assets/images/icons8-microsoft-word-2019.svg')" width="32"></v-img>
							<v-img v-if="['image/jpeg', 'image/jpg', 'image/png'].includes(item.documentFileType)"
								   :src="require('@/assets/images/icons8-image (1).svg')" width="32"></v-img>
						</div>
					</template>

					<!--Document Name-->
					<template v-slot:item.documentName="{item}">
						<app-text category="text-small">{{ item.documentName }}</app-text>
					</template>

					<!--Document Posted By-->
					<template v-slot:item.documentPostedBy="{item}">
						<app-text category="text-small">
							{{ item.documentPostedBy }}
						</app-text>
					</template>

					<!--Document Modified Date-->
					<template v-slot:item.modifiedDateTime="{item}">
						<app-text category="text-small">
							{{ $moment(Number(item.modifiedDateTime)).format('ddd Do MMM \'YY @ HH:mm') }}
						</app-text>
					</template>

					<!--Actions-->
					<template v-slot:item.actions="{item}">
						<v-btn class="primary--text white"
							   depressed @click="openRightPanel(item)">
							<v-icon class="icons8-forward pa-0" color="primary"/>
						</v-btn>
					</template>

				</v-data-table>

				<!--Mobile Cards-->
				<div v-if="$vuetify.breakpoint.width < 600">

					<app-text v-if="!documentationCollectionData.length" class="text-center mt-4">
						There are no Documents to show
					</app-text>

					<v-card v-for="item in computedTableData" :key="item.id"
							@click="openRightPanel(item)"
							class="rounded-lg mt-4 pa-4" flat>

						<!--Document Name-->
						<div class="d-flex align-center">
							<div>
								<v-img v-if="item.documentFileType === 'application/pdf'"
									   :src="require('@/assets/images/icons8-pdf.svg')" width="32"></v-img>
								<v-img
									v-if="item.documentFileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
									:src="require('@/assets/images/icons8-microsoft-excel-2019.svg')"
									width="32"></v-img>
								<v-img
									v-if="item.documentFileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
									:src="require('@/assets/images/icons8-microsoft-powerpoint-2019.svg')"
									width="32"></v-img>
								<v-img
									v-if="item.documentFileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
									:src="require('@/assets/images/icons8-microsoft-word-2019.svg')" width="32"></v-img>
								<v-img v-if="['image/jpeg', 'image/jpg', 'image/png'].includes(item.documentFileType)"
									   :src="require('@/assets/images/icons8-image (1).svg')" width="32"></v-img>
							</div>

							<app-text class="font-weight-bold ml-2">{{ item.documentName }}</app-text>
						</div>

						<!--Icon | Posted By-->
						<div class="d-flex align-start mt-2">

							<!--Icon-->
							<v-icon class="icons8-business-building mr-2" color="primary" size="16"/>

							<!--Document Name-->
							<app-text category="text-small" class="grey--text">
								{{ item.documentPostedBy }}
							</app-text>

						</div>

						<!--Icon | Date-->
						<div class="d-flex align-start mt-2">

							<!--Icon-->
							<v-icon class="icons8-upload mr-2" color="primary" size="16"/>

							<!--Date-->
							<app-text category="text-small" class="grey--text">
								{{ $moment(Number(item.modifiedDateTime)).format('ddd Do MMM \'YY @ HH:mm') }}
							</app-text>

						</div>

					</v-card>

				</div>

			</v-col>

			<!--Right panel-->
			<v-col v-if="isRightPanelOpen"
				   :class="computedSplitScreenColumns.left && 'borderLeft'"
				   :cols="computedSplitScreenColumns.right">

				<!--Action Bar-->
				<div class="d-flex align-center justify-space-between appbackground pa-4">

					<!--Title-->
					<app-text v-if="$vuetify.breakpoint.width >= 600" category="text-medium">
						{{ selectedItem && selectedItem.documentName ? selectedItem.documentName : 'New' }}
					</app-text>

					<v-spacer/>

					<!--Action Buttons-->
					<div>

						<!--Edit Icon-->
						<!--Can only be edited by Admin and the original Tenant-->
						<edit-icon v-if="selectedItem && selectedItem.documentCompanyId === GET_currentUser.userCompanyId
                                      && ['SA', 'TA'].includes(GET_currentUser.userLevel)"
								   :isActive="!isFormReadOnly" @click.native="editForm"/>

						<!--Delete Icon-->
						<!--Can only be deleted by Admin and the original Tenant-->
						<delete-icon-dialog v-if="selectedItem && selectedItem.documentCompanyId === GET_currentUser.userCompanyId
                                      && ['SA', 'TA'].includes(GET_currentUser.userLevel)"
											:disabled="!isFormReadOnly"
											:item-to-delete="selectedItem && selectedItem.documentName"
											v-on:emitDeleteFromDialog="emittedDeleteFromDialog"/>

						<!--Close Icon-->
						<close-icon @click.native="closeRightPanel"/>

					</div>

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs" show-arrows>

					<v-tabs-slider color="accent"/>

					<v-tab href="#overview">
						<app-text category="text-small">Overview</app-text>
					</v-tab>
					<v-tab href="#viewer">
						<app-text category="text-small">Viewer</app-text>
					</v-tab>

				</v-tabs>

				<!--Tab Content-->
				<v-tabs-items v-model="tabs">

					<!--Overview Tab Content-->
					<v-tab-item class="appbackground pa-4" value="overview">

						<documentation-form :doesFormStartReadOnly="isFormReadOnly"
											:formData="selectedItem"
											:tenantsData="tenantsCollectionData"
											v-on:emitCloseRightPanel="emittedCloseRightPanel"/>

					</v-tab-item>

					<!--Viewer Tab Content-->
					<v-tab-item class="appbackground" value="viewer">

						<documentation-viewer :formData="selectedItem"/>

					</v-tab-item>

				</v-tabs-items>

			</v-col>

		</v-row>

	</v-row>
</template>

<script>
import {mapGetters} from "vuex";
import DocumentationForm from "@/views/documentation/documentationForm/documentationForm";
import DocumentationViewer from "@/views/documentation/documentationViewer/documentationViewer";

export default {

	name: "documentation",

	components: {DocumentationViewer, DocumentationForm},

	data: () => ({
		searchByDocumentName: '',
		searchByPostedBy: '',
		isRightPanelOpen: false,
		isFormReadOnly: true,
		selectedItem: {},
		tableHeaders: [
			{
				text: 'Id',
				value: 'id',
				sortable: false,
				align: 'left',
				hiddenByDefault: true,
				hideWithWidth: true
			},
			{
				text: 'File Type',
				value: 'documentFileType',
				sortable: false,
				align: 'center',
				hiddenByDefault: false,
				hideWithWidth: false,
				width: '88px'
			},
			{
				text: 'Document',
				value: 'documentName',
				sortable: false,
				align: 'left',
				hiddenByDefault: false,
				hideWithWidth: false,
			},
			{
				text: 'Posted By',
				value: 'documentPostedBy',
				sortable: false,
				align: 'left',
				hiddenByDefault: false,
				hideWithWidth: false,
			},
			{
				text: 'Updated On',
				value: 'modifiedDateTime',
				sortable: false,
				align: 'left',
				hiddenByDefault: false,
				hideWithWidth: false,
			},
			{
				text: '',
				value: 'actions',
				sortable: false,
				align: 'right',
				hiddenByDefault: false,
				hideWithWidth: false,
				width: '96px',
			},
		],
		tabs: '',

		// Collection Data
		documentationCollectionData: [],
		isDocumentationCollectionDataLoaded: false,
		tenantsCollectionData: [],
		isTenantsCollectionDataLoaded: false,
		usersCollectionData: [],
		isUsersCollectionDataLoaded: false,
		isPageLoading: true,
	}),

	computed: {
		...mapGetters({
			GET_currentUser: 'GET_currentUser',
		}),

		/**
		 * Computed Is Page Loading
		 *
		 * Return a boolean for the page loading spinner to denote if all data has been loaded.
		 *
		 * @returns {boolean} if data has been loaded or not
		 */
		computedIsPageLoading() {
			const t = this

			t.isPageLoading = !(
				t.isDocumentationCollectionDataLoaded &&
				t.isTenantsCollectionDataLoaded &&
				t.isUsersCollectionDataLoaded
			)

			return t.isPageLoading
		},

		/**
		 * Computed Split Screen Columns
		 *
		 * Calculate the width of the left snd right panels, based on the currently selected functionality.
		 * The left panel has the table, the right panel has the forms.
		 *
		 * @returns {{left: number, right: number}}
		 */
		computedSplitScreenColumns() {
			const t = this
			let left = 12
			let right = 0

			if (t.$vuetify.breakpoint.width >= 1200) {
				if (t.isRightPanelOpen) {
					left = 6
					right = 6
				}
			} else {
				if (t.isRightPanelOpen) {
					left = 0
					right = 12
				}
			}

			return {left, right}
		},

		/**
		 * Computed Table Headers
		 *
		 * Using the tableHeaders data, hide the fields that aren't required.
		 *
		 * @returns {Array} an array of table header objects
		 */
		computedTableHeaders() {
			const t = this
			let tableHeaders = t.tableHeaders

			tableHeaders = tableHeaders.filter(header => !header.hiddenByDefault)

			if (t.computedSplitScreenColumns.left < 12) {
				tableHeaders = tableHeaders.filter(header => !header.hideWithWidth)
			}

			return tableHeaders
		},

		/**
		 * Computed Table Data
		 *
		 * Return a sorted array of JSON objects.
		 *
		 * @returns {Array} Array of processed JSON data
		 */
		computedTableData() {
			const t = this
			let tableData = []
			let documentData = t.documentationCollectionData

			documentData.forEach(doc => {

				const tenant = t.tenantsCollectionData.find(tenant => tenant.id === doc.documentCompanyId)

				const tableObj = {
					id: doc.id,
					documentCompanyId: doc.documentCompanyId,
					documentFile: doc.documentFile,
					documentFileType: doc.documentFileType,
					documentName: doc.documentName,
					documentPostedBy: tenant?.tenantName || '1CS',
					modifiedDateTime: doc.modifiedDateTime,
				}
				tableData.push(tableObj)
			})

			// Admin can see all (admin doesn't have a documentCompanyId)
			// Tenants can see Admins, and their own
			if (t.GET_currentUser.userCompanyId) {
				tableData = tableData.filter(td => td.documentCompanyId === t.GET_currentUser.userCompanyId || !td.documentCompanyId)
			}

			// Filter by Document Name
			if (t.searchByDocumentName) {
				tableData = tableData.filter(data =>
					data.documentName.toUpperCase().match(t.searchByDocumentName.toUpperCase()))
			}

			// Filter by Posted By
			if (t.searchByPostedBy) {
				tableData = tableData.filter(data =>
					data.documentPostedBy.toUpperCase().match(t.searchByPostedBy.toUpperCase()))
			}

			// Sort by updated date
			tableData = tableData.sort((a, b) => a.modifiedDateTime < b.modifiedDateTime ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Get Collection Data
		 *
		 * Fetch the data from the collection and assign them to the class state.
		 *
		 * @returns {Promise<void>}
		 */
		async getDocumentationCollectionData() {
			const t = this
			let documentationCollectionData = []

			await t.$firebase.db.collection('documentation')
				.onSnapshot(snapshot => {

					// Clear the array to avoid duplications
					documentationCollectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only push active documents
						if (!document.hasOwnProperty('delete')) documentationCollectionData.push(document)

					})

					// Assign the documents in one and set isLoaded to true
					t.documentationCollectionData = documentationCollectionData
					t.isDocumentationCollectionDataLoaded = true
				})
		},

		/**
		 * Get Collection Data
		 *
		 * Fetch the data from the collection and assign them to the class state.
		 *
		 * @returns {Promise<void>}
		 */
		async getTenantsCollectionData() {
			const t = this
			let tenantsCollectionData = []

			await t.$firebase.db.collection('tenants')
				.orderBy('tenantName')
				.onSnapshot(snapshot => {

					// Clear the array to avoid duplications
					tenantsCollectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only push active documents
						if (!document.hasOwnProperty('delete')) tenantsCollectionData.push(document)

					})

					// Assign the documents in one and set isLoaded to true
					t.tenantsCollectionData = tenantsCollectionData
					t.isTenantsCollectionDataLoaded = true
				})
		},

		/**
		 * Get Collection Data
		 *
		 * Fetch the data from the collection and assign them to the class state.
		 *
		 * @returns {Promise<void>}
		 */
		async getUsersCollectionData() {
			const t = this
			let usersCollectionData = []

			await t.$firebase.db.collection('users')
				.onSnapshot(snapshot => {

					// Clear the array to avoid duplications
					usersCollectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only push active documents
						if (!document.hasOwnProperty('delete')) usersCollectionData.push(document)

					})

					// Assign the documents in one and set isLoaded to true
					t.usersCollectionData = usersCollectionData
					t.isUsersCollectionDataLoaded = true
				})
		},

		/**
		 * Close Right Panel
		 *
		 * When closing the form, reset it to ensure it opens as new.
		 */
		closeRightPanel() {
			const t = this

			t.isFormReadOnly = true
			t.isRightPanelOpen = false
		},

		/**
		 * Edit Form
		 *
		 * Toggle the read only state of the form.
		 */
		editForm() {
			const t = this

			t.isFormReadOnly = !t.isFormReadOnly;
		},

		/**
		 * Emitted Close Right Panel
		 *
		 * Take the emitted message and call to close the right panel.
		 *
		 * @returns {Promise<void>}
		 */
		async emittedCloseRightPanel() {
			const t = this

			t.closeRightPanel()
		},

		/**
		 * Emitted Delete From Dialog
		 *
		 * Take the emitted message from the 'deleteIconDialog', and mark the selected document as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async emittedDeleteFromDialog() {
			const t = this

			const deleteDocument = await t.MIX_deleteDocument('documentation', t.selectedItem.id)

			t.closeRightPanel()

			t.MIX_renderConfirmationAlert(
				deleteDocument,
				'Successfully Deleted Document',
				'Error Deleting Document')
		},

		/**
		 * Open Right Panel
		 *
		 * Open the CRUD form.
		 * If the form is opened using the new button, it will be blank.
		 * If the form is opened from the table, it will be assigned here and populated.
		 *
		 * @param item JSON containing the selected data, if required
		 */
		openRightPanel(item) {
			const t = this

			if (!item?.id) t.isFormReadOnly = false
			t.selectedItem = item

			t.isRightPanelOpen = true
		},

	},

	async mounted() {
		const t = this

		await t.getDocumentationCollectionData()
		await t.getTenantsCollectionData()
		await t.getUsersCollectionData()
	}

}
</script>

<style scoped>
.borderLeft {
	border-left: 4px solid #999999;
	min-height: calc(100vh - 128px);
}
</style>
