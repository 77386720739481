<template>
    <div>

        {{ computedFormData }}

        <!--Images-->
        <div v-if="form.documentFileType === 'image/png' || form.documentFileType === 'image/jpeg'"
             class="d-flex justify-center pa-4" style="width: 100%">
            <v-img :src="value" contain max-width="100%"/>
        </div>

        <!--PDFs-->
        <iframe v-else :src="value" class="expandtofill"/>

    </div>
</template>

<script>
export default {

    name: "documentationViewer",

    props: ['formData'],

    data: () => ({
        category: 'ddd',
        form: {},
    }),

    computed: {

        computedFormData() {
            const t = this

            const formData = t.$props.formData

            if (formData?.id) t.form = formData
        },

        value() {
            const t = this
            let url

            // PDF
            if (t.form.documentFileType === "application/pdf") url = t.form.documentFile

            // MPEG
            else if (t.form.documentFileType === "video/mpeg") url = t.form.documentFile

            // Office
            else if (
                (t.form.documentFileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
                (t.form.documentFileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
                (t.form.documentFileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {

                url = "https://view.officeapps.live.com/op/embed.aspx?src=" + t.form.documentFile
            }

            // URL
            else url = t.form.documentFile

            return url
        },

    },

}
</script>

<style scoped>
.expandtofill {
    display: flex;
    justify-content: center;

    border: none;
    height: 100vh !important;
    width: 100% !important;
}
</style>
