<template>
    <v-card color="white" flat>

        <!--Toolbar-->
        <v-toolbar class="pl-2" color="primary">

            <!--Title-->
            <app-text category="text-medium" class="white--text">SWAPP</app-text>

            <v-spacer/>

            <!--Close-->
            <close-icon @click.native="closeDialog"/>

        </v-toolbar>

        <!--Content-->
        <div class="pa-4">

            <!--Instructional Text-->
            <app-text>
                You are about to SWAPP {{ computedSwappStatus }} {{ computedSwappStatus === 'In' ? 'to' : 'from' }} this location.
                This will update your Tenant Dashboard and presence.
            </app-text>

            <!--Instructional Text-->
            <app-text category="text-default-bold" class="mt-4">Do you want to SWAPP {{ computedSwappStatus }}?</app-text>

            <!--Action Buttons-->
            <div class="d-flex justify-space-between mt-4">

                <!--No-->
                <app-btn @click.native="closeDialog"
                         color="lightgrey"
                         icon="icons8-cancel"
                         label="No"/>

                <!--Yes-->
                <app-btn @click.native="handleYesButton"
                    color="success"
                    icon="icons8-checkmark-yes"
                    label="Yes"/>

            </div>

        </div>

    </v-card>
</template>

<script>
export default {

    name: "swappDesktopDialog",

    props: ['userData'],

    computed: {

        computedSwappStatus() {
            const t = this

            return t.$props.userData.swappStatus === 0 ? 'In' : 'Out'
        },

    },

    methods: {

        closeDialog() {
            const t = this

            t.$emit('emitCloseSwappDesktopDialog')
        },

        handleYesButton() {
            const t = this

            t.$emit('emitSwappFromDesktopDialog')
        },

    },

}
</script>

<style scoped>

</style>
