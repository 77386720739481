<template>
    <v-row class="fill-height" no-gutters>

        <!--Page Loader-->
        <page-loading :is-page-loading="isPageLoading"/>

        <!--Page Content-->
        <v-row v-if="!computedIsPageLoading" class="fill-height" no-gutters>

            <!--Left panel-->
            <v-col class="pa-4">

                <!--Page title-->
                <page-title icon="icons8-fires"
                            page-title="Fire Roll Call"
                            description="Initiate a new, or view past Fire Roll Calls."
                            :divider="true"/>

                <!--Action buttons-->
                <div class="d-flex justify-end">

                    <v-spacer v-if="$vuetify.breakpoint.width >= 600"/>

                    <!--Start a Roll Call-->
                    <fire-roll-call-start-dialog
                        v-if="!isFireRegisterVisible"
                        :sitesData="sitesCollectionData"
                        v-on:emitHandleStartRegister="emittedHandleStartRegister"/>

                    <!--Cancel the Roll Call-->
                    <fire-roll-call-cancel-dialog
                        v-if="isFireRegisterVisible"
                        v-on:emitHandleCancelRegister="emittedHandleCancelRegister"/>

                </div>

                <!--Table-->
                <v-data-table v-if="$vuetify.breakpoint.width >= 600 && !isFireRegisterVisible"
                              class="rounded-lg mt-4"
                              :headers="tableHeaders"
                              :items="fireRollCallCollectionData">

                    <!--Title-->
                    <template v-slot:item.title="{ item }">
                        <app-text category="text-small">{{ item.title }}</app-text>
                    </template>

                    <!--Type-->
                    <template v-slot:item.type="{ item }">
                        <app-text category="text-small">{{ item.type }}</app-text>
                    </template>

                    <!--Date-->
                    <template v-slot:item.date="{ item }">
                        <app-text category="text-small">
                            {{ $moment(Number(item.createdDateTime)).format('dddd Do MMMM YYYY @ HH:mm') }}
                        </app-text>
                    </template>

                    <!--Open-->
                    <template v-slot:item.open="{ item }">
                        <v-btn @click="openDocument(item.id, 'View', false)"
                               depressed class="primary--text white pa-4">
                            <v-icon class="icons8-forward" color="primary"/>
                        </v-btn>
                    </template>

                </v-data-table>

                <!--Mobile Cards-->
                <div v-if="$vuetify.breakpoint.width < 600 && !isFireRegisterVisible">

                    <!--No data message-->
                    <app-text v-if="!fireRollCallCollectionData.length"
                              category="text-default" class="justify-center text-center mt-4">
                        There are no Fire Roll Calls to show
                    </app-text>

                    <div v-for="rollCall in fireRollCallCollectionData">

                        <v-card @click="openDocument(rollCall.id, 'View', false)"
                                class="rounded-lg mt-4 pa-4" flat>

                            <!--Title-->
                            <app-text category="text-default-bold">{{ rollCall.title }}</app-text>

                            <!--Type | Date-->
                            <div class="d-flex">

                                <!--Type-->
                                <app-text category="text-default">
                                    {{ rollCall.type }}
                                </app-text>

                                <v-spacer/>

                                <!--Date-->
                                <app-text category="text-default">
                                    {{ $moment(Number(rollCall.createdDateTime)).format('DD MMMM YYYY @ HH:mm') }}
                                </app-text>

                            </div>

                        </v-card>

                    </div>

                </div>

                <!--Register-->
                <fire-roll-call-register v-if="isFireRegisterVisible"
                                         :users="computedSiteUsersData"
                                         v-on:emitCloseRegister="isFireRegisterVisible = false"/>

            </v-col>

            <!--Right panel-->
            <transition name="custom-classes-transition"
                        enter-active-class="animate__animated animate__slideIn animated__faster"
                        leave-active-class="animate__animated animate__fadeOut animated__faster"
                        mode="out-in">
                <rightpanel/>
            </transition>

        </v-row>

    </v-row>
</template>

<script>
import FireRollCallRegister from "./fireRollCallRegister/fireRollCallRegister";
import FireRollCallCancelDialog from "./fireRollCallCancelDialog/fireRollCallCancelDialog";
import FireRollCallStartDialog from "./fireRollCallStartDialog/fireRollCallStartDialog";
import {mapActions, mapGetters} from "vuex";

export default {

    name: "fireRollCall",

    components: {
        FireRollCallStartDialog,
        FireRollCallCancelDialog,
        FireRollCallRegister
    },

    data: () => ({
        isFireRegisterVisible: false,
        tableHeaders: [
            {text: 'Title', value: 'title', align: 'left', hidden: false},
            {text: 'Type', value: 'type', align: 'left', hidden: false},
            {text: 'Date', value: 'date', align: 'left', hidden: false, sortable: false},
            {text: '', value: 'open', align: 'right', hidden: false, sortable: false, width: '96px'},
        ],

        // Data
        fireRollCallCollectionData: [],
        isFireRollCallCollectionDataLoaded: false,
        sitesCollectionData: [],
        isSitesCollectionDataLoaded: false,
        usersCollectionData: [],
        isUsersCollectionDataLoaded: false,
        isPageLoading: false,
    }),

    computed: {
        ...mapGetters({
            GET_panelLayout: "GET_panelLayout",
        }),

        computedIsPageLoading() {
            const t = this

            t.isPageLoading = !(
                t.isFireRollCallCollectionDataLoaded &&
                t.isUsersCollectionDataLoaded &&
                t.isSitesCollectionDataLoaded
            )

            return t.isPageLoading
        },

        computedSiteUsersData() {
            const t = this
            let usersData = t.usersCollectionData
            let hoursAgo = t.$moment().subtract(1, 'hours').format('x')

            // Get all the Users either currently SWAPP'd in, or those who have SWAPP'd out in the last hour
            usersData = usersData.filter(user => user.swappStatus === 1 || user.swappStatus === 0 && user.swappTime > hoursAgo)

            return usersData
        },

        computedTableData() {
            const t = this
            let tableData = t.fireRollCallCollectionData

            tableData = tableData.sort((a, b) => a.createdDateTime > b.createdDateTime ? 1 : -1)

            return tableData
        },

    },

    methods: {
        ...mapActions({
            ACT_openItem: "ACT_openFireRollCall",
            ACT_openFireRollCall: "ACT_openFireRollCall",
        }),

        /**
         * Get Fire Roll Call Collection Data
         *
         * Fetch the collection data and add the records to the data array.
         *
         * @returns {Promise<void>}
         */
        async getFireRollCallCollectionData() {
            const t = this
            let fireRollCallCollectionData = []

            await t.$firebase.db.collection('fireRollCall')
                .onSnapshot(snapshot => {

                    // Clear the array to avoid data duplication
                    fireRollCallCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        fireRollCallCollectionData.push(document)

                    })

                    t.fireRollCallCollectionData = fireRollCallCollectionData
                    t.isFireRollCallCollectionDataLoaded = true
                })
        },

        /**
         * Get Users Collection Data
         *
         * Fetch the collection data and add those records which are not marked as 'deleted'.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this
            let usersCollectionData = []

            await t.$firebase.db.collection('users')
                .onSnapshot(snapshot => {

                    // Clear the array to avoid data duplication
                    usersCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only push documents not marked as deleted
                        if (!document.hasOwnProperty('delete')) usersCollectionData.push(document)
                    })

                    t.usersCollectionData = usersCollectionData
                    t.isUsersCollectionDataLoaded = true
                })
        },

        /**
         * Get Sites Collection Data
         *
         * Fetch the collection data and add those records which are not marked as 'deleted'.
         *
         * @returns {Promise<void>}
         */
        async getSitesCollectionData() {
            const t = this
            let sitesCollectionData = []

            await t.$firebase.db.collection('sites')
                .orderBy('siteName')
                .onSnapshot(snapshot => {

                    // Clear the array to avoid data duplication
                    sitesCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only push documents not marked as deleted
                        if (!document.hasOwnProperty('delete')) sitesCollectionData.push(document)
                    })

                    t.sitesCollectionData = sitesCollectionData
                    t.isSitesCollectionDataLoaded = true
                })
        },

        /**
         * Open Document
         *
         * When a table row is opened, populate and show the right panel with the row details.
         *
         * @param id
         * @param process
         * @param popup
         * @returns {Promise<void>}
         */
        async openDocument(id, process, popup) {
            const t = this

            const item = {}
            item.process = process
            item.popup = popup

            const itemResult = await t.MIX_readDocumentById('fireRollCall', id)
            item.data = itemResult.data

            t.ACT_openItem(item)

            if (t.$vuetify.breakpoint.lgAndDown) {
                t.MIX_fsDialog(true, 'Fire Roll Call', 'fireRollCall')
                t.MIX_setPanelLayout(12, 0, false, '')
            } else {
                t.MIX_setPanelLayout(6, 6, true, 'Fire Roll Call', 'fireRollCall')
            }
        },

        emittedHandleStartRegister() {
            const t = this

            t.isFireRegisterVisible = !t.isFireRegisterVisible
        },

        emittedHandleCancelRegister() {
            const t = this

            t.isFireRegisterVisible = !t.isFireRegisterVisible
        }

    },

    async mounted() {
        const t = this

        // Fetch collections data
        await t.getFireRollCallCollectionData()
        await t.getUsersCollectionData()
        await t.getSitesCollectionData()

    },

}
</script>

<style>

</style>
