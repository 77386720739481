<template>
    <div class="ma-0 pa-0">

        <!-- File input -->
        <input @change="previewFile"
               :accept="GET_photoUpload.allowedTypes"
               :multiple="multiple"
               ref="fileinput"
               style="display: none"
               type="file"/>

        <!--Add photo button | Progress bar-->
        <div class="d-flex align-center">

            <!--Add button-->
            <app-btn v-if="!isUploading" @click.native="fileClick"
                     class="white--text mr-4"
                     color="primary"
                     :disabled="disabled"
                     icon="icons8-camera mr-2"
                     :label="label || 'Add Photo'"/>

            <!--Progress bar-->
            <v-progress-linear v-if="isUploading"
                               height="36"
                               rounded
                               v-model="uploadValue">
                <app-text category="text-default">{{ Math.ceil(uploadValue) }}%</app-text>
            </v-progress-linear>

        </div>

    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {

    props: ['disabled', 'label', 'multiple'],

    data: () => ({
        fileData: null,
        fileType: '',
        hideUploadButton: false,
    }),

    computed: {
        ...mapGetters({
            GET_photoUpload: 'photoUpload_store/GET_photoUpload'
        })
    },

    methods: {

        fileClick() {
            this.$refs.fileinput.click();
        },

        previewFile: async function (event) {
            const t = this

            t.uploadValue = 0
            t.fileURL = null
            t.fileData = event.target.files

        },

    },

    watch: {

        fileData: async function () {
            const t = this

            for (const file of t.fileData) {

                // Get the DocLink (document ID to associate the file to)
                const docLink = t.$attrs.docLink.documentId

                // Compress the file
                const compressedFile = await t.MIX_compressImage(file)

                // Upload the file
                const uploadFileResult = await t.MIX_uploadFile(
                    t.GET_photoUpload.folder,
                    compressedFile,
                    docLink,
                    t.GET_photoUpload.type,
                    t.GET_photoUpload.typeId
                )

                t.MIX_photoUploadResult(uploadFileResult.data)
            }

        },

    },

}
</script>
