<template>
    <v-card class="rounded-lg pa-4" flat height="100%" max-height="512px" style="overflow-y: scroll">

        <!--Icon | Label-->
        <div class="d-flex align-center">
            <v-icon class="icons8-task-completed mr-4" color="accent" size="48"/>
            <app-text category="text-medium">Notice Board</app-text>
        </div>

        <v-divider v-if="!noticeData.length" class="my-4"/>

        <app-text v-if="!noticeData.length" class="grey--text text-center">There are no Notices to show</app-text>

        <!--Notices-->
        <div v-for="notice in noticeData" :key="notice.id" class="mt-4">

            <v-divider class="my-4"/>

            <div class="d-flex align-center">

                <!--Priority-->
                <v-icon v-if="notice.noticePriority === 'Default'"
                        class="icons8-filled-circle mr-2" color="success" size="16"/>
                <v-icon v-if="notice.noticePriority === 'Medium'"
                        class="icons8-filled-circle mr-2" color="warning" size="16"/>
                <v-icon v-if="notice.noticePriority === 'High'"
                        class="icons8-filled-circle mr-2" color="error" size="16"/>

                <!--Title-->
                <app-text category="text-default-bold">{{ notice.noticeTitle }}</app-text>

                <v-spacer/>

                <!--Date Posted-->
                <app-text category="text-small" class="grey--text ml-4">
                    {{ $moment(Number(notice.modifiedDateTime)).format('do MMM \'YY @ HH:mm') }}
                </app-text>

            </div>

            <app-text>{{ notice.noticeDescription }}</app-text>

        </div>

    </v-card>
</template>

<script>
export default {

    name: "noticeBoardsHomePageCard",

    props: ['noticeData'],

}
</script>

<style scoped>

</style>
