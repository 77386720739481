<template>
    <v-form>

        {{ computedFormData }}

        <!--Form Fields-->
        <v-row class="d-flex flex-column" no-gutters>

            <!--Notice Title-->
            <!--This is a WATCHED property to check for character count-->
            <app-input v-model.trim="form.noticeTitle"
                       :error="errors.noticeTitle"
                       :error-messages="errors.noticeTitleErrorMessage"
                       :hint="`Characters remaining ${noticeTitleMaxAllowedCharacters - form.noticeTitle.length}`"
                       :is-form-read-only="computedIsFormReadOnly"
                       input-type="textInput"
                       label="Notice Title"/>

            <!--Notice Description-->
            <app-input v-model.trim="form.noticeDescription"
                       :error="errors.noticeDescription"
                       :error-messages="errors.noticeDescriptionErrorMessage"
                       :is-form-read-only="computedIsFormReadOnly"
                       class="mt-4"
                       input-type="textArea"
                       label="Notice Description"
                       prepend-inner-icon="icons8-business-building"/>

            <!--Priority | User Groups-->
            <v-row no-gutters>

                <!--Priority-->
                <v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
                       :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                       class="mt-4">
                    <app-input v-model="form.noticePriority"
                               :error="errors.noticePriority"
                               :error-messages="errors.noticePriorityErrorMessage"
                               :is-form-read-only="computedIsFormReadOnly"
                               :items="['Default', 'Medium', 'High']"
                               input-type="select"
                               label="Notice Priority"/>
                </v-col>

                <!--User Groups-->
                <!--<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pl-2'"-->
                <!--       :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"-->
                <!--       class="mt-4">-->
                <!--    <app-input input-type="autoComplete"-->
                <!--               :error="errors.noticeTenantGroups"-->
                <!--               :error-messages="errors.noticeTenantGroupsErrorMessage"-->
                <!--               :is-form-read-only="computedIsFormReadOnly"-->
                <!--               :items="tenantsData"-->
                <!--               item-text="tenantName"-->
                <!--               label="Notice Tenant Groups"-->
                <!--               :multiple="true"-->
                <!--               :return-object="true"-->
                <!--               v-model="form.noticeTenantGroups"/>-->
                <!--</v-col>-->

            <!--</v-row>-->

            <!--Visibility-->
            <v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pl-2'"
                   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                   class="mt-4">
                <app-input v-model="form.noticeVisibility"
                           :error="errors.noticeVisibility"
                           :error-messages="errors.noticeVisibilityErrorMessage"
                           :is-form-read-only="computedIsFormReadOnly"
                           :items="['Hidden', 'Visible']"
                           input-type="select"
                           label="Notice Visibility"/>
            </v-col>
		</v-row>

            <!--Save button-->
            <div v-if="!computedIsFormReadOnly" class="d-flex justify-end mt-4">
                <app-btn color="success"
                         icon="icons8-save" label="Save" @click.native="saveOrganisation"/>
            </div>

        </v-row>

    </v-form>
</template>

<script>
export default {

    name: "noticeBoardsForm",

    props: ['doesFormStartReadOnly', 'formData', 'tenantsData'],

    data: () => ({
        errors: {
            noticeTitle: false,
            noticeTitleErrorMessage: '',
            noticeDescription: false,
            noticeDescriptionErrorMessage: '',
            noticePriority: false,
            noticePriorityErrorMessage: '',
            noticeTenantGroups: false,
            noticeTenantGroupsErrorMessage: '',
            noticeVisibility: false,
            noticeVisibilityErrorMessage: '',
        },
        form: {
            id: '',
            noticeTitle: '',
            noticePostedDate: '',
            noticeDescription: '',
            noticePriority: '',
            noticeTenantGroups: [],
            noticeVisibility: '',
        },
        isFormReadOnly: false,
        noticeTitleMaxAllowedCharacters: 32,
    }),

    computed: {

        /**
         * Computed From Data
         *
         * If this is an existing document, set the form data.
         * If it's not, the default form will be used.
         */
        computedFormData() {
            const t = this

            const formData = t.$props.formData

            if (formData?.id) {

                // Assign Contact data
                t.form = formData
            }

        },

        /**
         * Computed Is Form Read Only
         *
         * When the form is first created in the Supervisor's Control Panel,
         * the form should be editable.
         * If the form is open through the Reporting pages, it should open uneditable.
         *
         * @returns {boolean} is form read only
         */
        computedIsFormReadOnly() {
            const t = this

            return t.$props.doesFormStartReadOnly
                ? t.$props.doesFormStartReadOnly
                : t.isFormReadOnly
        },

    },

    methods: {

        clearErrorsAndMessages() {
            const t = this

            t.errors.noticeTitle = false
            t.errors.noticeTitleErrorMessage = ''
            t.errors.noticeDescription = false
            t.errors.noticeDescriptionErrorMessage = ''
            t.errors.noticePriority = false
            t.errors.noticePriorityErrorMessage = ''
            t.errors.noticeTenantGroups = false
            t.errors.noticeTenantGroupsErrorMessage = ''
            t.errors.noticeVisibility = false
            t.errors.noticeVisibilityErrorMessage = ''
        },

        /**
         * Save Organisation
         *
         * Create/update the Organisation.
         *
         * @returns {Promise<void>}
         */
        async saveOrganisation() {
            const t = this
            let document

            if (t.validateForm()) {

                // Create
                if (!t.form?.id) {

                    // Generate new IDs
                    t.form.id = t.MIX_generateId()

                    // Save new Organisation document
                    document = await t.MIX_createDocument('noticeBoards', t.form)

                }

                // Update
                else {

                    document = await t.MIX_updateDocument('noticeBoards', t.form)

                }

                t.MIX_renderConfirmationAlert(
                    document,
                    'Successfully Saved Notice',
                    'Error Saving Notice'
                )

                t.$emit('emitCloseRightPanel')

            }

        },

        /**
         * Validate Form
         *
         * Validate all required form fields before saving.
         *
         * @returns {boolean} if the form has passed validation or not
         */
        validateForm() {
            const t = this

            t.clearErrorsAndMessages()

            // Notice Title
            if (!t.form.noticeTitle?.trim()) {
                t.errors.noticeTitle = true
                t.errors.noticeTitleErrorMessage = 'Notice Title required'
            } else if (t.form.noticeTitle.length > t.noticeTitleMaxAllowedCharacters) {
                t.errors.noticeTitle = true
                t.errors.noticeTitleErrorMessage = 'Cannot be more than 32 characters'
            }

            // Notice Description
            if (!t.form.noticeDescription?.trim()) {
                t.errors.noticeDescription = true
                t.errors.noticeDescriptionErrorMessage = 'Notice Description required'
            }

            // Notice Priority
            if (!t.form.noticePriority?.trim()) {
                t.errors.noticePriority = true
                t.errors.noticePriorityErrorMessage = 'Notice Priority required'
            }

            // Notice Tenant Groups
            // if (!t.form.noticeTenantGroups?.length) {
            //     t.errors.noticeTenantGroups = true
            //     t.errors.noticeTenantGroupsErrorMessage = 'Notice Tenant Groups required'
            // }

            // Notice Visibility
            if (!t.form.noticeVisibility?.length) {
                t.errors.noticeVisibility = true
                t.errors.noticeVisibilityErrorMessage = 'Notice Visibility required'
            }

            return !Object.values(t.errors).includes(true)
        }

    },

    watch: {

        'form.noticeTitle'() {
            const t = this
            const maxCharacters = 32

            if (t.form.noticeTitle.length > maxCharacters) {
                t.errors.noticeTitle = true
                t.errors.noticeTitleErrorMessage = 'Cannot be more than 32 characters'
            } else {
                t.errors.noticeTitle = false
                t.errors.noticeTitleErrorMessage = ''
            }
        }

    },

}
</script>

<style scoped>

</style>
