import Vue from 'vue';
import VueRouter from 'vue-router';

// Store
import store from "@/store.js";

// Main Routes
import AppSupport from "@/views/appSupport/appSupport.vue";
import Concerns from "@/views/concerns/concerns.vue";
import Contractors from "@/views/contractors/contractors.vue";
import ContractorsArea from "@/views/contractors/contractorsArea/contractorsArea.vue";
import CovidAnswers from "@/views/covidAnswers/covidAnswers.vue";
import Contact from "@/views/contactUs/contact-us.vue";
import Dashboard from "@/views/dashboards/dashboard.vue"
import Documentation from "@/views/documentation/documentation.vue"
import FireRollCall from "@/views/fireRollCall/fireRollCall.vue"
import Home from '@/views/home/home.vue';
import ItSupport from "@/views/itSupport/itSupport.vue";
import MyTenant from "@/views/tenants/myTenant/myTenant.vue";
import NoticeBoards from "@/views/noticeBoards/noticeBoards.vue";
import Observations from "@/views/observations/observations.vue";
import OfficeDashboard from "@/views/dashboards/officeDashboard.vue"
import Questions from "@/views/questions/questions.vue";
import SiteDashboard from "@/views/dashboards/siteDashboard.vue"
import Sites from "@/views/sites/sites.vue";
import StaffDirectory from "@/views/staff/staff-directory.vue";
import Swapp from "@/views/swapp/swapp.vue";
import Tenants from "@/views/tenants/tenants.vue";
import Unauthorised from "@/views/unauthorised/unauthorised.vue";
import UserProfile from "@/views/userProfile/userProfile.vue";
import UserSchedule from "@/views/userSchedule/userSchedule.vue";

// Settings
import Settings from "@/views/settings/settings.vue";
import Lookups from "@/views/settings/lookups.vue";

// Auth Routes
import Register from "@/views/auth/register.vue";
import Login from "@/views/auth/login.vue";
import ResetPassword from "@/views/auth/reset-password.vue";
import Restricted from "@/views/auth/restricted.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU', 'TA', 'TU', 'VU'],
			userStatus: 'APPROVED'
		}
	},

	// Main Routes - Main Routes - Main Routes - Main Routes //
	{
		path: '/appSupport',
		name: 'AppSupport',
		component: AppSupport,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU', 'TA', 'TU'],
			userStatus: 'APPROVED'
		}
	},
	// {
	// 	path: '/concerns',
	// 	name: 'Concerns',
	// 	component: Concerns,
	// 	meta: {
	// 		requiresAuth: true,
	// 		userLevel: ['SA', 'SU', 'DA'],
	// 		userStatus: 'APPROVED'
	// 	}
	// },
	{
		path: '/contractors',
		name: 'Contractors',
		component: Contractors,
		meta: {
			requiresAuth: true,
			userLevel: ['SA'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/contractorsArea',
		name: 'Contractors Area',
		component: ContractorsArea,
		meta: {
			requiresAuth: true,
			userLevel: ['CU'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/covidAnswers',
		name: 'CovidAnswers',
		component: CovidAnswers,
		meta: {
			requiresAuth: true,
			userLevel: ['SA'],
			userStatus: 'APPROVED'
		}
	},
	// {
	// 	path: '/contact-us',
	// 	name: 'Contact',
	// 	component: Contact,
	// 	meta: {
	// 		requiresAuth: true,
	// 		userLevel: ['DA'],
	// 		userStatus: 'APPROVED'
	// 	}
	// },
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU', 'TA', 'TU'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/documentation',
		name: 'Documentation',
		component: Documentation,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU', 'TA', 'TU'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/fireRollCall',
		name: 'FireRollCall',
		component: FireRollCall,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU'],
			userStatus: 'APPROVED'
		}
	},
	// {
	// 	path: '/itSupport',
	// 	name: 'ItSupport',
	// 	component: ItSupport,
	// 	meta: {
	// 		requiresAuth: true,
	// 		userLevel: ['SA', 'SU', 'DA'],
	// 		userStatus: 'APPROVED'
	// 	}
	// },
	{
		path: '/myTenant',
		name: 'MyTenant',
		component: MyTenant,
		meta: {
			requiresAuth: true,
			userLevel: ['TA', 'TU'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/noticeBoards',
		name: 'NoticeBoards',
		component: NoticeBoards,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/observations',
		name: 'Observations',
		component: Observations,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU', 'TA', 'TU'],
			userStatus: 'APPROVED'
		}
	},
	// {
	// 	path: '/questions',
	// 	name: 'Questions',
	// 	component: Questions,
	// 	meta: {
	// 		requiresAuth: true,
	// 		userLevel: ['SA'],
	// 		userStatus: 'APPROVED'
	// 	}
	// },
	{
		path: '/siteDashboard',
		name: 'SiteDashboard',
		component: SiteDashboard,
		meta: {
			requiresAuth: true,
			userLevel: ['SA'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/sites',
		name: 'Sites',
		component: Sites,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/staff-directory',
		name: 'StaffDirectory',
		component: StaffDirectory,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU', 'TA', 'TU'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/unauthorised',
		name: 'Unauthorised',
		component: Unauthorised,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU', 'TA', 'TU', 'VU'],
			userStatus: ['PENDING', 'REJECTED', 'SUSPENDED', 'ARCHIVED'],
			delete: true,
		}
	},
	{
		path: '/tenants',
		name: 'Tenants',
		component: Tenants,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/userProfile',
		name: 'UserProfile',
		component: UserProfile,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU', 'TA', 'TU'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/userSchedule',
		name: 'UserSchedule',
		component: UserSchedule,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU', 'TA', 'TU'],
			userStatus: 'APPROVED'
		}
	},

	// Settings - Settings - Settings - Settings //
	{
		path: '/settings',
		component: Settings,
		meta: {
			requiresAuth: true,
			userLevel: ['DA']
		}
	},
	{
		path: '/settings/lookups',
		name: 'Lookups',
		component: Lookups,
		meta: {
			requiresAuth: true,
			userLevel: ['DA']
		}
	},

	// SWAPP - SWAPP - SWAPP - SWAPP //
	{
		path: '/swapp/:direction?',
		name: 'Swapp',
		component: Swapp,
		meta: {
			requiresAuth: true,
			userLevel: ['SA', 'SU', 'TA', 'TU'],
			userStatus: 'APPROVED'
		}
	},

	// *  AUTH ROUTES
	// ? register for normal user
	{path: "/register/:id?", name: "Register", component: Register},
	{path: "/login", name: "Login", component: Login},
	{path: "/restricted", name: "Restricted", component: Restricted},
	{path: "/reset-password", name: "ResetPassword", component: ResetPassword,},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {

	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

	// if (requiresAuth) {
	//
	// 	let checkUser = store.getters.GET_currentUser
	//
	// 	console.log('checkUser: ', checkUser)
	//
	// 	navigator.serviceWorker.register('/service-worker.js').then(reg => {
	// 		// sometime later…
	// 		reg.update();
	// 	})
	//
	// 	// If the user is not logged in, redirect them to the login page
	// 	if (!checkUser?.id) next("/login")
	//
	// 	// If the user's status is ARCHIVED/REJECTED/SUSPENDED/, redirect them to the Suspended page
	// 	else if (['PENDING', 'ARCHIVED', 'REJECTED', 'SUSPENDED'].includes(checkUser.userStatus) && !to.meta.userStatus.includes(checkUser.userStatus)) next({name: 'Unauthorised'})
	//
	// 	// If the user has been deleted, redirect them to the Suspended page
	// 	else if (checkUser.hasOwnProperty('delete') && !to.meta.delete) next({name: 'Unauthorised'})
	//
	// 	// If the user does not have access to the route, redirect them to the Home page
	// 	else if (!to.meta.userLevel.includes(checkUser.userLevel)) {
	// 		next({name: 'Home'})
	// 	}
	//
	// 	else {
	// 		next()
	// 	}
	//
	// } else {
	// 	next()
	// }

	if (requiresAuth) {
		let checkUser = store.getters.GET_currentUser;

		navigator.serviceWorker.register('/service-worker.js').then(reg => {
			// sometime later…
			reg.update();
		});

		// If the user is not logged in, redirect them to the login page
		if (!checkUser) {
			next("/login");
		}

		// If the user does not have access to the route, redirect them to the Home page
		else if (!to.meta.userLevel.includes(checkUser.userLevel)) {
			next({name: 'Home'})
		}

		// If the user's status is not APPROVED, redirect them to the Unauthorised page
		else if (!to.meta.userStatus.includes(checkUser.userStatus.toUpperCase())) {
			next({name: 'Unauthorised'})
		} else {
			next();
		}

	} else next();

})

export default router
