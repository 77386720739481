<template>
    <v-bottom-sheet v-model="updateExists"
                    :width="$vuetify.breakpoint.width < 600 ? '100%' : '304px'"
                    hide-overlay
                    persistent>

        <div class="d-flex align-center white rounded-lg pa-4">

            <app-btn class="pulse"
                     color="primary"
                     label="Update Available"
                     @click.native="refreshApp"/>

            <v-spacer/>

            <close-icon @click.native="updateExists = false"/>

        </div>

    </v-bottom-sheet>
</template>

<script>
export default {
    name: "appUpdate"
}
</script>

<style scoped>

</style>
