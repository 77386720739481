<!--
LOGIN

Renders the login form, containing the following:
	- Email (input)
	- Password (input)
	- Register (button)
	- Next (button)
	- Register (link)
	- Rest Password (link)
-->
<template>
    <div class="d-flex justify-center" style="width: 100%">
        <div class="d-flex flex-column align-center ma-4" style="min-width: 256px; max-width: 512px">

            <!--Logo-->
            <div class="d-flex justify-center rounded-lg">
                <v-img :src="require('@/assets/company_logo_light.svg')" max-width="512" class="rounded-lg"/>
            </div>

            <!--Welcome text-->
            <app-text category="text-large" class="justify-center grey--text mt-4" style="letter-spacing: 2px">
                One Central Square SWAPP
            </app-text>

            <v-divider class="my-4" style="width: 100%"/>

            <!--Title-->
            <app-text category="text-large" class="primary--text">Login</app-text>

            <!--Instruction-->
            <app-text category="text-default" class="mt-4">
                Please enter your email address and password below to login.
            </app-text>

            <!--LogIn Form-->
            <firebase-auth-login/>

            <!--Register-->
            <app-text category="text-default" class="justify-center mb-4">Don't have an account?
                <router-link :to="{ path: 'register' }" replace class="btn btn-link">
                    Register
                </router-link>
            </app-text>

            <!--Reset-->
            <app-text category="text-default" class="justify-center">Forgot Password?
                <router-link :to="{ path: 'reset-password' }" replace class="btn btn-link">
                    Reset password
                </router-link>
            </app-text>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
}
</script>
