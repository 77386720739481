<!--
Staff Directory

Renders the Staff Directory page which displays the details for staff members.
This renders as either a table (tablets and desktops) or cards (mobile).

Staff Directory also has a form for adding/editing/deleting staff details, and viewing their location history.
The form can also be used to SWAPP staff in/out.
-->
<template>
    <v-row no-gutters class="fill-height noprint" style="width: 100% !important;">

        <!--Left panel-->
        <v-col :cols="GET_panelLayout.leftPanel" class="pa-4">

            <!--Page title-->
            <page-title icon="icons8-people"
                        pageTitle="Staff"
                        description="View, edit and delete Users, or update a Users access permissions and status."
                        :divider="true"/>

            <!--Searches-->
            <v-row no-gutters>

                <!--Search-->
                <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                       :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'">
                    <app-input input-type="textInput"
                               :append-icon="'icons8-search'"
                               label="Name..."
                               v-model="searchByUserName"/>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                       :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'">
                    <app-input input-type="textInput"
                               :append-icon="'icons8-search'"
                               label="Position..."
                               v-model="searchByUserPosition"/>
                </v-col>

            </v-row>

            <!--Filter buttons | Action buttons-->
            <div class="d-flex mt-4">

                <!--Access-->
                <div v-if="$vuetify.breakpoint.width >= 600">

                    <!--Toggle buttons-->
                    <v-btn-toggle v-model="filter.userStatus" multiple color="white" class="rounded-lg mr-4"
                                  active-class="white" background-color="lightgrey" dense>

                        <!--Pending-->
                        <v-btn value="PENDING" large :height="buttonSizeDefault">
                            <v-icon color="error" class="icons8-inactive-state"/>
                            <app-text category="text-small" class="grey--text">
                                {{ $t(`filters.pending`) }}
                            </app-text>
                        </v-btn>

                        <!--Approved-->
                        <v-btn value="APPROVED" large :height="buttonSizeDefault">
                            <v-icon color="success" class="icons8-checkmark-yes"/>
                            <app-text category="text-small" class="grey--text">
                                {{ $t(`filters.approved`) }}
                            </app-text>
                        </v-btn>

                        <!--Rejected-->
                        <v-btn value="REJECTED" large :height="buttonSizeDefault"
                               v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1044">
                            <v-icon color="darkgrey" class="icons8-cancel"/>
                            <app-text category="text-small" class="grey--text"
                                      v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1168">
                                {{ $t(`filters.rejected`) }}
                            </app-text>
                        </v-btn>

                        <!--Suspended-->
                        <v-btn value="SUSPENDED" large :height="buttonSizeDefault"
                               v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1044">
                            <v-icon color="darkgrey" class="icons8-private"/>
                            <app-text category="text-small" class="grey--text"
                                      v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1168">
                                {{ $t(`filters.suspended`) }}
                            </app-text>
                        </v-btn>

                        <!--Archived-->
                        <v-btn value="ARCHIVED" large :height="buttonSizeDefault"
                               v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1044">
                            <v-icon color="grey" class="icons8-xbox-a"/>
                            <app-text category="text-small" class="grey--text"
                                      v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1168">
                                {{ $t(`filters.archived`) }}
                            </app-text>
                        </v-btn>

                    </v-btn-toggle>

                </div>

				<!--Status-->
				<v-btn-toggle active-class="white"
							  background-color="lightgrey"
							  class="rounded-lg mr-4"
							  color="white"
							  dense
							  multiple
							  v-model="filter.swappStatus">

					<!--In-->
					<v-btn :value="1" large :height="buttonSizeDefault">
						<v-icon class="icons8-qr-code" color="success"/>
						<app-text v-if="GET_panelLayout.leftPanel === 12" category="text-small" class="grey--text">
							In
						</app-text>
					</v-btn>

					<!--Out-->
					<v-btn :value="0" large :height="buttonSizeDefault">
						<v-icon class="icons8-qr-code" color="grey"/>
						<app-text v-if="GET_panelLayout.leftPanel === 12" category="text-small" class="grey--text">
							Out
						</app-text>
					</v-btn>

				</v-btn-toggle>

                <v-spacer/>

                <!--Action buttons-->
                <div v-if="['SA', 'TA'].includes(GET_currentUser.userLevel)" class="d-flex">

                    <!--Columns-->
                    <app-btn v-if="$vuetify.breakpoint.width >= 646"
                             @click.native="columnsDialog = true"
                             color="primary"
                             hide-label-below="810"
                             icon="icons8-select-column"
                             label="Columns"/>

                    <!--Export-->
                    <app-btn v-if="$vuetify.breakpoint.width >= 646"
                             @click.native="MIX_exportDocuments(headersCSV,'Staff Directory',formatExport)"
                             class="ml-4"
                             color="primary"
                             hide-label-below="810"
                             icon="icons8-export-csv"
                             label="Export"/>

                </div>

            </div>

            <!--Table-->
            <v-data-table v-if="$vuetify.breakpoint.width >= 600"
                          class="rounded-lg mt-4"
                          :headers="computedHeaders"
                          :items="computedTableData"
                          item-key="id"
                          sort-by="userName">

                <!--Id-->
                <template v-slot:[`item.id`]="{ item }">
                    <app-text category="text-small">{{ item.id }}</app-text>
                </template>

                <!--User Status-->
                <template v-slot:[`item.userStatus`]="{ item }">
                    <div>
                        <v-icon v-if="item.userStatus === 'PENDING'"
                                class="icons8-inactive-state" color="accent" size="32"/>
                        <v-icon v-if="item.userStatus === 'REJECTED'"
                                class="icons8-cancel" color="warning"/>
                        <v-icon v-if="item.userStatus === 'APPROVED'"
                                class="icons8-checkmark-yes" color="success" size="32"/>
                        <v-icon v-if="item.userStatus === 'SUSPENDED'"
                                class="icons8-private" color="error" size="32"/>
                        <v-icon v-if="item.userStatus === 'ARCHIVED'"
                                class="icons8-xbox-a" color="grey" size="32"/>
                    </div>
                </template>

                <!--Swapp Status-->
                <template v-slot:[`item.swappStatus`]="{ item }">

                    <!--Out-->
                    <div v-if="item.swappStatus === 0" class="d-flex align-center">
                        <v-icon color="grey" class="icons8-filled-circle mr-2" size="32"/>
                        <app-text category="text-small" class="grey--text">OUT</app-text>
                    </div>

                    <!--In-->
                    <div v-if="item.swappStatus === 1" class="d-flex align-center">
                        <v-icon color="success" class="icons8-filled-circle mr-2" size="32"/>
                        <app-text category="text-small" class="grey--text">IN</app-text>
                    </div>

                </template>

                <!--User Name-->
                <template v-slot:[`item.userName`]="{ item }">
                    <app-text category="text-small">{{ item.userName }}</app-text>
                </template>

                <!--Position-->
                <template v-slot:[`item.userPosition`]="{ item }">
                    <app-text category="text-small">{{ item.userPosition }}</app-text>
                </template>

                <!--Telephone Number-->
                <template v-slot:[`item.userTelephone`]="{ item }">
                    <app-text category="text-small">{{ item.userTelephone }}</app-text>
                </template>

                <!--Email Address-->
                <template v-slot:[`item.userEmail`]="{ item }">
                    <app-text category="text-small">{{ item.userEmail }}</app-text>
                </template>

                <!--Registered-->
                <template v-slot:[`item.registered`]="{ item }">
                    <div class="text-left">
                        <v-icon v-if="item.authId !== null" color="success" class="icons8-verified-account"/>
                        <v-icon v-else color="grey" class="icons8-customer"/>
                    </div>
                </template>

                <!--Last location-->
                <template v-slot:[`item.lastLocation.locationName`]="{ item }">
                    <app-text category="text-small">{{ item.lastLocation.locationName }}</app-text>
                </template>

                <!-- Action button -->
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn @click="openItem(item.id, 'View', false)"
                           class="primary--text white pa-4" depressed>
                        <v-icon class="icons8-forward" color="primary"/>
                    </v-btn>
                </template>

            </v-data-table>

            <!--Mobile Cards-->
            <div v-if="$vuetify.breakpoint.width < 600" class="mt-4">

                <staff-user-modal v-for="item in computedTableData" :key="item.id"
                                  :user="item"/>

            </div>

        </v-col>

        <!--Right panel-->
        <transition name="custom-classes-transition"
                    enter-active-class="animate__animated animate__fadeIn animated__faster"
                    leave-active-class="animate__animated animate__fadeOut animated__faster"
                    mode="out-in">
            <rightpanel></rightpanel>
            <!-- <component :is="dynamicComponent" :key="dynamicComponentKey"></component>      -->
        </transition>

        <!--Column Dialog Box-->
        <v-dialog v-model="columnsDialog" scrollable max-width="300px">
            <v-card class="rounded-lg">

                <!--Title-->
                <app-text category="text-medium" class="primary--text ma-4">Show/Hide Columns</app-text>

                <v-divider/>

                <!--List items-->
                <v-card-text>
                    <v-list>
                        <v-list-item v-for="(header, index) in headers.slice(0, -1)" :key="index">
                            <app-text>
                                <v-checkbox color="grey darken-1" hide-details v-model="headers[index].hidden"
                                            :false-value="true" :true-value="false" :label="header.text"/>
                            </app-text>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-divider/>

                <!--Close button-->
                <v-card-actions class="text-right">
                    <v-spacer/>
                    <v-btn color="primary" text @click="columnsDialog = false">Close</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

    </v-row>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import staffUserModal from "./staffUserModal/staffUserModal"

export default {

    name: "StaffDirectory",

    components: {
        staffUserModal
    },

    data: () => ({
        title: "Staff Directory",
        allData: [],
        collection: "staffDirectory",
        collectionItem: "staffDirectory",
        collectionItemTitle: "Staff Directory",
        columnsDialog: false,
        customWhere: [],
        exportStaff: [],
        filter: {
            swappStatus: [],
            userStatus: [],
        },
        headers: [
            {
                text: "ID",
                value: "id",
                align: "start",
                sortable: false,
                hidden: true,
                hide: false,
                hasAccess: ['SA', 'SU']
            },
            {
                text: "Status",
                value: "userStatus",
                align: "center",
                sortable: false,
                hidden: false,
                hide: false,
                width: '69px',
            },
            {
                text: "SWAPP",
                value: "swappStatus",
                align: "start",
                sortable: false,
                hidden: false,
                hide: false,
                width: '69px',
            },
            {
                text: "Name",
                value: "userName",
                align: "start",
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: "Position",
                value: "userPosition",
                align: "start",
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: "Telephone Number",
                value: "userTelephone",
                align: "start",
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: "Email Address",
                value: "userEmail",
                align: "start",
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: "Registered",
                value: "registered",
                align: "center",
                sortable: false,
                hidden: true,
                hide: true,
            },
            {
                text: "Last Location",
                value: "lastLocation.locationName",
                align: "start",
                sortable: false,
                hidden: true,
                hide: true,
            },
            {
                text: "",
                value: "actions",
                align: "right",
                sortable: false,
                hasAccess: ['SA', 'TA'],
                hidden: false,
                hide: false,
                width: "96px",
            },
        ],
        headersCSV: {
            userName: 'User Name',
            userTelephone: 'User Telephone',
            userEmail: 'User Email',
            userAddressLine1: 'Address Line 1',
            userAddressLine2: 'Address Line 2',
            userAddressLine3: 'Address Line 3',
            userTown: 'User Town',
            userCounty: 'User County',
            userPostcode: 'User Postcode',
            userTitle: 'User Position',
            userType: 'User Type',
            lastLocation: 'Last Location',
            userLevel: 'User Level',
            userRole: 'User Role',
            userStatus: 'Access',
        },
        searchByUserName: '',
        searchByUserPosition: '',
        tableData: [],
    }),

    computed: {
        ...mapGetters({
            GET_panelLayout: "GET_panelLayout",
            GET_currentUser: "GET_currentUser",
        }),

        //Format of the Export File
        formatExport() {
            const t = this
            const staff = JSON.parse(JSON.stringify(t.computedTableData));
            t.exportStaff = [];

            for (let i = 0; i < staff.length; i++) {
                const userName = "";
                if (staff[i].userName) {
                    userName = staff[i].userName;
                } else {
                    userName = "UNKNOWN"
                }
                const userTelephone = "";
                if (staff[i].userTelephone) {
                    userTelephone = staff[i].userTelephone;
                } else {
                    userTelephone = "UNKNOWN"
                }
                const userEmail = "";
                if (staff[i].userEmail) {
                    userEmail = staff[i].userEmail;
                } else {
                    userEmail = "UNKNOWN"
                }
                const userAddressLine1 = "";
                if (staff[i].userAddress.userAddressLine1) {
                    userAddressLine1 = staff[i].userAddress.userAddressLine1;
                } else {
                    userAddressLine1 = "UNKNOWN"
                }
                const userAddressLine2 = "";
                if (staff[i].userAddress.userAddressLine2) {
                    userAddressLine2 = staff[i].userAddress.userAddressLine2;
                } else {
                    userAddressLine2 = "UNKNOWN"
                }
                const userAddressLine3 = "";
                if (staff[i].userAddress.userAddressLine3) {
                    userAddressLine3 = staff[i].userAddress.userAddressLine3;
                } else {
                    userAddressLine3 = "UNKNOWN"
                }
                const userTown = "";
                if (staff[i].userAddress.userTown) {
                    userTown = staff[i].userAddress.userTown;
                } else {
                    userTown = "UNKNOWN"
                }
                const userCounty = "";
                if (staff[i].userAddress.userCounty) {
                    userCounty = staff[i].userAddress.userCounty;
                } else {
                    userCounty = "UNKNOWN"
                }
                const userPostcode = "";
                if (staff[i].userAddress.userPostcode) {
                    userPostcode = staff[i].userAddress.userPostcode;
                } else {
                    userPostcode = "UNKNOWN"
                }
                const userTitle = "";
                if (staff[i].userPosition) {
                    userTitle = staff[i].userPosition;
                } else {
                    userTitle = "UNKNOWN"
                }
                const userType = "";
                if (staff[i].userType) {
                    userType = staff[i].userType;
                } else {
                    userType = "UNKNOWN"
                }
                const lastLocation = "";
                if (staff[i].lastLocation.locationName) {
                    lastLocation = staff[i].lastLocation.locationName;
                } else {
                    lastLocation = "UNKNOWN"
                }
                const userLevel = "";
                if (staff[i].userLevel) {
                    userLevel = staff[i].userLevel;
                } else {
                    userLevel = "UNKNOWN"
                }
                const userRole = "";
                if (staff[i].userRole) {
                    userRole = staff[i].userRole;
                } else {
                    userRole = "UNKNOWN"
                }
                const userStatus = "";
                if (staff[i].userStatus) {
                    userStatus = staff[i].userStatus;
                } else {
                    userStatus = "UNKNOWN"
                }

                t.exportStaff.push({
                    userName: userName,
                    userTelephone: userTelephone,
                    userEmail: userEmail,
                    userAddressLine1: userAddressLine1,
                    userAddressLine2: userAddressLine2,
                    userAddressLine3: userAddressLine3,
                    userTown: userTown,
                    userCounty: userCounty,
                    userPostcode: userPostcode,
                    userTitle: userTitle,
                    userType: userType,
                    lastLocation: lastLocation,
                    userLevel: userLevel,
                    userRole: userRole,
                    userStatus: userStatus,
                })
            }

            return t.exportStaff;
        },

        /**
         * Computed Headers
         *
         * Only display headers that are not set to hidden and the user has access to (via userLevel).
         *
         * @returns {[headers]} - filtered headers to render in the table
         */
        computedHeaders() {
            const t = this
            const headers = t.headers
            let filteredHeaders = []

            headers.forEach(header => {

                // Only Admins can view the OPEN button
                if (header.value === 'actions' && !header.hasAccess.includes(t.GET_currentUser.userLevel)) {
                    return
                }

                // If the column is not hidden, show it
                if (!header.hidden) {
                    filteredHeaders.push(header)
                }

            })

            return filteredHeaders
        },

        computedTableData() {
            const t = this
            let tableData = t.tableData

            // TA & TU should only be allowed to view their own Tenants
            if (['TA', 'TU'].includes(t.GET_currentUser.userLevel)) {
                tableData = tableData.filter(user => user.userCompanyId === t.GET_currentUser.userCompanyId)
            }

            // Filter by SWAPP status
            if (t.filter.swappStatus.length) {
                tableData = tableData.filter(item => t.filter.swappStatus.includes(item.swappStatus))
            }

            // Filter by User Status for Access
            if (t.filter.userStatus.length) {
                tableData = tableData.filter(item => t.filter.userStatus.includes(item.userStatus))
            }

            // Search By UserName
            if (t.searchByUserName) {
                tableData = tableData.filter(user => user.userName.toUpperCase().match(t.searchByUserName.toUpperCase()))
            }

            // Search By User Position
            if (t.searchByUserPosition) {
                tableData = tableData.filter(user => user.userPosition.toUpperCase().match(t.searchByUserPosition.toUpperCase()))
            }

            // Sort in to alphabetical order
            tableData = tableData.sort((a, b) => a.userName > b.userName ? 1 : -1)

            return tableData
        },

    },

    methods: {
        ...mapActions({
            ACT_openItem: "ACT_openStaffDirectory",
            ACT_openStaffDirectory: "ACT_openStaffDirectory",
        }),

        async getTableData() {
            const t = this

            let collection = t.$firebase.db.collection("users")

            if (JSON.stringify(t.customWhere) !== "[]") {

                for (let i = 0; i < t.customWhere.length; i++) {

                    const query = {
                        field: t.customWhere[i].key,
                        operator: t.customWhere[i].operator,
                        value: t.customWhere[i].value
                    }

                    collection = collection.where(query.field, query.operator, query.value)
                }
            }

            collection.onSnapshot(function (querySnapshot) {

                // Rest array to avoid data duplication
                t.tableData = []

                querySnapshot.forEach(function (doc) {

                    const document = doc.data()
                    document.id = doc.id

                    if (!document.hasOwnProperty("delete")) {
                        t.tableData.push(document)
                        t.allData.push(document)
                    } else if (t.showDeleted) {
                        t.tableData.push(document)
                    } else {
                        // Do not Show Concern
                    }
                })
            })
        },

        async openItem(id, process, popup) {
            const t = this

            // Only allow opening if the user has access
            if (['SA', 'TA'].includes(t.GET_currentUser.userLevel)) {
                const item = {}

                item.process = process
                item.popup = popup

                // If this is NOT a new document, fetch the user data by id
                if (item.process !== "New") {
                    const itemResult = await t.MIX_readDocumentById("users", id)
                    item.data = itemResult.data
                } else {
                    item.data = null
                }

                t.ACT_openItem(item)

                if (t.$vuetify.breakpoint.lgAndDown) {
                    t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem)
                    t.MIX_setPanelLayout(12, 0, false, "")
                } else {
                    t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem)
                    // * HIDE HEADERS
                    for (let i = 0; i < t.headers.length; i++) {
                        if (t.headers[i].hide === true) {
                            t.headers[i].hidden = true
                        }
                    }
                }
            }
        },

    },

    async mounted() {
        const t = this

        t.MIX_setPanelLayout(12, 0, false, "") // Hide Right Panel

        // Get Users collections data
        await t.getTableData()

    },

};
</script>
