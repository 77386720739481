<!--
HOME

Renders the home screen which contains cards for Covid related flows and quick link around the app.
-->
<template>
    <div class="pa-4" style="width: 100%">

        <!--Page Loader-->
        <page-loading :is-page-loading="isPageLoading"/>

        <!--Page Content-->
        <div v-if="!computedIsPageLoading">

            <!--Welcome User-->
            <welcome-user-home-page-card/>

            <!--Hero Image-->
            <div class="d-flex justify-center mt-4" style="margin: 0 auto; width: 100%; max-width: 1600px">
                <v-img :src="require('@/assets/company_logo_light.svg')"/>
            </div>

            <!--Notice Board | Quick Links-->
            <v-row class="mt-8" no-gutters>

                <!--Notice Board-->
                <v-col :class="$vuetify.breakpoint.width < 1024 ? '' : 'pr-4'"
                       :cols="$vuetify.breakpoint.width < 1024 ? 12 : 6">

                    <notice-boards-home-page-card :noticeData="noticeBoardsCollectionData"/>

                </v-col>

                <!--Quick Links-->
                <v-col :class="$vuetify.breakpoint.width < 1024 ? 'mt-4' : ''"
                       :cols="$vuetify.breakpoint.width < 1024 ? 12 : 6"
                       class="home-quickLinks-container">

                    <v-card v-for="link in computedQuickLinks" :key="link.title"
                            @click.native="$vuetify.breakpoint.width >= 600 && link.title === 'SWAPP' ? link.click() : MIX_go(link.page)"
                            class="d-flex align-center rounded-lg pa-4"
                            flat
                            height="104">

                        <!--Icon-->
                        <v-icon :class="link.icon" class="mr-4" color="accent" size="48"/>

                        <div>

                            <!--Title-->
                            <app-text category="text-medium">{{ link.title }}</app-text>

                            <!--SWAPP Status-->
                            <app-text v-if="link.title === 'SWAPP'" category="text-small" class="grey--text">
                                {{ computedCurrentUser && computedCurrentUser.swappStatus === 0 ? 'Currently Out' : 'Currently In' }}
                            </app-text>

                        </div>

                    </v-card>

                </v-col>

            </v-row>

        </div>

        <v-dialog max-width="512"
                  v-model="isSwappDialogVisible">

            <swapp-desktop-dialog :userData="computedCurrentUser"
                                  v-on:emitCloseSwappDesktopDialog="emittedCloseSwappDesktopDialog"
                                  v-on:emitSwappFromDesktopDialog="emittedSwappFromDesktopDialog"/>

        </v-dialog>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import NoticeBoardsHomePageCard from "@/views/noticeBoards/noticeBoardsHomePageCard/noticeBoardsHomePageCard";
import WelcomeUserHomePageCard from "@/views/home/welcomeUserHomePageCard/welcomeUserHomePageCard";
import SwappDesktopDialog from "@/views/swapp/swappDesktopDialog/swappDesktopDialog";

export default {

    name: "home",

    components: {
        SwappDesktopDialog,
        NoticeBoardsHomePageCard,
        WelcomeUserHomePageCard,
    },

    data: () => ({
        isPageLoading: true,
        isSwappDialogVisible: false,
        scheduleData: [],
        scheduleError: '',
        tomorrowsScheduleData: {},

        // Data
        noticeBoardsCollectionData: [],
        isNoticeBoardsCollectionDataLoaded: false,
        usersCollectionData: [],
        isUsersCollectionDataLoaded: false,
    }),

    computed: {
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
        }),

        computedCurrentUser() {
            const t = this

            return t.usersCollectionData.find(user => user.id === t.GET_currentUser.id)
        },

        /**
         * Computed Is Page Loading
         *
         * Set isPageLoading to 'false' to show the page and hide the spinner, only when all data has been loaded.
         *
         * @returns {boolean} to denote if the data has finished loading
         */
        computedIsPageLoading() {
            const t = this

            t.isPageLoading = !(
                t.isNoticeBoardsCollectionDataLoaded &&
                t.isUsersCollectionDataLoaded
            )

            return t.isPageLoading

        },

        computedQuickLinks() {
            const t = this
            let quickLinks = []

            // Staff
            if (t.GET_currentUser.userType === 'Staff') {
                quickLinks = [
                    {
                        icon: 'icons8-qr-code',
                        title: 'SWAPP',
                        page: t.GET_currentUser.swappStatus === 0 ? '/swapp/in' : '/swapp/out',
                        click: t.openSwappDialog
                    },
                    {
                        icon: 'icons8-business-building',
                        title: 'Tenants',
                        page: '/tenants',
                    },
                    {
                        icon: 'icons8-image-document',
                        title: 'Documentation',
                        page: '/documentation',
                    },
                    {
                        icon: 'icons8-eye',
                        title: 'Observations',
                        page: '/observations',
                    },
                    {
                        icon: 'icons8-task-completed',
                        title: 'Notices',
                        page: '/noticeBoards',
                    },
                ]

                if (t.GET_currentUser.userLevel === 'SA' || t.GET_currentUser.userAccessAndAbilities?.includes('Fire Marshall')) {
                    quickLinks.push(
                        {
                            icon: 'icons8-fire',
                            title: 'Fire Roll Call',
                            page: '/fireRollCall',
                        },
                    )
                }

            }

            // Tenant
            if (t.GET_currentUser.userType === 'Tenant') {
                quickLinks = [
                    {
                        icon: 'icons8-qr-code',
                        title: 'SWAPP',
                        page: t.GET_currentUser.swappStatus === 0 ? '/swapp/in' : '/swapp/out',
                        click: t.openSwappDialog
                    },
                    {
                        icon: 'icons8-business-building',
                        title: 'My Tenant',
                        page: '/myTenant',
                    },
                    {
                        icon: 'icons8-image-document',
                        title: 'Documentation',
                        page: '/documentation',
                    },
                    {
                        icon: 'icons8-eye',
                        title: 'Observations',
                        page: '/observations',
                    },
                ]
            }

            return quickLinks
        },

        /**
         * Users Awaiting Approval
         *
         * Filter all users and return an array containing just those with a 'PENDING' status, awaiting approval.
         *
         * @returns array - array containing users with a 'PENDING' status
         */
        usersAwaitingApproval() {
            const t = this

            return t.usersCollectionData.filter(user => user.userStatus.toUpperCase() === 'PENDING')
        },

        /**
         * Users Awaiting Approval Message
         *
         * Take the number of users awaiting approval and return a grammatically correct string to render.
         *
         * @returns message - message to render to screen
         */
        usersAwaitingApprovalMessage() {
            const t = this
            let message

            if (t.usersAwaitingApproval.length === 1) {
                message = `You have ${t.usersAwaitingApproval.length} account awaiting approval`
            } else {
                message = `You have ${t.usersAwaitingApproval.length} accounts awaiting approval`
            }

            return message
        },

    },

    methods: {

        /**
         * Get Schedule Collection
         *
         * Fetch the current user's data from the Schedule collection.
         * If there is a schedule present, call for tomorrow's data to render.
         * If there is no schedule present, set a message.
         *
         * @returns {Promise<void>}
         */
        async getScheduleCollectionData() {
            const t = this

            // Get collection data for the current user
            const collection = await t.$firebase.db.collection('schedule').doc(t.GET_currentUser.id)
            const doc = await collection.get()

            // If the data exists, set it and call for tomorrow's schedule
            if (doc.exists) {
                t.scheduleData = doc.data()
                t.getTomorrowsScheduleData(doc.data())
            }

            // If the data does not exist, set an error
            else {
                t.scheduleError = 'Sorry, couldn\'t find your schedule'
            }
        },

        /**
         * Get Users Collection Data
         *
         * Fetch all the data from the Users collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this
            let collectionData = []

            await t.$firebase.db.collection('users')
                .onSnapshot(snapshot => {

                    collectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        if (!document.hasOwnProperty('delete')) collectionData.push(document)

                    })

                    t.usersCollectionData = collectionData
                    t.isUsersCollectionDataLoaded = true

                })

        },

        async getNoticeBoardsCollectionData() {
            const t = this
            let noticeBoardsCollectionData = []

            await t.$firebase.db.collection('noticeBoards')
                .where('noticeVisibility', '==', 'Visible')
                .onSnapshot(snapshot => {

                    noticeBoardsCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        if (!document.hasOwnProperty('delete')) noticeBoardsCollectionData.push(document)

                    })

                    // Sort by Created Date
                    noticeBoardsCollectionData = noticeBoardsCollectionData.sort((a, b) => {
                        return a.createdDateTime < b.createdDateTime ? 1 : -1
                    })

                    t.noticeBoardsCollectionData = noticeBoardsCollectionData
                    t.isNoticeBoardsCollectionDataLoaded = true
                })
        },

        closeSwappDialog() {
            const t = this

            t.isSwappDialogVisible = false
        },

        /**
         * Emitted Close Swapp Desktop Dialog
         *
         * Takes the emitted message and closes the SWAPP dialog.
         */
        emittedCloseSwappDesktopDialog() {
            const t = this

            t.closeSwappDialog()
        },

        async emittedSwappFromDesktopDialog() {
            const t = this
            const fields = {}
            const user = t.computedCurrentUser

            fields.lastLocation = {}
            fields.lastSite = {}

            fields.lastLocation.locationId = 'LanqrqI5GOLGMgBTbyun'
            fields.lastLocation.locationName = 'One Central Square'
            fields.lastLocation.manualSwappReason = 'Desktop'
            fields.lastSite.siteId = 'RJ3T2PkjFHu4m68cUMzq'
            fields.lastSite.siteName = 'One Central Square'
            fields.swappStatus = user.swappStatus === 1 ? 0 : 1
            fields.swappMethod = 'Desktop'
            fields.swappTime = t.$moment().format('x')

            // Update the SWAPP Status in the User's document
            const updateDocumentResult = await t.MIX_updateDocumentFieldsById(
                'users',
                t.GET_currentUser.id,
                fields
            )

            t.MIX_renderConfirmationAlert(
                updateDocumentResult,
                'Successfully Updated SWAPP Status',
                'Error Updating SWAPP Status'
            )

            t.closeSwappDialog()
        },

        /**
         * Get Tomorrow's Schedule Data
         *
         * The schedule card's text needs to show where the user is the next day (tomorrow).
         * Iterate over the schedule data and show the next working day (mon-fri)
         *
         * @param scheduleData - the current users work schedule
         */
        getTomorrowsScheduleData(scheduleData) {
            const t = this

            // Get the day tomorrow
            const tomorrowsDate = t.$moment().add(1, 'day').format('dddd')

            // If tomorrow doesn't fall on the weekend
            if (['Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(tomorrowsDate)) {

                // Iterate over the schedule
                for (const [key, value] of Object.entries(scheduleData)) {

                    // If tomorrow is in the schedule data, set the value
                    if (key.toUpperCase() === tomorrowsDate.toUpperCase()) {
                        t.tomorrowsScheduleData = value
                    }
                }
            }

            // If tomorrow falls on the weekend, set it to Monday
            else {
                t.tomorrowsScheduleData = scheduleData.monday
            }

        },

        openSwappDialog() {
            const t = this

            t.isSwappDialogVisible = true
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} submitted`, null, null)
            } else {
                t.MIX_alert(-1, `Error submitting ${t.collectionItemTitle}`, null, null)
            }
        },

    },

    async mounted() {
        const t = this

        await t.getNoticeBoardsCollectionData()
        await t.getUsersCollectionData()

    },

};
</script>

<style>
.expansion-panel-content-container > div {
    padding: 16px;
}

.home-quickLinks-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(232px, 1fr));
    grid-gap: 16px;

    align-content: flex-start;
}
</style>
