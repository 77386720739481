<template>
    <v-app id="app" class="customPageHeight">
        <alertbox style="z-index: 999999 !important;"/>
        <checkNetwork></checkNetwork>
        <confirmation style="z-index: 999999 !important;"/>
        <location></location>
        <fsdialog style="z-index: 99999 !important;"/>
        <pudialog style="z-index: 999999 !important;"/>
        <confirmbox style="z-index: 999999 !important;"/>
        <qrCodeGeneratorDialog></qrCodeGeneratorDialog>
        <location></location>

        <!--App Bar-->
        <v-app-bar
            v-if="!['Login', 'Register', 'ResetPassword', 'OfficeDashboard', 'SiteDashboard', 'Unauthorised', 'Suspended'].includes($route.name)"
            app class="#FFFFFF noprint" clipped-left height="64px">

            <!--Home icon-->
            <v-btn @click="MIX_go('/')" class="ml-n1" icon>
                <v-icon class="icons8-home" color="primary"/>
            </v-btn>

            <!--Company logo-->
            <v-img @click="MIX_go('/')"
                   contain
                   width="48"
                   max-height="48"
                   position="center"
                   :src="require('@/assets/company_logo_white.svg')"/>

            <!--Menu-->
            <div class="d-flex">
                <v-menu offset-y left>

                    <!--Menu icon-->
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn dark icon v-bind="attrs" v-on="on">
                            <v-icon class="icons8-person" color="primary"/>
                        </v-btn>
                    </template>

                    <!--Menu items-->
                    <v-list nav dense class="rounded-lg">

                        <!--Home-->
                        <v-list-item class="ma-0 pa-0">
                            <v-btn @click="MIX_go('/')"
                                   color="white"
                                   depressed
                                   :height="buttonSizeDefault"
                                   width=100%>
                                <v-icon class="icons8-home" color="primary"/>
                                <v-list-item-title class="darkgrey--text text-left ml-4">Home</v-list-item-title>
                            </v-btn>
                        </v-list-item>

                        <!--Profile-->
                        <v-list-item v-if="GET_currentUser.userLevel !== 'VU'" class="ma-0 pa-0">
                            <v-btn @click="MIX_go('userProfile')"
                                   color="white"
                                   depressed
                                   :height="buttonSizeDefault"
                                   width="100%">
                                <v-icon class="icons8-male-user" color="primary"/>
                                <v-list-item-title class="darkgrey--text text-left ml-4">Profile</v-list-item-title>
                            </v-btn>
                        </v-list-item>

                        <!--Fire Roll Call-->
                        <v-list-item
                            v-if="GET_currentUser.userLevel === 'SA' || GET_currentUser.userAccessAndAbilities.includes('Fire Marshall')"
                            class="ma-0 pa-0">
                            <v-btn @click="MIX_go('fireRollCall')"
                                   color="white"
                                   depressed
                                   :height="buttonSizeDefault"
                                   width=100%>
                                <v-icon class="icons8-fires" color="primary"/>
                                <v-list-item-title class="darkgrey--text text-left ml-4">Fire Roll Call
                                </v-list-item-title>
                            </v-btn>
                        </v-list-item>

                        <!--My Schedule-->
                        <v-list-item class="ma-0 pa-0">
                            <v-btn @click="MIX_go('userSchedule')"
                                   color="white"
                                   depressed
                                   :height="buttonSizeDefault"
                                   width=100%>
                                <v-icon class="icons8-schedule" color="primary"/>
                                <v-list-item-title class="darkgrey--text text-left ml-4">My Schedule</v-list-item-title>
                            </v-btn>
                        </v-list-item>

                        <!--App Support-->
                        <v-list-item class="ma-0 pa-0">
                            <v-btn @click="MIX_go('appSupport')"
                                   color="white"
                                   depressed
                                   :height="buttonSizeDefault"
                                   width=100%>
                                <v-icon class="icons8-online-support" color="primary"/>
                                <v-list-item-title class="darkgrey--text text-left ml-4">App Support</v-list-item-title>
                            </v-btn>
                        </v-list-item>

                        <!--Install app-->
                        <v-list-item v-if="showInstallBanner" class="ma-0 pa-0">
                            <v-btn @click="install()"
                                   color="white"
                                   depressed
                                   :height="buttonSizeDefault"
                                   width=100%>
                                <v-icon class="icons8-download" color="darkgrey"/>
                                <v-list-item-title class="darkgrey--text text-left ml-4">Install App</v-list-item-title>
                            </v-btn>
                        </v-list-item>

                        <!--Log out-->
                        <v-list-item class="ma-0 pa-0" v-if="$store.getters.GET_currentUser != null">
                            <v-btn @click="logout()"
                                   color="white"
                                   depressed
                                   :height="buttonSizeDefault"
                                   width=100%>
                                <v-icon class="icons8-exit" color="primary"/>
                                <v-list-item-title class="darkgrey--text text-left ml-4">Log Out</v-list-item-title>
                            </v-btn>
                        </v-list-item>

                        <!--Terms and Conditions-->
                        <v-list-item class="ma-0 pa-0">
                            <v-btn @click="privacyDialog = true"
                                   color="white"
                                   depressed
                                   :height="buttonSizeDefault"
                                   width=100%>
                                <v-list-item-title class="darkgrey--text">Terms and Conditions</v-list-item-title>
                            </v-btn>
                        </v-list-item>

                        <!--App version-->
                        <v-list-item>
                            <v-list-item-title class="grey--text pl-2">App Version {{ LATEST_VERSION }}
                            </v-list-item-title>
                        </v-list-item>

                    </v-list>

                </v-menu>
            </div>

        </v-app-bar>

        <!--Main Navigation (Side - Vertical)-->
        <v-navigation-drawer v-if="
			$vuetify.breakpoint.width >= 600
			&& showPageElements === true
			&& GET_currentUser.userLevel !== 'VU'
			&& !['OfficeDashboard', 'SiteDashboard', 'Unauthorised', 'Suspended'].includes($route.name)"
                             app
                             class="primary noprint"
                             clipped
                             expand-on-hover
                             permanent
                             style="z-index: 999 !important;">
            <menuList/>
        </v-navigation-drawer>

        <!--Main Navigation (Top - Horizontal)-->
        <v-banner app sticky class="pa-0 elevation-0 ma-0"
                  v-if="$vuetify.breakpoint.width < 600 && showPageElements === true && GET_currentUser.userLevel !== 'VU' && !['Unauthorised', 'Suspended'].includes($route.name)">
            <v-row no-gutters>
                <v-col cols="12">

                    <v-tabs fixed-tabs show-arrows icons-and-text class="elevation-0 pa-0 ma-0"
                            v-model="menutab" hide-slider background-color="primary" color="white">

                        <!--<v-tab v-for="(item, index2) in menu" :key="index2" :to="item.path"-->
                        <v-tab v-for="(item, index2) in computedNavItems" :key="index2" :to="item.path"
                               class="white--text app-nav-tab"
                               :active-class="['Dashboard', 'StaffDirectory', 'Observations'].includes($route.name) ? 'app-nav-tab-active' : ''">

                            <app-text category="text-small" class="mt-1">{{ item.name }}</app-text>
                            <v-icon class="navicons display-1" color="white">{{ item.icon }}</v-icon>
                        </v-tab>

                    </v-tabs>

                </v-col>
            </v-row>
        </v-banner>

        <progressbar/>

        <v-main class="appbackground">
            <v-container class="ma-0 pa-0 d-flex align-start fill-height" fluid>
                <transition name="custom-classes-transition"
                            enter-active-class="animate__animated animate__fadeIn animate__faster"
                            leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
                    <router-view/>
                </transition>
            </v-container>

            <!--Footer-->
            <div class="appCopyrightFooter">
                <appcopyright/>
            </div>

            <!--Terms and Conditions-->
            <v-dialog v-model="privacyDialog"
                      style="z-index: 3000 !important;"
                      :width="$vuetify.breakpoint.smAndDown ? '100%' : '50%'"
                      transition="dialog-bottom-transition">
                <v-card class="appbackground">

                    <v-toolbar color="primary white--text" :height="$vuetify.breakpoint.width < 400 && '100%'">

                        <!--Title-->
                        <app-text category="text-medium" class="white--text">
                            Privacy
                            <span class="accent--text">&</span>
                            Terms & Conditions
                        </app-text>

                        <v-spacer/>

                        <!--Close icon-->
                        <v-icon class="icons8-multiply" color="white" @click="privacyDialog = false"/>

                    </v-toolbar>

                    <PrivacyTermsConditions/>

                </v-card>
            </v-dialog>

            <!--start of ACCESS-CODE-OVERLAY-->
            <v-overlay v-if="!authSuccess" :opacity="1" color="vindicoPurple">
                <v-dialog v-if="!authSuccess" v-model="dialogLogin" persistent style="z-index: 3000 !important;"
                          width="500">
                    <v-card flat class="login" height="350" color="vindicoPurple">
                        <v-card-title class="justify-center pt-4">
                            <v-img height="50" contain width="50%" :src="require('@/assets/vlogo-white.png')"/>
                        </v-card-title>
                        <v-divider class="white mt-2"></v-divider>
                        <v-card-text class="mt-6 mb-0 pb-0">
                            <!-- <p class="headline white--text text-left font-weight-thin my-0 py-0" >Vindico Preview</p> -->
                            <p class="body-1 white--text text-left my-0 py-2">
                                Please enter the access code you received in order to proceed to the preview.
                            </p>
                            <!-- ACCESS-CODE-INPUT-->
                            <v-text-field label="Preview Code" hide-details="auto" v-model.trim="accessCode" dark filled
                                          type="password" v-on:keyup.enter="enterAccessCode()"></v-text-field>
                            <!-- start of ERROR-MESSAGE-->
                            <v-row v-if="authCode === 0" class="justify-center ma-0 pa-0">
                                <transition name="custom-classes-transition" enter-active-class="animated flash">
									<span :class="{ 'error--text': !authSuccess }"
                                          class="subtitle1 font-weight-bold my-1">{{ authMessage }}</span>
                                </transition>
                            </v-row>
                            <!-- end of ERROR-MESSAGE-->
                        </v-card-text>
                        <v-card-actions class="justify-center pt-0 mt-6">
                            <!-- ENTER-ACCESS-CODE-BUTTON-->
                            <v-btn class="white vindicoPurple--text" large @click="enterAccessCode()">Enter</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-overlay>
            <!--end of ACCESS-CODE-OVERLAY-->
        </v-main>

        <!--App Update Notification Bar-->
        <app-update/>

    </v-app>
</template>

<script>
import progressBar from "@/modules/progressBar/progressBar_component.vue";
import PrivacyTermsConditions from "@/components/termsAndConditions.vue";
import {version as appVersionNumber} from '../package.json'
import AppUpdate from "@/components/base/appUpdate";

let installEvent;
export default {

    name: "App",

    components: {
        AppUpdate,
        progressbar: progressBar,
        PrivacyTermsConditions,
    },

    computed: {

        /**
         * Computed Nav Items
         *
         * Return either the Contractors menu, or the normal menu, based on the user type.
         *
         * @returns {*|[{path: string, name: string, icon: string}, {path: string, name: string, icon: string}, {path: string, name: string, icon: string}]|VuetifyIcon|InstanceType<typeof VMenu>|string}
         */
        computedNavItems() {
            const t = this
            let menu = t.menu

            if (t.GET_currentUser.userType === 'Contractor') {
                menu = t.contractorMenu
            }

            return menu

        },
    },

    data() {
        return {
            currentUser: [],
            LATEST_VERSION: appVersionNumber,
            privacyDialog: false,
            // page elements
            showPageElements: false, // hide menu etc. when there's no user logged in
            showInstallBanner: false,
            menutab: [],
            menu: [
                {name: "Dashboard", icon: "icons8-content", path: `/dashboard`},
                {name: "Staff", icon: "icons8-people", path: `/staff-directory`},
                {name: "Observations", icon: "icons8-eye", path: `/observations`},
            ],
            contractorMenu: [
                {name: "Contractors", icon: "icons8-business-building", path: `/contractorsArea`},
                {name: "Staff", icon: "icons8-people", path: `/staff-directory`},
                {name: "Observations", icon: "icons8-eye", path: `/observations`},
            ],
            // * ACCESS CODE VARIABLES
            // TODO - change access password
            accessPassword: "Vindico2021!", // ? ACCESS PASSwORD
            accessCode: "", // TODO remove before go live // user input code
            dialogLogin: true, // login dialog
            authSuccess: true, // TODO change back to false before go live // * when true - closes the overlay
            authMessage: null, // message displayed to user
            authCode: null, // * 1 for correct password, 0 for incorrect

            userTypes: [],
            userType: "",
            userRoles: [],
            userRole: "",
        };
    },

    watch: {
        currentUser: {
            handler: function () {
                this.$store.commit("SET_currentUser", this.currentUser);
            },
            deep: true,
        },
        // Close right panel if it is open when navigating to a different page
        $route() {
            if (this.$store.state.panelLayout.show) {
                this.ACT_panelLayout({leftPanel: 12, rightPanel: 0, show: false, dynamicComponent: ''});
            }
        }
    },

    methods: {

        logout() {
            this.$firebase.auth.signOut().then(() => {
                this.showPageElements = false;
                this.$router.push("/login").catch((err) => {
                    console.log("Route error: " + err);
                });
                this.$store.commit("SET_currentUser", null);
            });
        },

        install() {
            this.showInstallBanner = false;
            installEvent.prompt();
            installEvent.userChoice.then(() => {
                installEvent = null;
            });
        },

        goToContact() {
            this.$router.push("/contact-us").catch((err) => {
                console.log("Router error: " + err);
            });
        },

        enterAccessCode() {
            // * Access code validation
            var v = this;
            if (this.accessCode === this.accessPassword) {
                v.authSuccess = true; // * close the overlay
                v.authCode = 1; // * success code
                v.authMessage = "The access code was correct";
                v.accessCode = null;
            } else {
                v.authSuccess = false; // ! keep the overlay open
                v.authCode = 0; // ! error code
                v.authMessage = "The access code you have entered is incorrect";
            }
        },

        getUser() {
            let t = this
            t.$firebase.db
                .collection('users')
                .doc(t.GET_currentUser.userId)
                .onSnapshot((doc) => {

                    let t = this
                    var currentUser = []

                    t.$firebase.db
                        .collection('users')
                        .doc(t.GET_currentUser.id)
                        .get()
                        .then((doc) => {

                            if (doc.exists) {

                                var document = doc.data()
                                document.id = doc.id
                                currentUser = document
                                t.$store.commit('SET_currentUser', currentUser)

                            } else {
                                currentUser = null
                                // t.MIX_alert(-1, 'Account not found.', null, null)
                            }
                        })
                })
        }
    },

    async created() {
        const t = this;
        this.$firebase.auth.onAuthStateChanged((user) => {
            if (user != null) {
                t.$firebase.db
                    .collection("users")
                    .where("authId", "==", user.uid)
                    .get()
                    .then(function (querySnapshot) {
                        if (querySnapshot.docs.length === 0) {
                            t.showPageElements = false;
                        } else {
                            querySnapshot.forEach(function (doc) {
                                var document = doc.data();
                                t.currentUser = document;
                            });
                            t.showPageElements = true;
                        }
                    });
            } else {
                this.showPageElements = false;
            }
        });
    },

    mounted() {
        this.MIX_progressBar({show: false});

        // this.getUser()
    },

};
</script>

<style>
/* The app-nav... styles below are for the tab navigation (they are active even if they're greyed out) */
.app-nav-tab {
    background: var(--v-primary);
}

.app-nav-tab:active,
.app-nav-tab:hover {
    background: var(--v-accent-base);
}

.app-nav-tab-active {
    background: var(--v-accent-base);
}

/* Make the page full screen minus the appbar */
.customPageHeight {
    height: calc(100% - 64px)
}

/* Push the footer to the bottom of the page, minus the height of the footer */
.appCopyrightFooter {
    position: relative;
    top: -72px;
    padding: 0 16px
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*color: #2c3e50;*/
    color: #666666;
}

.v-banner__wrapper {
    padding: 0px !important;
    margin: 0px !important;
}

.v-banner__content {
    padding: 0px !important;
    margin: 0px !important;

}

.tile:active {
    color: #308CCB !important;
}

.v-toolbar__content {
    padding-left: 10px !important;
}

.tablecard {
    border-radius: 8px !important;
    /* width: 100% !important;
    height: 100px !important; */
}

/* .app-bar-background {
	background: rgb(48, 141, 204);
	background: linear-gradient(90deg, rgba(48, 141, 204, 1) 0%, rgba(81, 35, 121, 1) 100%);
} */

.border-left-grey {
    border-left: 3px solid #cdcdcd !important; /*//#DFDFDF; /*E0E0E0*/
}

.border-top-grey {
    border-top: 2px solid #cdcdcd !important;
}

.heading1 {
    letter-spacing: -0.015625em;
    font-size: 6rem;
    font-weight: 300;
}

.heading2 {
    letter-spacing: -0.0083333333em;
    font-size: 3.75rem;
    font-weight: 300;
}

.heading3 {
    font-size: 3rem;
    font-weight: 400;
}

.heading4 {
    letter-spacing: 0.0073529412em;
    font-size: 2.125rem;
    font-weight: 400;
}

.heading5 {
    font-size: 1.5rem;
    font-weight: 300;
}

.heading6 {
    font-size: 1.25rem;
    font-weight: 500;
}

.subtitle1 {
    letter-spacing: 0.009375rem;
    font-size: 1rem;
    font-weight: 400;
}

.subtitle2 {
    letter-spacing: 0.0071428571em;
    font-size: 0.875rem;
    font-weight: 500;
}

.body1 {
    letter-spacing: 0.03125em;
    font-size: 1rem;
    font-weight: 300;
}

.body2 {
    letter-spacing: 0.0178571429em;
    font-size: 0.875rem;
    font-weight: 400;
}

.button {
    letter-spacing: 0.0892857143em;
    font-size: 0.875rem;
    font-weight: 500;
}

/* Animations - Animations - Animations - Animations */

.flash {
    animation: flash 1s infinite;
}

.pulse {
    animation: pulse 1s infinite;
}
</style>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

@media #{map-get($display-breakpoints, 'md-and-up')}
    .contentstart
        margin-top: 20px !important

@media #{map-get($display-breakpoints, 'xs-only')}
    .contentstart
        margin-top: 50px !important
    .bottomnav
        width: 100% !important
    .submenu
        z-index: 100 !important
        position: fixed !important
        top: 131px !important
        width: 100% !important
    .copyright
        width: calc(100% - 25px) !important

        bottom:0px !important
</style>
