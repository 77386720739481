<template>
	<v-row no-gutters>

		<!--Page Loader-->
		<page-loading :is-page-loading="isPageLoading"/>

		<!--Page Content-->
		<div v-if="!computedIsPageLoading" class="pa-4" style="width: 100%">

			<!--Header-->
			<div class="d-flex align-start" style="width: 100%">

				<!--Title | Instructional Text-->
				<div>
					<!--Title-->
					<page-title icon="icons8-business-building" page-title="My Tenant"/>

					<!--Instructional Text-->
					<app-text>This is your Tenant profile. From here you can update your company, and contact details.
					</app-text>

				</div>

				<v-spacer/>

				<!--Edit Icon-->
				<edit-icon v-if="GET_currentUser.userRole === 'Admin'" :isActive="!isFormReadOnly"
						   @click.native="editForm"/>

			</div>

			<v-divider class="my-4"/>

			<tenants-form :doesFormStartReadOnly="isFormReadOnly"
						  :formData="tenantsCollectionData[0]"
						  v-on:emitCloseRightPanel="emittedCloseRightPanel"/>

		</div>

	</v-row>
</template>

<script>
import TenantsForm from "@/views/tenants/tenantsForm/tenantsForm";
import {mapGetters} from "vuex";

export default {

	name: "tenants",

	components: {TenantsForm},

	data: () => ({
		isFormReadOnly: true,

		// Collection Data
		tenantsCollectionData: [],
		isTenantsCollectionDataLoaded: false,
		userCollectionData: [],
		isUserCollectionDataLoaded: false,
		isPageLoading: true,
	}),

	computed: {
		...mapGetters({
			GET_currentUser: 'GET_currentUser',
		}),

		/**
		 * Computed Is Page Loading
		 *
		 * Return a boolean for the page loading spinner to denote if all data has been loaded.
		 *
		 * @returns {boolean} if data has been loaded or not
		 */
		computedIsPageLoading() {
			const t = this

			t.isPageLoading = !(
				t.isTenantsCollectionDataLoaded &&
				t.isUserCollectionDataLoaded
			)

			return t.isPageLoading
		},

	},

	methods: {

		/**
		 * Get User Data
		 *
		 * Fetch the data from the collection and assign them to the class state.
		 * Call for the current user's Tenant data when done.
		 *
		 * @returns {Promise<void>}
		 */
		async getUserCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('users')
				.where('id', '==', t.GET_currentUser?.id)
				.onSnapshot(async snapshot => {

					// Clear the array to avoid duplications
					collectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only push active documents
						if (!document.hasOwnProperty('delete')) collectionData.push(document)

					})

					// Assign the documents in one and set isLoaded to true
					t.userCollectionData = collectionData
					t.isUserCollectionDataLoaded = true

					await t.getTenantsCollectionData()
				})
		},

		/**
		 * Get Collection Data
		 *
		 * Fetch the data from the collection and assign them to the class state.
		 *
		 * @returns {Promise<void>}
		 */
		async getTenantsCollectionData() {
			const t = this
			let tenantsCollectionData = []

			await t.$firebase.db.collection('tenants')
				.where('id', '==', t.userCollectionData[0].userCompanyId)
				.onSnapshot(snapshot => {

					// Clear the array to avoid duplications
					tenantsCollectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only push active documents
						if (!document.hasOwnProperty('delete')) tenantsCollectionData.push(document)

					})

					// Assign the documents in one and set isLoaded to true
					t.tenantsCollectionData = tenantsCollectionData
					t.isTenantsCollectionDataLoaded = true
				})
		},

		/**
		 * Close Right Panel
		 *
		 * When closing the form, reset it to ensure it opens as new.
		 */
		closeRightPanel() {
			const t = this

			t.isFormReadOnly = true
		},

		/**
		 * Edit Form
		 *
		 * Toggle the read only state of the form.
		 */
		editForm() {
			const t = this

			t.isFormReadOnly = !t.isFormReadOnly;
		},

		/**
		 * Emitted Close Right Panel
		 *
		 * Take the emitted message and call to close the right panel.
		 *
		 * @returns {Promise<void>}
		 */
		async emittedCloseRightPanel() {
			const t = this

			t.closeRightPanel()
		},

	},

	async mounted() {
		const t = this

		await t.getUserCollectionData()
		// await t.getTenantsCollectionData()
	}

}
</script>

<style scoped>

</style>
