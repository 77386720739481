<!--
Sites

Renders the Sites page which displays the details for each site.
This renders as either a table (tablets and desktops) or cards (mobile).

Sites also has a form for adding/editing/deleting site details, and add/viewing new locations.
Each location can also print its own QR code poster enabling personnel to SWAPP in/out.
-->
<template>
    <v-row no-gutters class="fill-height noprint" style="width: 100% !important;">

        <!--Left panel-->
        <v-col :cols="GET_panelLayout.leftPanel" class="pa-4">

            <!--Title-->
            <page-title icon="icons8-skyscrapers"
                        pageTitle="Sites"
                        description="Add Sites as required. If Users are required to SWAPP in to a site, it must also have a location."
                        :divider="true"/>

            <!--Searches-->
            <v-row no-gutters>
                <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                    <app-input input-type="textInput"
                               label="Site Name..."
                               :append-icon="'icons8-search'"
                               v-model.trim="searchBySiteName"/>
                </v-col>
            </v-row>

            <!--Action buttons > 600-->
            <div v-if="$vuetify.breakpoint.width >= 600 && GET_currentUser.userLevel === 'SA'"
                 class="d-flex justify-end align-end mt-4">

                <!--Add button-->
                <!--<app-btn v-if="['SA'].includes(GET_currentUser.userLevel) && $vuetify.breakpoint.width >= 732"-->
                <!--         @click.native="openItem('', 'New', false)"-->
                <!--         color="primary mr-4"-->
                <!--         icon="icons8-skyscrapers"-->
                <!--         label="New"/>-->

                <!--Columns button-->
                <app-btn @click.native="columnsDialog = true"
                         color="primary mr-4"
                         hide-label-below="1060"
                         icon="icons8-select-column"
                         label="Columns"/>

                <!--Export button-->
                <app-btn @click.native="MIX_exportDocuments(headersCSV,'sites',formatExport)"
                         color="primary"
                         hide-label-below="1060"
                         icon="icons8-export-csv"
                         label="Export"/>

            </div>

            <!--Action buttons < 600-->
            <!--<app-btn v-if="['SA'].includes(GET_currentUser.userLevel) && $vuetify.breakpoint.width < 732"-->
            <!--         @click.native="openItem('', 'New', false)"-->
            <!--         :block="true"-->
            <!--         color="primary mr-4 mt-4"-->
            <!--         icon="icons8-skyscrapers"-->
            <!--         label="New"/>-->

            <!--Table-->
            <v-data-table v-if="$vuetify.breakpoint.width >= 600"
                          class="rounded-lg mt-4"
                          :headers="computedHeaders"
                          :items="computedTableData"
                          item-key="id">

                <!--Id-->
                <template v-slot:item.id="{ item }">
                    <app-text category="text-small">{{ item.id }}</app-text>
                </template>

                <!--Site name-->
                <template v-slot:[`item.siteName`]="{ item }">
                    <app-text category="text-small">{{ item.siteName }}</app-text>
                </template>

                <!--Telephone-->
                <template v-slot:[`item.siteTelephone`]="{ item }">
                    <app-text category="text-small">{{ item.siteTelephone }}</app-text>
                </template>

                <!--Contact name-->
                <template v-slot:[`item.siteContact.contactName`]="{ item }">
                    <sites-manager-selection-modal :site="item"
                                                   :users="usersData"
                                                   v-on:updateManager="updateWithNewManager"/>
                </template>

                <!--Address Line 1-->
                <template v-slot:[`item.siteAddress.addressLine1`]="{ item }">
                    <app-text category="text-small">{{ item.siteAddress.addressLine1 }}</app-text>
                </template>

                <!--Address Line 2-->
                <template v-slot:[`item.siteAddress.addressLine2`]="{ item }">
                    <app-text category="text-small">{{ item.siteAddress.addressLine2 }}</app-text>
                </template>

                <!--Address Line 3-->
                <template v-slot:[`item.siteAddress.addressLine3`]="{ item }">
                    <app-text category="text-small">{{ item.siteAddress.addressLine3 }}</app-text>
                </template>

                <!--Town-->
                <template v-slot:[`item.siteAddress.town`]="{ item }">
                    <app-text category="text-small">{{ item.siteAddress.town }}</app-text>
                </template>

                <!--County-->
                <template v-slot:[`item.siteAddress.county`]="{ item }">
                    <app-text category="text-small">{{ item.siteAddress.county }}</app-text>
                </template>

                <!--Postcode-->
                <template v-slot:[`item.siteAddress.postcode`]="{ item }">
                    <app-text category="text-small">{{ item.siteAddress.postcode }}</app-text>
                </template>

                <!--Contact position-->
                <template v-slot:[`item.siteContact.contactPosition`]="{ item }">
                    <app-text category="text-small">{{ item.siteContact.contactPosition }}</app-text>
                </template>

                <!--Contact telephone-->
                <template v-slot:[`item.siteContact.contactTelephone`]="{ item }">
                    <app-text category="text-small">{{ item.siteContact.contactTelephone }}</app-text>
                </template>

                <!--Contact mobile-->
                <template v-slot:[`item.siteContact.contactMobile`]="{ item }">
                    <app-text category="text-small">{{ item.siteContact.contactMobile }}</app-text>
                </template>

                <!--Contact email-->
                <template v-slot:[`item.siteContact.contactEmail`]="{ item }">
                    <app-text category="text-small">{{ item.siteContact.contactEmail }}</app-text>
                </template>

                <!--Action button-->
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn @click="openItem(item.id, 'View', false)"
                           depressed class="primary--text white">
                        <v-icon class="icons8-forward" color="primary"/>
                    </v-btn>
                </template>

            </v-data-table>

            <!--Mobile Cards-->
            <div v-if="$vuetify.breakpoint.width < 600">

                <v-card v-for="site in computedTableData" :key="site.id"
                        @click="openItem(site.id, 'View', false)"
                        class="rounded-lg mt-4 pa-4" flat>

                    <!--Site name-->
                    <app-text category="text-default-bold">{{ site.siteName }}</app-text>

                    <!--Site address-->
                    <app-text>{{ computedSiteAddress(site) }}</app-text>

                </v-card>

            </div>

        </v-col>

        <!--Right panel-->
        <transition name="custom-classes-transition"
                    enter-active-class="animate__animated animate__fadeIn animated__faster"
                    leave-active-class="animate__animated animate__fadeOut animated__faster"
                    mode="out-in">
            <rightpanel></rightpanel>
            <!-- <component :is="dynamicComponent" :key="dynamicComponentKey"></component>      -->
        </transition>

        <!--Column selection box-->
        <v-dialog v-model="columnsDialog" scrollable max-width="300px">
            <v-card>
                <!--Title-->
                <app-text category="text-medium" class="primary--text ma-4">Show/Hide Columns</app-text>

                <v-divider/>

                <!--List items-->
                <v-card-text>
                    <v-list>
                        <v-list-item v-for="(header, index) in headers.slice(0, -1)" :key="index">
                            <app-text>
                                <v-checkbox color="grey darken-1" hide-details v-model="headers[index].hidden"
                                            :false-value="true" :true-value="false" :label="header.text"/>
                            </app-text>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-divider/>

                <!--Close button-->
                <v-card-actions class="text-right">
                    <v-spacer/>
                    <v-btn @click="columnsDialog = false" color="primary" text>Close</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

    </v-row>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import sitesManagerSelectionModal from './sitesManagerSelectionModal/sitesManagerSelectionModal'

export default {
    name: "Sites",

    components: {
        sitesManagerSelectionModal
    },

    data: () => ({
        title: 'Sites',
        collection: 'sites',
        collectionItem: 'sites',
        collectionItemTitle: 'Site',
        columnsDialog: false,
        customWhere: [],
        exportSites: [],
        headers: [
            {
                text: 'Id',
                value: 'id',
                align: 'start',
                sortable: false,
                hidden: true,
                hide: true
            },
            {
                text: 'Name',
                value: 'siteName',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: false
            },
            // {
            //     text: 'Telephone',
            //     value: 'siteTelephone',
            //     align: 'start',
            //     sortable: false,
            //     hidden: false,
            //     ide: false,
            // },
            {
                text: 'Address Line 1',
                value: 'siteAddress.addressLine1',
                align: 'start',
                sortable: false,
                hidden: true,
                hide: false
            },
            {
                text: 'Address Line 2',
                value: 'siteAddress.addressLine2',
                align: 'start',
                sortable: false,
                hidden: true,
                hide: false
            },
            {
                text: 'Address Line 3',
                value: 'siteAddress.addressLine3',
                align: 'start',
                sortable: false,
                hidden: true,
                hide: false
            },
            {
                text: 'Town',
                value: 'siteAddress.town',
                align: 'start',
                sortable: false,
                hidden: true,
                hide: false
            },
            {
                text: 'County',
                value: 'siteAddress.county',
                align: 'start',
                sortable: false,
                hidden: true,
                hide: false
            },
            {
                text: 'Postcode',
                value: 'siteAddress.postcode',
                align: 'start',
                sortable: false,
                hidden: true,
                hide: false
            },
            {
                text: 'Site Contact Name',
                value: 'siteContact.contactName',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: 'Site Contact Position',
                value: 'siteContact.contactPosition',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: 'Site Contact Telephone',
                value: 'siteContact.contactTelephone',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: false,
            },
            // {
            //     text: 'Site Contact Mobile',
            //     value: 'siteContact.contactMobile',
            //     align: 'start',
            //     sortable: false,
            //     hidden: false,
            //     hide: false,
            // },
            {
                text: 'Site Contact Email',
                value: 'siteContact.contactEmail',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: '',
                value: 'actions',
                align: 'right',
                sortable: false,
                hidden: false,
                hide: false,
                width: '96px'
            },
        ],
        headersCSV: {
            // clientName: 'Client Name',
            siteName: 'Site Name',
            siteTelephone: 'Site Telephone',
            siteAddressLine1: 'Site Address Line 1',
            siteAddressLine2: 'Site Address Line 2',
            siteAddressLine3: 'Site Address Line 3',
            siteTown: 'Site Town',
            siteCounty: 'Site County',
            sitePostcode: 'Site Postcode',
            // siteArea: 'Site Area',
            // siteCommercialManager: 'Commercial Manager',
            // siteManager: 'Site Manager',
            siteContactName: 'Site Contact Name',
            siteContactPosition: 'Site Contact Position',
            siteContactTelephone: 'Site Contact Telephone',
            siteContactMobile: 'Site Contact Mobile',
            siteContactEmail: 'Site Contact Email',
            // ppeRequired: 'PPE Required',
            // dbsCheck: 'DBS Check',
            // siteSector: 'Site Sector',
            siteNotes: 'Site Notes'
        },
        searchBySiteName: '',
        tableData: [],
        usersData: [],
    }),

    computed: {
        ...mapGetters({
            GET_panelLayout: 'GET_panelLayout',
            GET_currentUser: 'GET_currentUser',
        }),

        formatExport() {
            const t = this
            const sites = JSON.parse(JSON.stringify(t.tableData))
            t.exportSites = []

            for (let i = 0; i < sites.length; i++) {
                // var clientName = "";
                // if (sites[i].clientData.clientName) {
                //     clientName = sites[i].clientData.clientName
                // } else {
                //     clientName = "UNKNOWN"
                // }
                const siteName = "";
                if (sites[i].siteName) {
                    siteName = sites[i].siteName
                } else {
                    siteName = "UNKNOWN"
                }
                const siteTelephone = "";
                if (sites[i].siteTelephone) {
                    siteTelephone = sites[i].siteTelephone
                } else {
                    siteTelephone = "UNKNOWN"
                }
                const siteAddressLine1 = "";
                if (sites[i].siteAddress.addressLine1) {
                    siteAddressLine1 = sites[i].siteAddress.addressLine1
                } else {
                    siteAddressLine1 = "UNKNOWN"
                }
                const siteAddressLine2 = "";
                if (sites[i].siteAddress.addressLine2) {
                    siteAddressLine2 = sites[i].siteAddress.addressLine2
                } else {
                    siteAddressLine2 = "UNKNOWN"
                }
                const siteAddressLine3 = "";
                if (sites[i].siteAddress.addressLine3) {
                    siteAddressLine3 = sites[i].siteAddress.addressLine3
                } else {
                    siteAddressLine3 = "UNKNOWN"
                }
                const siteTown = "";
                if (sites[i].siteAddress.town) {
                    siteTown = sites[i].siteAddress.town
                } else {
                    siteTown = "UNKNOWN"
                }
                const siteCounty = "";
                if (sites[i].siteAddress.county) {
                    siteCounty = sites[i].siteAddress.county
                } else {
                    siteCounty = "UNKNOWN"
                }
                const sitePostcode = "";
                if (sites[i].siteAddress.postcode) {
                    sitePostcode = sites[i].siteAddress.postcode
                } else {
                    sitePostcode = "UNKNOWN"
                }
                // const siteArea = "";
                // if (sites[i].siteArea) {
                //     siteArea = sites[i].siteArea
                // } else {
                //     siteArea = "UNKNOWN"
                // }
                // const siteCommercialManager = "";
                // if (sites[i].siteCommercialManager) {
                //     siteCommercialManager = sites[i].siteCommercialManager
                // } else {
                //     siteCommercialManager = "UNKNOWN"
                // }
                // const siteManager = "";
                // if (sites[i].siteManager) {
                //     siteManager = sites[i].siteManager
                // } else {
                //     siteManager = "UNKNOWN"
                // }
                const siteContactName = "";
                if (sites[i].siteContact.contactName) {
                    siteContactName = sites[i].siteContact.contactName
                } else {
                    siteContactName = "UNKNOWN"
                }
                const siteContactPosition = "";
                if (sites[i].siteContact.contactPosition) {
                    siteContactPosition = sites[i].siteContact.contactPosition
                } else {
                    siteContactPosition = "UNKNOWN"
                }
                const siteContactTelephone = "";
                if (sites[i].siteContact.contactTelephone) {
                    siteContactTelephone = sites[i].siteContact.contactTelephone
                } else {
                    siteContactTelephone = "UNKNOWN"
                }
                const siteContactMobile = "";
                if (sites[i].siteContact.contactMobile) {
                    siteContactMobile = sites[i].siteContact.contactMobile
                } else {
                    siteContactMobile = "UNKNOWN"
                }
                const siteContactEmail = "";
                if (sites[i].siteContact.contactEmail) {
                    siteContactEmail = sites[i].siteContact.contactEmail
                } else {
                    siteContactEmail = "UNKNOWN"
                }
                // const ppeRequired = "";
                // if (sites[i].ppeRequired) {
                //     ppeRequired = sites[i].ppeRequired
                // } else {
                //     ppeRequired = "UNKNOWN"
                // }
                // const dbsCheck = "";
                // if (sites[i].dbsCheck) {
                //     dbsCheck = sites[i].dbsCheck
                // } else {
                //     dbsCheck = "UNKNOWN"
                // }
                // const siteSector = "";
                // if (sites[i].siteSector) {
                //     siteSector = sites[i].siteSector
                // } else {
                //     siteSector = "UNKNOWN"
                // }
                const siteNotes = "";
                if (sites[i].siteNotes) {
                    siteNotes = sites[i].siteNotes
                } else {
                    siteNotes = "UNKNOWN"
                }
                this.exportSites.push({
                    // clientName: clientName,
                    siteName: siteName,
                    siteTelephone: siteTelephone,
                    siteAddressLine1: siteAddressLine1,
                    siteAddressLine2: siteAddressLine2,
                    siteAddressLine3: siteAddressLine3,
                    siteTown: siteTown,
                    siteCounty: siteCounty,
                    sitePostcode: sitePostcode,
                    // siteArea: siteArea,
                    // siteCommercialManager: siteCommercialManager,
                    // siteManager: siteManager,
                    siteContactName: siteContactName,
                    siteContactPosition: siteContactPosition,
                    siteContactTelephone: siteContactTelephone,
                    siteContactMobile: siteContactMobile,
                    siteContactEmail: siteContactEmail,
                    // ppeRequired: ppeRequired,
                    // dbsCheck: dbsCheck,
                    // siteSector: siteSector,
                    siteNotes: siteNotes
                })
            }
            return t.exportSites;
        },

        computedCols() {
            const t = this
            let cols
            let leftPanel = this.GET_panelLayout.leftPanel

            switch (true) {
                case (leftPanel === 12):
                    cols = 6
                    break
                case (leftPanel === 6):
                    cols = 12
                    break;
                default:
                    cols = 12
            }

            if (this.$vuetify.breakpoint.smAndDown) {
                cols = 12
            }

            return cols
        },

        computedHeaders() {
            const t = this
            const headers = this.headers

            return (this.$filter(headers, {
                hidden: false, // filter headers based on initial hidden value
            }))
        },

        computedTableData() {
            const t = this
            let tableData = t.tableData

            if (t.searchBySiteName) {
                tableData = tableData.filter(site => site.siteName.toUpperCase().match(t.searchBySiteName.toUpperCase()))
            }

            // Sort Alphabetically
            tableData = tableData.sort((a, b) => a.siteName > b.siteName ? 1 : -1)

            return tableData
        },

    },

    methods: {
        ...mapActions({'ACT_openItem': 'ACT_openSites', 'ACT_openSites': 'ACT_openSites'}),

        /**
         * Get Users Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Users collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this

            await t.$firebase.db.collection('users')
                .onSnapshot(snapshot => {

                // Clear the data to avoid duplications
                t.usersData = []

                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) t.usersData.push(document)
                })
            })
        },

        async getSitesCollectionData() {
            const t = this

            let collection = await t.$firebase.db.collection('sites')

            if (JSON.stringify(t.customWhere) !== '[]') {

                for (let i = 0; i < t.customWhere.length; i++) {
                    const query = {
                        field: t.customWhere[i].key,
                        operator: t.customWhere[i].operator,
                        value: t.customWhere[i].value
                    }

                    collection = collection.where(query.field, query.operator, query.value)
                }
            }

            collection.onSnapshot(function (querySnapshot) {

                // Clear the array to avoid data duplication
                t.tableData = []

                querySnapshot.forEach(function (doc) {

                    const document = doc.data()
                    document.id = doc.id

                    if (!document.hasOwnProperty('delete')) t.tableData.push(document)
                })
            })

        },

        async openItem(id, process, popup) {
            const t = this
            const item = {}

            item.process = process
            item.popup = popup

            if (item.process !== 'New') {
                const itemResult = await t.MIX_readDocumentById('sites', id)
                item.data = itemResult.data
            } else {
                item.data = null
            }

            t.ACT_openItem(item)

            if (t.$vuetify.breakpoint.lgAndDown) {
                t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem)
                t.MIX_setPanelLayout(12, 0, false, '')
            } else {
                t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem)

                // * HIDE HEADERS
                for (let i = 0; i < t.headers.length; i++) {
                    if (t.headers[i].hide === true) {
                        t.headers[i].hidden = true
                    }
                }
            }

        },

        /**
         * Update With New Manager
         *
         * Update the site details with the new site manager's: name, position, telephone and email.
         *
         * @returns {Promise<void>}
         */
        async updateWithNewManager(payload) {
            const t = this
            const {manager, site} = payload

            // Add new values to existing document
            const form = {
                ...site,
                siteContact: {
                    contactName: manager.userName,
                    contactPosition: manager.userPosition,
                    contactTelephone: manager.userTelephone,
                    contactEmail: manager.userEmail,
                }
            }

            // Update the document
            const updateDocumentResult = await this.MIX_updateDocument(t.collection, form)

            // Call for the confirmation box
            t.renderConfirmationAlert(updateDocumentResult,
                'Site manager successfully updated',
                'Error updating site manager')
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document, successMessage, failureMessage) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, successMessage, null, null)
            } else {
                t.MIX_alert(-1, failureMessage, null, null)
            }
        },

        computedSiteAddress(site) {
            let address = ''

            if (site.siteAddress.addressLine1) {
                address += site.siteAddress.addressLine1 + ', '
            }

            if (site.siteAddress.town) {
                address += site.siteAddress.town + ', '
            }

            if (site.siteAddress.postcode) {
                address += site.siteAddress.postcode
            }

            return address

        },

    },

    async mounted() {
        const t = this

        t.MIX_setPanelLayout(12, 0, false, ''); // Hide Right Panel
        ////t.updateBreadcrumbs(); // Update Breadcrumbs in Title
        // INSERT CUSTOM WHERES HERE
        // t.customWhere.push ({ key: '', operator: '==', value: '' });
        t.getSitesCollectionData(); // Get Table Data

        await t.getUsersCollectionData()
    }
}
</script>
