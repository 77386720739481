<template>
    <v-form>

        {{ computedFormData }}

        <!--Form Fields-->
        <v-row class="d-flex flex-column" no-gutters>

            <app-text>Uploaded files must be in either a PDF, .docx, .pptx, .xlsx, JPG or PNG format.</app-text>

            <!--Document Name-->
            <app-input input-type="textInput"
                       class="mt-4"
                       :error="errors.documentName"
                       :error-messages="errors.documentNameErrorMessage"
                       :is-form-read-only="computedIsFormReadOnly"
                       label="Document Name"
                       v-model.trim="form.documentName"/>

            <!--Photo Upload-->
            <div class="d-flex align-center mt-4">
                <photoupload :allowedtypes="['application/pdf', '.docx', 'application/msword', 'image/*']"
                             :disabled="computedIsFormReadOnly"
                             :docLink="{collection: 'documentation', documentId: form.id}"
                             label="Add File"
                             folder="documentation"/>

                <div>

                    <!--File Name-->
                    <app-text v-if="fileUploadResult">
                        {{ fileUploadResult.fileName }}
                    </app-text>

                    <!--File Size-->
                    <app-text v-if="fileUploadResult">
                        {{ (fileUploadResult.fileSize / 1024).toFixed(1) }}
                        <span class="grey--text">KB</span>
                    </app-text>

                </div>

            </div>

            <!--Save button-->
            <div v-if="!computedIsFormReadOnly" class="d-flex justify-end mt-4">
                <app-btn color="success"
                         :disabled="!form.id && !(form.documentName && (fileUploadResult && fileUploadResult.id))"
                         icon="icons8-save" label="Save" @click.native="saveDocument"/>
            </div>

        </v-row>

    </v-form>
</template>

<script>
import {mapGetters} from "vuex";

export default {

    name: "documentationForm",

    props: ['doesFormStartReadOnly', 'formData', 'tenantsData'],

    data: () => ({
        errors: {
            documentName: false,
            documentNameErrorMessage: '',
        },
        form: {
            id: '',
            documentName: '',
            documentFile: null,
            documentCompanyId: '',
        },
        imagePreview: '',
        isFormReadOnly: false,
        fileUploadResult: null,
    }),

    computed: {
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
            GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
        }),

        /**
         * Computed From Data
         *
         * If this is an existing document, set the form data.
         * If it's not, the default form will be used.
         */
        computedFormData() {
            const t = this

            const formData = t.$props.formData

            if (formData?.id) t.form = formData

        },

        /**
         * Computed Is Form Read Only
         *
         * When the form is first created in the Supervisor's Control Panel,
         * the form should be editable.
         * If the form is open through the Reporting pages, it should open uneditable.
         *
         * @returns {boolean} is form read only
         */
        computedIsFormReadOnly() {
            const t = this

            return t.$props.doesFormStartReadOnly
                ? t.$props.doesFormStartReadOnly
                : t.isFormReadOnly
        },

    },

    methods: {

        /**
         * Clear Errors and Messages
         *
         * Clear all the errors and error messages from the inputs.
         */
        clearErrorsAndMessages() {
            const t = this

            t.errors.documentName = false
            t.errors.documentNameErrorMessage = ''
        },

        /**
         * Save Document
         *
         * Create/update the document.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this
            let document

            if (t.validateForm()) {

                // Create
                if (!t.form?.id) {

                    // Generate new IDs
                    t.form.id = t.MIX_generateId()
                    t.form.documentCompanyId = t.GET_currentUser.userCompanyId

                    // Save new Organisation document
                    document = await t.MIX_createDocument('documentation', t.form)

                }

                // Update
                else {

                    document = await t.MIX_updateDocument('documentation', t.form)

                }

                if (t.imagePreview && document.code === 1) await t.uploadFile()

                t.MIX_renderConfirmationAlert(
                    document,
                    'Successfully Saved Documentation',
                    'Error Saving Documentation'
                )

                t.$emit('emitCloseRightPanel')
            }

        },

        /**
         * Upload File
         *
         * Upon successful saving/updating of a document, also save the file's image path.
         *
         * @returns {Promise<void>}
         */
        async uploadFile() {
            const t = this

            if (t.fileUploadResult !== {}) {

                // Save to the document with: collection | user id | image path
                const updatePhotosResult = await this.MIX_updateDocumentFieldsById(
                    'documentation',
                    t.form.id,
                    {documentFile: t.fileUploadResult.fileURL}
                )

                t.MIX_renderConfirmationAlert(
                    updatePhotosResult,
                    'Successfully Uploaded File',
                    'Error Uploading File'
                )

            }
        },

        /**
         * Validate Form
         *
         * Validate all required form fields before saving.
         *
         * @returns {boolean} if the form has passed validation or not
         */
        validateForm() {
            const t = this

            t.clearErrorsAndMessages()

            // Document Name
            if (!t.form.documentName?.trim()) {
                t.errors.documentName = true
                t.errors.documentNameErrorMessage = 'Document Name required'
            }

            return !Object.values(t.errors).includes(true)
        }

    },

    watch: {

        /**
         * Photo Upload Result
         *
         * On upload of a profile picture, add its storage path to the fileUploadResult variable.
         */
        GET_photoUploadResult: {
            async handler() {
                const t = this

                t.fileUploadResult = t.GET_photoUploadResult
                t.form.documentFileType = t.GET_photoUploadResult.fileType
                t.imagePreview = t.fileUploadResult.fileURL

            }, deep: true
        },

    }

}
</script>

<style scoped>

</style>
