<template>
    <v-list nav dense class="primary">

        <v-list-item v-for="(item, index) in computedTabs"
                     :key="index"
                     link
                     @click="go(item.path, item.name)"
                     class="list-item">

            <v-list-item-icon class="white--text">
                <v-icon class="navicons white--text">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title class="white--text text-left">{{ item.name }}</v-list-item-title>

        </v-list-item>

    </v-list>

</template>

<script>
import {mapGetters} from 'vuex';

export default {

    name: "MenuList",

    data: () => ({
        tabs: [
            // {name: 'Home', path: "/", icon: "icons8-home", userLevels: ['SA']},
            {name: 'Dashboard', path: "/dashboard", icon: "icons8-content", userLevels: ['SA', 'SU', 'TA', 'TU']},
            {name: 'Covid', path: "/covidAnswers", icon: "icons8-coronavirus", userLevels: ['SA']},
            {name: 'Documentation', path: "/documentation", icon: "icons8-image-document", userLevels: ['SA', 'SU', 'TA', 'TU']},
            {
                name: 'Fire Roll Call',
                path: "/fireRollCall",
                icon: "icons8-fires",
                userLevels: ['SA', 'SU']
            },
            {name: 'Notice Board', path: "/noticeBoards", icon: "icons8-task-completed", userLevels: ['SA', 'SU']},
            {name: 'Observations', path: "/observations", icon: "icons8-eye", userLevels: ['SA', 'SU', 'TA', 'TU']},
            // {name: 'IT Support', path: "/itSupport", icon: "icons8-computer-support", userLevels: ['SA', 'SU', 'DA']},
            // {name: 'Questions', path: "/questions", icon: "icons8-ask-question", userLevels: ['SA']},
            // {name: 'Contractors', path: "/contractors", icon: "icons8-business-building", userLevels: ['SA']},
            // {name: 'Contractors Area', path: "/contractorsArea", icon: "icons8-business-building", userLevels: ['CU']},
            {name: 'Sites', path: "/sites", icon: "icons8-skyscrapers", userLevels: ['SA', 'SU']},
            {
                name: 'Staff',
                path: "/staff-directory",
                icon: "icons8-people",
                userLevels: ['SA', 'SU', 'TA', 'TU']
            },
            {name: 'Tenants', path: "/tenants", icon: "icons8-business-building", userLevels: ['SA', 'SU']},
            {
                name: 'My Profile',
                path: "/userProfile",
                icon: "icons8-customer",
                userLevels: ['SA', 'SU', 'TA', 'TU']
            },
            {
                name: 'My Schedule',
                path: "/userSchedule",
                icon: "icons8-schedule",
                userLevels: ['SA', 'SU', 'TA', 'TU']
            },
            {name: 'My Tenant', path: "/myTenant", icon: "icons8-business-building", userLevels: ['TA', 'TU']},
            {
                name: 'AppSupport',
                path: "/appSupport",
                icon: "icons8-online-support",
                userLevels: ['SA', 'SU', 'TA', 'TU']
            },
            {name: 'Settings', path: "/settings", icon: "icons8-settings", userLevels: ['DA']},
            // {name: 'Concerns', path: "/concerns", icon: "icons8-siren", userLevels: ['SA', 'SU', 'DA']},
        ],
        langPath: 'menuItems'
    }),

    computed: {
        ...mapGetters({
            GET_mobileNav: 'GET_mobileNav',
            GET_currentUser: 'GET_currentUser'
        }),

        computedTabs() {
            const t = this
            const tabs = t.tabs
            const newTabs = []

            // If the current user has access, display the link
            tabs.forEach(function (tab) {
                if (tab.userLevels.includes(t.GET_currentUser.userLevel)) {

                    // Only show Fire Roll Call to Admins and those with access
                    if (tab.name === 'Fire Roll Call') {
                        if (!['SA', 'TA'].includes(t.GET_currentUser.userLevel) && !t.GET_currentUser.userAccessAndAbilities.includes('Fire Marshall')) {
                            return
                        }
                    }

                    // Only show Covid to those with access
                    if (tab.name === 'Covid') {
                        if (!t.GET_currentUser.userAccessAndAbilities.includes('Covid')) {
                            return
                        }
                    }

                    // Only show Contractors to those with access
                    if (tab.name === 'Contractors') {
                        if (!t.GET_currentUser.userAccessAndAbilities.includes('Create Contractors')) {
                            return
                        }
                    }

                    newTabs.push(tab)
                }
            })

            return newTabs
        }
    },

    methods: {
        // // updateBreadcrumbs(name, path) {
        // //     var t = this;
        // //     var breadcrumbs = [];
        // //     var uperrcaseName = t.MIX_capitalizeText(name);
        // //     breadcrumbs.push ({ name: uperrcaseName, path: path}) // Set the Main Path
        // //     t.MIX_breadcrumbs(breadcrumbs);
        // // },

        go(path, name) {
            this.$router.push(path).catch((err) => {
                console.log("Route error: " + err)
            })
            this.MIX_mobileNav(false)
            //// this.updateBreadcrumbs(name, path);
        },
    }

}
</script>

<style scoped>

.list-item {
    transition: 0.3s
}

.list-item:hover {
    background: var(--v-accent-base)
}

.navicons {
    font-size: 24px !important;
    font-weight: bold !important;
}

</style>
