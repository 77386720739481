<template>
    <v-form>

        {{ computedFormData }}

        <!--Form Fields-->
        <div class="d-flex flex-column">

            <!--Tenant Details-->
            <div>

                <!--Section title-->
                <app-text category="text-medium" class="grey--text">Tenant Details</app-text>
                <v-divider class="mt-2 mb-4"/>

                <!--Form Fields-->
                <v-row no-gutters>

                    <!--Tenant Name-->
                    <v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
                           :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
                        <app-input input-type="textInput"
                                   :error="errors.tenantName"
                                   :error-messages="errors.tenantNameErrorMessage"
                                   :is-form-read-only="computedIsFormReadOnly"
                                   label="Tenant Name"
                                   v-model.trim="form.tenantName"/>
                    </v-col>

                    <!--Floor-->
                    <v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
                           :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                        <app-input input-type="textInput"
                                   :error="errors.tenantFloor"
                                   :error-messages="errors.tenantFloorErrorMessage"
                                   :is-form-read-only="computedIsFormReadOnly"
                                   label="Floor"
                                   v-model.trim="form.tenantFloor"/>
                    </v-col>

                </v-row>

            </div>

            <!--Contact Details-->
            <div>

                <!--Section title-->
                <app-text category="text-medium" class="grey--text mt-4">Primary Contact</app-text>
                <v-divider class="mt-2 mb-4"/>

                <!--Contact Email | Contact Telephone-->
                <v-row no-gutters>

                    <!--Contact Name-->
                    <v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
                           :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
                        <app-input input-type="textInput"
                                   :error="errors.tenantContactName"
                                   :error-messages="errors.tenantContactNameErrorMessage"
                                   :is-form-read-only="computedIsFormReadOnly"
                                   label="Contact Name"
                                   v-model.trim="form.tenantContactName"/>
                    </v-col>

                    <!--Contact Email-->
                    <v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
                           :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                        <app-input input-type="textInput"
                                   :error="errors.tenantContactEmail"
                                   :error-messages="errors.tenantContactEmailErrorMessage"
                                   :is-form-read-only="computedIsFormReadOnly"
                                   label="Contact Email"
                                   type="email"
                                   v-model.trim="form.tenantContactEmail"/>
                    </v-col>

                    <!--Contact Telephone-->
                    <v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
                           :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                        <app-input input-type="textInput"
                                   :error="errors.tenantContactTelephone"
                                   :error-messages="errors.tenantContactTelephoneErrorMessage"
                                   :is-form-read-only="computedIsFormReadOnly"
                                   label="Contact Telephone"
                                   type="telephone"
                                   v-model.trim="form.tenantContactTelephone"/>
                    </v-col>

                </v-row>

            </div>

            <!--Additional Details-->
            <div>

                <!--Section title-->
                <app-text category="text-medium" class="grey--text mt-4">Additional Information (optional)</app-text>
                <v-divider class="mt-2 mb-4"/>

                <!--Text Area-->
                <app-input input-type="textArea"
                           :is-form-read-only="computedIsFormReadOnly"
                           label="Text Area"
                           v-model.trim="form.tenantAdditionalInformation"/>

            </div>

            <!--Save button-->
            <div v-if="!computedIsFormReadOnly" class="d-flex justify-end mt-4">
                <app-btn color="success"
                         icon="icons8-save" label="Save" @click.native="saveDocument"/>
            </div>

        </div>

    </v-form>
</template>

<script>
export default {

    name: "tenantsForm",

    props: ['doesFormStartReadOnly', 'formData'],

    data: () => ({
        errors: {
            tenantName: false,
            tenantNameErrorMessage: '',
            tenantContactTelephone: false,
            tenantContactTelephoneErrorMessage: '',
            tenantContactEmail: false,
            tenantContactEmailErrorMessage: '',
            tenantContactName: false,
            tenantContactNameErrorMessage: '',
            tenantFloor: false,
            tenantFloorErrorMessage: '',
        },
        form: {
            id: '',
            tenantName: '',
            tenantContactTelephone: '',
            tenantContactName: '',
            tenantContactEmail: '',
            tenantFloor: '',
            tenantAdditionalInformation: '',

        },
        isFormReadOnly: false,
    }),

    computed: {

        /**
         * Computed From Data
         *
         * If this is an existing document, set the form data.
         * If it's not, the default form will be used.
         */
        computedFormData() {
            const t = this

            const formData = t.$props.formData

            if (formData?.id) {

                // Assign data
                t.form = formData
            }

        },

        /**
         * Computed Is Form Read Only
         *
         * When the form is first created in the Supervisor's Control Panel,
         * the form should be editable.
         * If the form is open through the Reporting pages, it should open uneditable.
         *
         * @returns {boolean} is form read only
         */
        computedIsFormReadOnly() {
            const t = this

            return t.$props.doesFormStartReadOnly
                ? t.$props.doesFormStartReadOnly
                : t.isFormReadOnly
        },

    },

    methods: {

        /**
         * Clear Errors and Messages
         *
         * Clear all the errors and error messages from the inputs.
         */
        clearErrorsAndMessages() {
            const t = this

            t.errors.tenantName = false
            t.errors.tenantNameErrorMessage = ''
            t.errors.tenantContactTelephone = false
            t.errors.tenantContactTelephoneErrorMessage = ''
            t.errors.tenantContactEmail = false
            t.errors.tenantContactEmailErrorMessage = ''
            t.errors.tenantContactName = false
            t.errors.tenantContactNameErrorMessage = ''
            t.errors.tenantFloor = false
            t.errors.tenantFloorErrorMessage = ''
        },

        /**
         * Save Document
         *
         * Create/update the document.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this
            let document

            if (t.validateForm()) {

                // Create
                if (!t.form?.id) {

                    // Generate new IDs
                    t.form.id = t.MIX_generateId()

                    // Save new Organisation document
                    document = await t.MIX_createDocument('tenants', t.form)

                }

                // Update
                else {

                    document = await t.MIX_updateDocument('tenants', t.form)

                }

                t.MIX_renderConfirmationAlert(
                    document,
                    'Successfully Saved Tenant',
                    'Error Saving Tenant'
                )

                t.$emit('emitCloseRightPanel')

            }

        },

        /**
         * Validate Form
         *
         * Validate all required form fields before saving.
         *
         * @returns {boolean} if the form has passed validation or not
         */
        validateForm() {
            const t = this

            t.clearErrorsAndMessages()

            // Tenant Name
            if (!t.form.tenantName?.trim()) {
                t.errors.tenantName = true
                t.errors.tenantNameErrorMessage = 'Tenant Name required'
            }

            // Tenant Floor
            if (!t.form.tenantFloor?.trim()) {
                t.errors.tenantFloor = true
                t.errors.tenantFloorErrorMessage = 'Floor required'
            }

            // Contact Name
            if (!t.form.tenantContactName?.trim()) {
                t.errors.tenantContactName = true
                t.errors.tenantContactNameErrorMessage = 'Contact Name required'
            }

            // Contact Email
            if (!t.form.tenantContactEmail?.trim()) {
                t.errors.tenantContactEmail = true
                t.errors.tenantContactEmailErrorMessage = 'Contact Email required'
            }

            // Contact Telephone
            if (!t.form.tenantContactTelephone?.trim()) {
                t.errors.tenantContactTelephone = true
                t.errors.tenantContactTelephoneErrorMessage = 'Contact Telephone required'
            }

            return !Object.values(t.errors).includes(true)
        }

    },

}
</script>

<style scoped>

</style>
