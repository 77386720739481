<!--
Observation

Renders the form (right side panel) for Observations which allows the user to add/edit/delete an observation.
This are split into tabs for: Report |  Response | Evidence.
-->
<template>
	<div>

		<!--Header-->
		<v-row class="d-flex grey lighten-2 pa-4" no-gutters>

			<!--Title | Status-->
			<div class="d-flex flex-column">

				<app-text v-if="!form.observationReportingShortDescription">
					{{ $t(`concerns.form.title.new`) }}
				</app-text>

				<app-text v-else>
					{{ form.observationReportingShortDescription }}
				</app-text>

				<!--Status-->
				<div class="d-flex align-center mt-2">

					<v-icon v-if="form.observationStatus === 'Pending'"
							class="icons8-inactive-state"
							color="error"
							size="24"/>

					<v-icon v-if="form.observationStatus === 'In Progress'"
							class="icons8-circle"
							color="orange"
							size="24"/>

					<v-icon v-if="form.observationStatus === 'Resolved'"
							class="icons8-checkmark-yes"
							color="success"
							size="24"/>

					<app-text category="text-small" class="grey--text ml-2">{{ form.observationStatus }}</app-text>

				</div>

				<!--Reported by-->
				<app-text category="text-small" class="grey--text mt-2">
					Reported by: <span class="font-weight-bold">{{ form.createdUserData.userName }}</span>
				</app-text>

			</div>

			<v-spacer/>

			<!--Action buttons-->
			<div class="d-flex align-center">

				<v-spacer/>

				<!--Edit-->
				<v-icon v-if="!['New'].includes(formMode)"
						@click.native="editItem"
						class="icons8-edit"
						:class="formReadOnly ? 'frc-icon' : 'frc-icon-edit-active'"
						:color="formReadOnly ? 'warning' : 'white'"
						size="32"/>

				<!--Delete-->
				<v-icon v-if="!['New'].includes(formMode) && ['SA'].includes(GET_currentUser.userLevel)"
						@click.native="deleteItem(form.id)"
						class="icons8-trash frc-icon"
						color="error"
						size="32"/>

			</div>

		</v-row>

		<!--Page Content-->
		<v-row class="mt-0" no-gutters>

			<!--Tabs (Report | Response | Evidence)-->
			<v-tabs class="border-bottom-grey" v-model="tabs" :height="buttonSizeDefault">

				<v-tabs-slider color="accent"/>

				<!--Report-->
				<v-tab href="#report">
					<app-text category="text-small">Report</app-text>
				</v-tab>

				<!--Response-->
				<v-tab v-if="form.id" href="#response">
					<app-text category="text-small">Response</app-text>
				</v-tab>

				<!--Evidence-->
				<v-tab v-if="formMode !== 'New'" href="#evidence">
					<app-text category="text-small">{{ $t(`concerns.form.tabs.photos.tabName`) }}
						({{ calculateArrayLength(files) }})
					</app-text>
				</v-tab>

			</v-tabs>

			<!--Tab Content (Report | Response | Evidence)-->
			<v-tabs-items v-model="tabs" class="appbackground">

				<!--Reporting Tab Content-->
				<v-tab-item value="report" class="mb-4">

					<app-text v-if="!formReadOnly && GET_currentUser.userLevel !== 'SA' && ['In Progress', 'Resolved'].includes(form.observationStatus)"
							  class="error--text mt-4 ml-4">
						{{ `Observations cannot be edited when they are ${form.observationStatus}` }}
					</app-text>

					<!--Form-->
					<v-form ref="form">
						<v-row class="pa-4 pt-0" no-gutters>

							<!--Types-->
							<v-col :cols="$vuetify.breakpoint.width >= 600 ? 6 : 12">
								<app-input inputType="select"
										   class="mt-4"
										   :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
										   :disabled="GET_currentUser.userLevel !== 'SA' && ['In Progress', 'Resolved'].includes(form.observationStatus)"
										   :error="errors.observationReportingType"
										   :error-messages="errors.observationReportingTypeErrorMessage"
										   :is-form-read-only="formReadOnly"
										   :items="observationTypesOptionsData"
										   label="Type"
										   v-model="form.observationReportingType"/>
							</v-col>

							<!--Short description-->
							<!--This is a WATCHED input and is allowed a max of 32 characters-->
							<v-col :cols="$vuetify.breakpoint.width >= 600 ? 6 : 12">
								<app-input inputType="textInput"
										   class="mt-4"
										   :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
										   :disabled="GET_currentUser.userLevel !== 'SA' && ['In Progress', 'Resolved'].includes(form.observationStatus)"
										   :error="errors.observationReportingShortDescription"
										   :error-messages="errors.observationReportingShortDescriptionErrorMessage"
										   :hint="`Remaining characters ${32 - form.observationReportingShortDescription.length}`"
										   :is-form-read-only="formReadOnly"
										   label="Short Description"
										   placeholder="Please provide a short description"
										   v-model="form.observationReportingShortDescription"/>
							</v-col>

							<!--Long description-->
							<v-col cols="12">
								<app-input inputType="textArea"
										   class="mt-4"
										   :disabled="GET_currentUser.userLevel !== 'SA' && ['In Progress', 'Resolved'].includes(form.observationStatus)"
										   :error="errors.observationReportingLongDescription"
										   :error-messages="errors.observationReportingLongDescriptionErrorMessage"
										   :hint="`What happened?\nWhat caused it?\nWho was involved?`"
										   :is-form-read-only="formReadOnly"
										   label="Full Description of the issue"
										   :placeholder="`What happened?\nWhat caused it?\nWho was involved?`"
										   rows="4"
										   v-model="form.observationReportingLongDescription"/>
							</v-col>

							<!--Location-->
							<v-col :cols="$vuetify.breakpoint.width >= 600 ? 6 : 12">
								<app-input inputType="textInput"
										   class="mt-4"
										   :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
										   :disabled="GET_currentUser.userLevel !== 'SA' && ['In Progress', 'Resolved'].includes(form.observationStatus)"
										   :error="errors.observationReportingLocation"
										   :error-messages="errors.observationReportingLocationErrorMessage"
										   :is-form-read-only="formReadOnly"
										   label="Where did the issue occur?"
										   v-model="form.observationReportingLocation"/>
							</v-col>

							<!--Floor-->
							<v-col :cols="$vuetify.breakpoint.width >= 600 ? 6 : 12">
								<app-input inputType="textInput"
										   class="mt-4"
										   :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
										   :disabled="GET_currentUser.userLevel !== 'SA' && ['In Progress', 'Resolved'].includes(form.observationStatus)"
										   :error="errors.observationReportingLocationFloor"
										   :error-messages="errors.observationReportingLocationFloorErrorMessage"
										   :is-form-read-only="formReadOnly"
										   label="What floor did the issue occur on?"
										   v-model="form.observationReportingLocationFloor"/>
							</v-col>

							<!--Date-->
							<v-col class="mt-4 pr-2" cols="6">
								<date-picker :date="form.observationReportingDate"
											 :disabled="GET_currentUser.userLevel !== 'SA' && ['In Progress', 'Resolved'].includes(form.observationStatus)"
											 :error="errors.observationReportingDate"
											 :error-messages="errors.observationReportingDateErrorMessage"
											 :isFormReadOnly="formReadOnly"
											 label="Date"
											 :minDate="[1, 'years', 'past']"
											 :maxDate="[0, 'years', 'future']"
											 v-on:emitDate="handleDatePicker"/>
							</v-col>

							<!--Time-->
							<v-col cols="6">
								<observation-time-picker :disabled="GET_currentUser.userLevel !== 'SA' && ['In Progress', 'Resolved'].includes(form.observationStatus)"
														 :error="errors.observationReportingTime"
														 :error-messages="errors.observationReportingTimeErrorMessage"
														 :isFormReadOnly="formReadOnly"
														 :time="form.observationReportingTime"
														 v-on:emitObservationTime="handleObservationTime"/>
							</v-col>

						</v-row>
					</v-form>

					<!--Action buttons-->
					<v-row class="mr-4" no-gutters>
						<v-col class="d-flex">

							<v-spacer/>

							<!--Save-->
							<app-btn v-if="formMode === 'Edit' || formMode === 'New'"
									 @click.native="validateReportingForm"
									 color="success"
									 icon="icons8-save"
									 label="Save"/>

						</v-col>
					</v-row>

				</v-tab-item>

				<!--Response Tab Content-->
				<v-tab-item value="response" class="pa-0">

					<app-text v-if="!formReadOnly && GET_currentUser.userLevel !== 'SA'"
							  class="error--text mt-4 ml-4">
						Only Admin can response to Observations
					</app-text>

					<!--Form-->
					<v-form class="pt-4" ref="form">
						<v-row class="ma-0 px-4" no-gutters>

							<!--Manager's Name-->
							<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="8">
								<app-input input-type="textInput"
										   :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
										   :disabled="!['SA','SM'].includes(GET_currentUser.userLevel) || formReadOnly"
										   :error="errors.observationManagersName"
										   :error-messages="errors.observationManagersNameErrorMessage"
										   label="Manager's Name"
										   :is-form-read-only="formReadOnly"
										   v-model="form.observationManagersName"/>
							</v-col>

							<!--Reference number-->
							<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="4">
								<app-input input-type="textInput"
										   :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
										   :disabled="true"
										   label="Reference"
										   v-model="form.observationReferenceNumber"/>
							</v-col>

							<!--Description-->
							<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
								<app-input input-type="textArea"
										   class="mt-4"
										   :disabled="!['SA','SM'].includes(GET_currentUser.userLevel) || formReadOnly"
										   :error="errors.observationRespondingDescription"
										   :error-messages="errors.observationRespondingDescriptionErrorMessage"
										   :is-form-read-only="formReadOnly"
										   label="Description"
										   rows="4"
										   v-model="form.observationRespondingDescription"/>
							</v-col>

							<!--Priority-->
							<v-col cols="12" xs="12" sm="6" md="6" lg="6">
								<app-input input-type="select"
										   :class="$vuetify.breakpoint.width >= 600 ? 'mt-4 pr-2' : 'mt-4'"
										   :disabled="!['SA','SM'].includes(GET_currentUser.userLevel) || formReadOnly"
										   :error="errors.observationPriority"
										   :error-messages="errors.observationPriorityErrorMessage"
										   :is-form-read-only="formReadOnly"
										   :items="observationPriorityOptionsData"
										   label="Priority"
										   v-model="form.observationPriority"/>
							</v-col>

							<!--Status-->
							<v-col cols="12" xs="12" sm="6" md="6" lg="6">
								<app-input input-type="select"
										   :class="$vuetify.breakpoint.width >= 600 ? 'mt-4 pl-2' : 'mt-4'"
										   :disabled="!['SA','SM'].includes(GET_currentUser.userLevel) || formReadOnly"
										   :is-form-read-only="formReadOnly"
										   :items="observationStatusOptionsData"
										   label="Status"
										   v-model="form.observationStatus"/>
							</v-col>

						</v-row>
					</v-form>

					<!--Action buttons-->
					<v-row class="ma-4" no-gutters>
						<v-col class="d-flex">

							<v-spacer/>

							<!--Save-->
							<app-btn v-if="formMode === 'Edit' || formMode === 'New'"
									 @click.native="validateRespondingForm"
									 class="d-flex align-center"
									 color="success"
									 icon="icons8-save"
									 label="Save"/>

						</v-col>
					</v-row>

					<!--Meta data-->
					<v-row v-if="form.id" class="ma-4" no-gutters>
						<v-col>

							<v-divider class="mb-4"/>

							<app-text category="text-small" class="grey--text">
								Modified by: <span class="font-weight-bold">{{ form.modifiedUserData.userName }}</span>
								<br>
								Date: <span class="font-weight-bold">
								{{ $moment(form.modifiedDateTime, 'x').format('ddd DD MMM YYYY @ HH:MM') }}</span>
							</app-text>

						</v-col>
					</v-row>

				</v-tab-item>

				<!--Evidence-->
				<v-tab-item value="evidence" class="pa-0">

					<v-row class="pa-4" no-gutters>

						<!--Photo Upload | Deleted-->
						<v-col class="d-flex align-center" cols="12">

							<!--Photo upload-->
							<photoupload :allowedtypes="allowedTypes" :docLink="docLink" :folder="folder" multiple/>

							<!--Deleted-->
							<div v-if="['DA'].includes(GET_currentUser.userLevel)" class="d-flex align-center">
								<v-icon class="icons8-trash ml-4 text-h4" color="primary"/>
								<v-switch @change="getFiles(GET_openItem)" v-model="showDeletedFiles"
										  class="ml-4 purple--text" color="accent" inset></v-switch>
							</div>

						</v-col>

					</v-row>

					<!--Photo grid-->
					<v-row no-gutters>

						<transition-group name="fade" class="row ma-0">

							<v-col v-for="(file, index) in files" :key="file.id"
								   class="d-flex child-flex pa-4"
								   cols="6" xs="6" sm="6" md="3">

								<v-img v-if="file.fileURL" @click="openPhoto(index)"
									   :src="file.fileURL" aspect-ratio="1" class="d-flex align-end"
									   style="border-radius: 8px !important;">

									<!--Photo action buttons-->
									<div class="pa-2">

										<!--Delete button-->
										<v-menu offset-x flat v-if="!file.hasOwnProperty('delete')">
											<template v-slot:activator="{ on, attrs }">
												<v-btn small v-bind="attrs" v-on="on">
													<v-icon color="error" class="icons8-trash"/>
												</v-btn>
											</template>
											<v-btn @click="deletePhoto(file.id)" small class="error ml-2">CONFIRM
											</v-btn>
										</v-menu>

										<!--Process button-->
										<v-menu offset-x flat v-if="file.hasOwnProperty('delete')">
											<template v-slot:activator="{ on, attrs }">
												<v-btn small v-bind="attrs" v-on="on">
													<v-icon color="primary" class="icons8-process"/>
												</v-btn>
											</template>
											<v-btn @click="undeletePhoto(file.id)" small class="ml-2">CONFIRM
											</v-btn>
										</v-menu>

									</div>

									<template v-slot:placeholder>
										<v-row class="fill-height" align="center" justify="center" no-gutters>
											<v-progress-circular indeterminate
																 color="red lighten-5"></v-progress-circular>
										</v-row>
									</template>

								</v-img>

							</v-col>

						</transition-group>

					</v-row>

					<!--Preview image-->
					<v-img v-if="tempImage" :src="tempImage"
						   class="d-flex justify-start align-start ml-4"
						   style="border-radius: 4px !important;"
						   height="256"
						   width="256"
						   covers/>

				</v-tab-item>

			</v-tabs-items>

		</v-row>

	</div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import formFieldContainer from "./commonComponents/formFieldContainer"
import ObservationTimePicker from "../views/observations/observationTimePicker/observationTimePicker";
import DatePicker from "../modules/datePicker/datePicker_component"
import login from "@/views/auth/login";

export default {

	name: "Observations",

	components: {
		ObservationTimePicker,
		formFieldContainer,
		DatePicker
	},

	data: () => ({
		collection: 'observations',
		collectionTitle: 'Observations',
		collectionItem: 'observation',
		collectionItemTitle: 'Observation',
		concernStatus: [],
		allowedTypes: "image/*",
		errors: {
			observationManagersName: false,
			observationManagersNameErrorMessage: '',
			observationPriority: false,
			observationPriorityErrorMessage: '',
			observationReportingLongDescription: false,
			observationReportingLongDescriptionErrorMessage: '',
			observationReportingShortDescription: false,
			observationReportingShortDescriptionErrorMessage: '',
			observationReportingDate: false,
			observationReportingDateErrorMessage: '',
			observationReportingTime: false,
			observationReportingTimeErrorMessage: '',
			observationRespondingDescription: false,
			observationRespondingDescriptionErrorMessage: '',
			observationReportingLocation: false,
			observationReportingLocationErrorMessage: '',
			observationReportingLocationFloor: false,
			observationReportingLocationFloorErrorMessage: '',
			observationReportingType: false,
			observationReportingTypeErrorMessage: '',
		},
		form: {
			id: '',
			observationManagersName: '',
			observationPriority: '',
			observationReferenceNumber: '',
			observationReportingLongDescription: '',
			observationReportingShortDescription: '',
			observationReportingDate: '',
			observationReportingTime: '',
			observationRespondingDescription: '',
			observationReportingLocation: '',
			observationReportingLocationFloor: '',
			observationStatus: 'Pending',
			observationReportingType: '',
			createdUserData: {},
			createdDateTime: "",
			modifiedUserData: {},
			modifiedDateTime: "",
		},
		formBackground: 'grey lighten-3',
		formMode: 'New', // Show Form in New Mode / Blank
		formReadOnly: false, // Make Form Editable
		files: [],
		folder: '/observations',
		hasImages: false,
		rules: {},
		showDeletedFiles: false, // Show Deleted Comments
		tabs: 'observation',
		tempImage: '',
		types: "image/*",
	}),

	computed: {
		...mapGetters({
			GET_confirmBoxResult: 'confirmBox_store/GET_confirmBoxResult',
			GET_help: 'GET_help',
			GET_openItem: 'GET_openObservation',
			GET_openObservation: "GET_openObservation",
			GET_panelLayout: "GET_panelLayout",
			GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult'
		}),

		// DocLink for document upload
		docLink() {
			const t = this

			return ({collection: t.collection, documentId: t.form.id})
		},

	},

	methods: {
		...mapActions({ACT_openItem: "ACT_openObservation", ACT_openObservation: "ACT_openObservation"}),

		updateBreadcrumbs() {
			const t = this

			const breadcrumbs = []
			breadcrumbs.push({name: `${t.collectionTitle}`, path: `/${t.collection}`}) // Set the Main Path
			if (t.GET_openItem.process === 'New') {
				breadcrumbs.push({name: `New ${t.collectionItemTitle}`, path: `/${t.collection}`}) // Set a Secondary Path
			} else {
				breadcrumbs.push({
					name: `${this.GET_openItem.data[t.collectionItem + 'Name']}`,
					path: `/${t.collection}/${this.GET_openItem.data.id}`
				}) // Set a Secondary Path
			}
			this.MIX_breadcrumbs(breadcrumbs)
		},

		async openItem() {
			const t = this

			// Get open item data
			const openItem = await t.GET_openItem
			switch (openItem.process) {
				case 'New':
					t.formMode = 'New'
					t.formReadOnly = false
					t.formBackground = 'white'
					break
				case 'View':
					const item1 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
					t.form = item1.data
					t.formMode = 'View'
					t.formReadOnly = true
					t.formBackground = 'grey lighten-3'
					break
				case 'Edit':
					const item2 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
					t.form = item2.data
					t.formMode = 'Edit'
					t.formReadOnly = false
					t.formBackground = 'white'
					break
			}
		},

		editItem() {
			const t = this

			if (t.formReadOnly) {
				t.formMode = 'Edit'
				t.formReadOnly = false
			} else {
				t.formMode = 'View'
				t.formReadOnly = true
				t.refreshItemView()
			}
		},

		clearErrorsAndMessages() {
			const t = this

			t.errors.observationReportingDate = false
			t.errors.observationReportingDateErrorMessage = ''
			t.errors.observationReportingLongDescription = false
			t.errors.observationReportingLongDescriptionErrorMessage = ''
			t.errors.observationReportingShortDescription = false
			t.errors.observationReportingShortDescriptionErrorMessage = ''
			t.errors.observationReportingTime = false
			t.errors.observationReportingTimeErrorMessage = ''
			t.errors.observationReportingLocation = false
			t.errors.observationReportingLocationErrorMessage = ''
			t.errors.observationReportingLocationFloor = false
			t.errors.observationReporintgLocationFloorErrorMessage = ''
			t.errors.observationReportingType = false
			t.errors.observationReportingTypeErrorMessage = ''

		},

		validateReportingForm() {
			const t = this

			t.clearErrorsAndMessages()

			// Type
			if (!t.form.observationReportingType) {
				t.errors.observationReportingType = true
				t.errors.observationReportingTypeErrorMessage = 'Type is required'
			}

			// Short description
			if (!t.form.observationReportingShortDescription.trim()) {
				t.errors.observationReportingShortDescription = true
				t.errors.observationReportingShortDescriptionErrorMessage = 'Short description is required'
			}

			// Long description
			if (!t.form.observationReportingLongDescription.trim()) {
				t.errors.observationReportingLongDescription = true
				t.errors.observationReportingLongDescriptionErrorMessage = 'Long description required'
			}

			// Site location
			if (!t.form.observationReportingLocation.trim()) {
				t.errors.observationReportingLocation = true
				t.errors.observationReportingLocationErrorMessage = 'Site location is required'
			}

			// Location Floor
			if (!t.form.observationReportingLocationFloor.trim()) {
				t.errors.observationReportingLocationFloor = true
				t.errors.observationReportingLocationFloorErrorMessage = 'Location floor is required'
			}

			// Date
			if (!t.form.observationReportingDate.trim()) {
				t.errors.observationReportingDate = true
				t.errors.observationReportingDateErrorMessage = 'Date is required'
			}

			// Time
			if (!t.form.observationReportingTime.trim()) {
				t.errors.observationReportingTime = true
				t.errors.observationReportingTimeErrorMessage = 'Time is required'
			}

			// If there are no errors, call to save
			if (!Object.values(t.errors).includes(true)) {
				this.saveDocument()
			}

		},

		validateRespondingForm() {
			const t = this

			t.errors.observationManagersName = false
			t.errors.observationManagersNameErrorMessage = ''
			t.errors.observationPriority = false
			t.errors.observationPriorityErrorMessage = ''
			t.errors.observationRespondingDescription = false
			t.errors.observationRespondingDescriptionErrorMessage = ''

			// Manager's name
			if (!t.form.observationManagersName.trim()) {
				t.errors.observationManagersName = true
				t.errors.observationManagersNameErrorMessage = 'Manager\'s Name is required'
			}

			// Response long description
			if (!t.form.observationRespondingDescription.trim()) {
				t.errors.observationRespondingDescription = true
				t.errors.observationRespondingDescriptionErrorMessage = 'Response Description required'
			}

			// Priority
			if (!t.form.observationPriority) {
				t.errors.observationPriority = true
				t.errors.observationPriorityErrorMessage = 'Priority required'
			}

			// If there are no errors, call to save
			if (!Object.values(t.errors).includes(true)) {
				this.saveDocument()
			}

		},

		async saveDocument() {
			const t = this
			let documentResult = {}

			// If this a new observation, save a new document
			if (t.formMode === 'New') {

				t.form.id = t.MIX_generateId()
				t.form.observationReferenceNumber = t.$moment().format('x')

				// Save the new observation
				documentResult = await t.MIX_createDocument('observations', t.form)

				if (documentResult.code === 1) t.sendObservationEmail()
			}

			// If this an existing observation, update the existing document
			if (t.formMode === 'Edit') documentResult = await t.MIX_updateDocument('observations', t.form)

			// If the document has successfully saved
			if (documentResult.code === 1) {
				t.formMode = 'View'
				t.formReadOnly = true
				t.form.id = documentResult.data.id

				await t.refreshItemView();
			}

			// Call for a confirmation alert
			t.renderConfirmationAlert(documentResult)
		},

		sendObservationEmail() {
			const t = this

			const emailDetails = {
				testEmail: [],
				observationReportingType: t.form.observationReportingType,
				observationReportingShortDescription: t.form.observationReportingShortDescription,
				observationReportingLongDescription: t.form.observationReportingLongDescription,
				observationReportingLocation: t.form.observationReportingLocation,
				observationReportingLocationFloor: t.form.observationReportingLocationFloor,
				observationReportingDate: t.$moment(Number(t.form.observationReportingDate)).format('dddd Do MMMM \'YY'),
				observationReportingTime: t.form.observationReportingTime,
			}

			// Use the logged-in User's email address for testing
			if (process.env.NODE_ENV === 'development') emailDetails.testEmail.push(t.GET_currentUser.userEmail)

			const email = t.$firebase.functions.httpsCallable('sendObservationEmail')
			email(emailDetails)

				.then(result => console.log('Successfully sent Observation email: ', result))
				.catch(error => console.log('Error sending Observation email: ', error))

		},

		async refreshItemView() {
			const t = this

			const itemResult = await t.MIX_readDocumentById(t.collection, t.form.id)
			const payload = {}
			payload.process = 'View'
			payload.popup = false
			payload.data = itemResult.data
			t.ACT_openItem(payload)
			await t.openItem()
		},

		deleteItem: function (id) {
			const t = this
			const width = t.$vuetify.breakpoint.width <= 425 ? '' : '400'

			t.MIX_confirmBox(
				{
					show: true,
					collection: t.collection,
					id: id,
					title: `Delete ${t.collectionItemTitle}`,
					description: `Are you sure you want to delete this ${t.collectionItemTitle}: <br /><div class="mt-4"><strong>${t.form[t.collectionItem + 'ReportingShortDescription']}</strong>?</div>`,
					width,
					trueButtonText: 'Yes',
					trueButtonValue: 'YES',
					falseButtonText: 'No',
					falseButtonValue: 'NO',
				}
			)
		},

		async undeleteItem(id) {
			const t = this

			const undeleteResult = await t.MIX_undeleteDocument(t.collection, id)
			if (undeleteResult.code === 1) {
				const unDeleteLinkedResult = await t.MIX_undeleteLinked(['files', 'comments'], t.form.id)
				if (unDeleteLinkedResult.code === 1) {
					const comment = {}
					comment.comment = `${t.collectionItemTitle} Restored`
					comment.docLink = {}
					comment.docLink.collection = 'observations'
					comment.docLink.documentId = t.form.id
					comment.private = false
					await t.MIX_createDocument('comments', comment)
					t.MIX_alert(1, `${t.collectionItemTitle} Restored`, unDeleteLinkedResult.data, null)
					await t.refreshItemView()
				} else {
					t.MIX_alert(-1, `Error Restoring File Record`, null, unDeleteLinkedResult.error);
				}
			} else {
				t.MIX_alert(-1, `Error Restoring ${t.collectionItemTitle}`, null, undeleteResult.error);
				console.log(`undeleteResult.error = ${JSON.stringify(undeleteResult.error, null, 2)}`)
			}
			await t.refreshItemView();
		},

		async destroyItem() {
			const t = this

			t.MIX_confirmBox(
				{
					show: true,
					collection: 'observations/destroy',
					id: t.form.id,
					title: `Destroy observation`,
					description: `Are you sure you want to destroy the observation: <br /><strong>${t.form.concernName}</strong>?`,
					width: '300',
					height: '300',
					trueButtonText: 'Yes',
					trueButtonValue: 'YES',
					falseButtonText: 'No',
					falseButtonValue: 'NO',
				}
			)
		},

		async getFiles(openItem) {
			const t = this

			if (JSON.stringify(openItem.data) !== '{}') {
				t.$firebase.db.collection('files').where("docLink", "==", openItem.data.id)
					.onSnapshot(function (querySnapshot) {
						t.files = []
						querySnapshot.forEach(function (doc) {
							var document = doc.data()
							document.id = doc.id
							if (!document.hasOwnProperty('delete')) {
								t.files.push(document)
							} else if (t.showDeletedFiles) {
								t.files.push(document)
							} else {
								// Do not Show Notice
							}
						})

						// Set hasImages to prevent a double image
						if (t.files.length) {
							t.hasImages = true
						}
					})
			}
		},

		openPhoto(index) {
			const t = this

			const payload = {}
			payload.title = t.form.concernName
			payload.index = index
			payload.collection = 'concerns'
			payload.id = t.form.id
			payload.photos = t.files
			let width, height
			switch (true) {
				case (t.$vuetify.breakpoint.name === 'xs'):
					width = '300'
					height = "300"
					break
				case (t.$vuetify.breakpoint.name === 'sm'):
					width = '300'
					height = "300"
					break
				default:
					width = '600'
					height = "580"
					break
			}
			payload.width = width
			payload.height = height
			t.MIX_photoViewer(payload)
			t.MIX_puDialog(true, 'photoviewer', width, (parseFloat(height) + 88).toString())

		},

		async deletePhoto(fileId) {
			const t = this

			const deleteFileResult = await t.MIX_deleteDocument('files', fileId)
			if (deleteFileResult.code === 1) {
				t.MIX_alert(1, 'Photo Deleted', deleteFileResult.data, null) // Send Success Alert to Concern
			} else {
				t.MIX_alert(-1, 'Error Deleting Photo', null, deleteFileResult.error) // Send Error Alert to Concern
			}
		},

		async undeletePhoto(fileId) {
			const t = this
			const undeleteFileResult = await t.MIX_undeleteDocument('files', fileId)
			if (undeleteFileResult.code === 1) {
				t.MIX_alert(1, 'Photo Restored', undeleteFileResult.data, null) // Send Success Alert to Concern
			} else {
				t.MIX_alert(-1, 'Error Restoring Photo', null, undeleteFileResult.error) // Send Error Alert to Concern
			}
		},

		calculateArrayLength(array) {
			return array.length
		},

		/**
		 * Render Confirmation Alert
		 *
		 * Take the response of creating a document and render:
		 *  -> 'Success' alert if all is ok
		 *  -> 'Error' alert if something when wrong
		 */
		renderConfirmationAlert(document) {
			const t = this

			if (document.code === 1) {
				t.MIX_alert(1, `${t.collectionItemTitle} saved`, null, null)
			} else {
				t.MIX_alert(-1, `Error saving ${t.collectionItemTitle}`, null, null)
			}
		},

		/**
		 * Handle Observation Time
		 *
		 * Takes the time emitted from observationTimePicker and assigns it to the form data.
		 *
		 * @param time
		 */
		handleObservationTime(time) {
			const t = this

			t.form.observationReportingTime = time
		},

		/**
		 * Handle Date Picker
		 *
		 * Takes the date emitted from Date Picker and assigns it to the form data.
		 *
		 * @param date
		 */
		handleDatePicker(date) {
			const t = this

			// Convert to Unix timestamp
			date = t.$moment(date).format('x')
			t.form.observationReportingDate = date
		}
	},

	watch: {

		GET_confirmBoxResult: {
			handler: async function () {
				const t = this

				if (t.GET_confirmBoxResult.data.collection === 'observations') {
					if (t.GET_confirmBoxResult.data.result === 'YES') {
						const deleteDocumentResult = await t.MIX_deleteDocument('observations', t.GET_confirmBoxResult.data.id)
						const deleteLinkedResult = await t.MIX_deleteLinked(['files', 'comments'], t.GET_confirmBoxResult.data.id)
						if ((deleteDocumentResult.code === 1) && (deleteLinkedResult.code === 1)) {
							const comment = {}
							comment.comment = `${t.collectionItemTitle} Deleted`
							comment.docLink = {}
							comment.docLink.collection = 'observations'
							comment.docLink.documentId = t.GET_confirmBoxResult.data.id
							comment.private = false
							await t.MIX_createDocument('comments', comment)
							t.MIX_alert(1, `Incident and Photos Deleted`, deleteDocumentResult.data, null)
							t.refreshItemView()
						} else {
							t.MIX_alert(-1, `Error Deleting Incident / Photos`, null, null)
						}
					}
				} else if (t.GET_confirmBoxResult.data.collection === 'observations/destroy') {
					if (t.GET_confirmBoxResult.data.result === 'YES') {
						const destroyDocumentResult = await t.MIX_destroyDocument('observations', t.GET_confirmBoxResult.data.id)
						const destroyLinkedDocs = await t.MIX_destroyLinked(['files', 'comments'], t.GET_confirmBoxResult.data.id)
						if ((destroyDocumentResult.code === 1) && (destroyLinkedDocs.code === 1)) {
							t.MIX_alert(1, `Observation and Photos Destroyed`, destroyDocumentResult.data, null)
							t.MIX_setPanelLayout(12, 0, false, '', '')
						} else {
							t.MIX_alert(-1, `Error Destroying Observation & Photos`, null, null)
						}
					}
				} else if (t.GET_confirmBoxResult.data.collection === 'comments') {
					if (t.GET_confirmBoxResult.data.result === 'YES') {
						const deleteResult = await t.MIX_deleteDocument(t.GET_confirmBoxResult.data.collection, t.GET_confirmBoxResult.data.id)
						if (deleteResult.code === 1) {
							t.MIX_alert(1, 'Comment Deleted', deleteResult.data, null)
						} else {
							t.MIX_alert(-1, 'Error Deleting Comment', null, deleteResult.error)
						}
					}
				} else {
					// Do nothing
				}
			}, deep: true
		},

		GET_photoUploadResult: {
			handler: async function () {
				const t = this

				const photoUploadResult = t.GET_photoUploadResult
				if (!t.hasImages) {
					t.tempImage = photoUploadResult.fileURL
				}

				if (JSON.stringify(photoUploadResult) !== '{}') {

					const index = t.files.findIndex(file => file === photoUploadResult.id)

					if (index === -1) {

						t.files.push(photoUploadResult.id)

						const updatePhotosResult = await t.MIX_updateDocumentFieldsById('observations', t.form.id, {files: t.form.files})

						if (updatePhotosResult.code === 1) {
							t.MIX_alert(1, 'Photo Added', updatePhotosResult.data, null)
							await t.getFiles()
						} else {
							t.MIX_alert(-1, 'Error Adding Photo', null, updatePhotosResult.error)
						}
					}
				}
			}, deep: true
		},

		/**
		 * Limit the Number of characters allowed
		 */
		'form.observationReportingShortDescription'() {
			const t = this
			const maxAllowed = 32
			if (t.form.observationReportingShortDescription.length > maxAllowed) {
				t.errors.observationReportingShortDescription = true
				t.errors.observationReportingShortDescriptionErrorMessage = `Max of ${maxAllowed} characters allowed`
			} else {
				t.errors.observationReportingShortDescription = false
				t.errors.observationReportingShortDescriptionErrorMessage = ''
			}
		}
	},

	async mounted() {
		const t = this

		// Empty Photo Upload Result on Load
		t.MIX_photoUploadResult({})

		// * ITEM RELATED MOUNTED
		const openItem = await t.GET_openItem // Get Open Concern Data
		t.formMode = openItem.process // Show Form in View Mode
		if (openItem.process === 'New') {
			t.formReadOnly = false
			t.formBackground = 'white'
		} else {
			t.form = openItem.data // Update the Form with the Open Concern details
			t.formReadOnly = true
			t.formBackground = 'grey lighten-3'
			await t.getFiles(openItem)
		}
		if (openItem.popup === false) {
			t.updateBreadcrumbs()
		} // Update Breadcrumbs in Title

		if (openItem.popup === false) {
			t.updateBreadcrumbs()
		} // Update Breadcrumbs in Title
	}

}
</script>

<style scoped>
.disabled >>> .v-input__slot::before {
	border-style: none !important;
}

.v-tabs-items {
	width: 100% !important;
	height: 100% !important;
}

.v-window-item {
	height: calc(100vh - 170px);
	overflow-y: auto;
}

.v-menu__content {
	box-shadow: none !important;
}

.fade-in-enter-active {
	transition: all 0.5s ease;
}

.fade-in-leave-active {
	transition: all 0.5s ease;
}

.fade-in-enter, .fade-in-leave-to {
	position: absolute; /* add for smooth transition between elements */
	opacity: 0;
}

/* v-menu__content theme--light menuable__content__active */
</style>
