<template>
    <v-row no-gutters>

        <!--Page Loader-->
        <page-loading :is-page-loading="isPageLoading"/>

        <!--Page Content-->
        <v-row v-if="!computedIsPageLoading" no-gutters style="height: 100%">

            <!--Left Panel-->
            <v-col v-if="computedSplitScreenColumns.left"
                   :cols="computedSplitScreenColumns.left"
                   class="pa-4">

                <!--Title-->
                <page-title icon="icons8-business-building"
                            page-title="Tenants"
                            description="View, edit and delete Tenants."
                            :divider="true"/>

                <!--Searches-->
                <v-row no-gutters>

                    <!--Tenant Name-->
                    <v-col :class="$vuetify.breakpoint.width <= 600 ? '' : 'pr-2'"
                           :cols="$vuetify.breakpoint.width <= 600 ? 12 : 4">
                        <app-input append-icon="icons8-search"
                                   :clearable="true"
                                   input-type="textInput"
                                   label="Tenant Name..."
                                   v-model.trim="searchByTenantName"/>
                    </v-col>

                    <!--Tenant Floor-->
                    <v-col :class="$vuetify.breakpoint.width <= 600 ? 'mt-4' : 'px-2'"
                           :cols="$vuetify.breakpoint.width <= 600 ? 12 : 4">
                        <app-input append-icon="icons8-search"
                                   :clearable="true"
                                   input-type="textInput"
                                   label="Floor..."
                                   v-model.trim="searchByTenantFloor"/>
                    </v-col>

                    <!--Contact Name-->
                    <v-col :class="$vuetify.breakpoint.width <= 600 ? 'mt-4' : 'pl-2'"
                           :cols="$vuetify.breakpoint.width <= 600 ? 12 : 4">
                        <app-input append-icon="icons8-search"
                                   :clearable="true"
                                   input-type="textInput"
                                   label="Contact Name..."
                                   v-model.trim="searchByTenantContactName"/>
                    </v-col>

                </v-row>

                <!--Action Buttons-->
                <div class="d-flex justify-end mt-4">

                    <!--New-->
                    <app-btn :block="$vuetify.breakpoint.width < 600"
                             icon="icons8-business-building"
                             label="New"
                             @click.native="openRightPanel()"/>

                </div>

                <!--Table-->
                <v-data-table v-if="$vuetify.breakpoint.width >= 600"
                              :headers="computedTableHeaders"
                              :items="computedTableData"
                              class="rounded-lg my-4">

                    <!--Id-->
                    <template v-slot:item.id="{item}">
                        <app-text category="text-small">{{ item.id }}</app-text>
                    </template>

                    <!--Tenant Name-->
                    <template v-slot:item.tenantName="{item}">
                        <app-text category="text-small">{{ item.tenantName }}</app-text>
                    </template>

                    <!--Tenant Floor-->
                    <template v-slot:item.tenantFloor="{item}">
                        <app-text category="text-small">{{ item.tenantFloor }}</app-text>
                    </template>

                    <!--Tenant Contact Name-->
                    <template v-slot:item.tenantContactName="{item}">
                        <app-text category="text-small">{{ item.tenantContactName }}</app-text>
                    </template>

                    <!--Tenant Contact Email-->
                    <template v-slot:item.tenantContactNameEmail="{item}">
                        <app-text category="text-small">{{ item.tenantContactEmail }}</app-text>
                    </template>

                    <!--Actions-->
                    <template v-slot:item.actions="{item}">
                        <v-btn class="primary--text white"
                               depressed @click="openRightPanel(item)">
                            <v-icon class="icons8-forward pa-0" color="primary"/>
                        </v-btn>
                    </template>

                </v-data-table>

                <!--Mobile Cards-->
                <div v-if="$vuetify.breakpoint.width < 600">

                    <app-text v-if="!tenantsCollectionData.length" class="text-center mt-4">
                        There are no Tenants to show
                    </app-text>

                    <v-card v-for="item in computedTableData" :key="item.id"
                            @click="openRightPanel(item)"
                            class="rounded-lg mt-4 pa-4" flat>

                        <app-text category="text-default-bold">{{ item.tenantName }}</app-text>

                        <div class="d-flex align-center mt-2">
                            <app-text category="text-small" class="grey--text mr-2">Floor:</app-text>
                            <app-text category="text-small">{{ item.tenantFloor }}</app-text>
                        </div>

                        <div class="d-flex align-center mt-2">
                            <app-text category="text-small" class="grey--text mr-2">Contact:</app-text>
                            <app-text category="text-small">{{ item.tenantContactName }}</app-text>
                        </div>

                    </v-card>

                </div>

            </v-col>

            <!--Right panel-->
            <v-col v-if="isRightPanelOpen"
                   :class="computedSplitScreenColumns.left && 'borderLeft'"
                   :cols="computedSplitScreenColumns.right">

                <!--Action Bar-->
                <div class="d-flex align-center justify-space-between appbackground pa-4">

                    <!--Title-->
                    <app-text v-if="$vuetify.breakpoint.width >= 600" category="text-medium">
                        {{ selectedItem && selectedItem.tenantName ? selectedItem.tenantName : 'New' }}
                    </app-text>

                    <v-spacer/>

                    <div>

                        <!--Edit Icon-->
                        <edit-icon :isActive="!isFormReadOnly" @click.native="editForm"/>

                        <!--Delete Icon-->
                        <delete-icon-dialog :disabled="!isFormReadOnly"
                                            :item-to-delete="selectedItem && selectedItem.tenantName"
                                            v-on:emitDeleteFromDialog="emittedDeleteFromDialog"/>

                        <!--Close Icon-->
                        <close-icon @click.native="closeRightPanel"/>

                    </div>

                </div>

                <!--Tabs (Overview)-->
                <v-tabs v-model="tabs" show-arrows>

                    <v-tabs-slider color="accent"/>

                    <v-tab href="#overview">
                        <app-text category="text-small">Overview</app-text>
                    </v-tab>

                </v-tabs>

                <!--Tab Content-->
                <v-tabs-items v-model="tabs">

                    <!--Overview Tab Content-->
                    <v-tab-item class="appbackground pa-4" value="overview">

                        <tenants-form :doesFormStartReadOnly="isFormReadOnly"
                                      :formData="selectedItem"
                                      v-on:emitCloseRightPanel="emittedCloseRightPanel"/>

                    </v-tab-item>

                </v-tabs-items>

            </v-col>

        </v-row>

    </v-row>
</template>

<script>
import TenantsForm from "@/views/tenants/tenantsForm/tenantsForm";

export default {

    name: "tenants",

    components: {TenantsForm},

    data: () => ({
        searchByTenantName: '',
        searchByTenantContactName: '',
        searchByTenantFloor: '',
        isRightPanelOpen: false,
        isFormReadOnly: true,
        selectedItem: {},
        tableHeaders: [
            {
                text: 'Id',
                value: 'id',
                sortable: false,
                align: 'left',
                hiddenByDefault: true,
                hideWithWidth: true
            },
            {
                text: 'Tenant',
                value: 'tenantName',
                sortable: false,
                align: 'left',
                hiddenByDefault: false,
                hideWithWidth: false,
            },
            {
                text: 'Floor',
                value: 'tenantFloor',
                sortable: false,
                align: 'left',
                hiddenByDefault: false,
                hideWithWidth: false,
            },
            {
                text: 'Contact Name',
                value: 'tenantContactName',
                sortable: false,
                align: 'left',
                hiddenByDefault: false,
                hideWithWidth: false,
            },
            {
                text: 'Contact Email',
                value: 'tenantContactEmail',
                sortable: false,
                align: 'left',
                hiddenByDefault: false,
                hideWithWidth: false,
            },
            {
                text: '',
                value: 'actions',
                sortable: false,
                align: 'right',
                hiddenByDefault: false,
                hideWithWidth: false,
                width: '96px',
            },
        ],
        tabs: '',

        // Collection Data
        tenantsCollectionData: [],
        isTenantsCollectionDataLoaded: false,
        isPageLoading: true,
    }),

    computed: {

        /**
         * Computed Is Page Loading
         *
         * Return a boolean for the page loading spinner to denote if all data has been loaded.
         *
         * @returns {boolean} if data has been loaded or not
         */
        computedIsPageLoading() {
            const t = this

            t.isPageLoading = !(
                t.isTenantsCollectionDataLoaded
            )

            return t.isPageLoading
        },

        /**
         * Computed Split Screen Columns
         *
         * Calculate the width of the left snd right panels, based on the currently selected functionality.
         * The left panel has the table, the right panel has the forms.
         *
         * @returns {{left: number, right: number}}
         */
        computedSplitScreenColumns() {
            const t = this
            let left = 12
            let right = 0

            if (t.$vuetify.breakpoint.width >= 1200) {
                if (t.isRightPanelOpen) {
                    left = 6
                    right = 6
                }
            } else {
                if (t.isRightPanelOpen) {
                    left = 0
                    right = 12
                }
            }

            return {left, right}
        },

        /**
         * Computed Table Headers
         *
         * Using the tableHeaders data, hide the fields that aren't required.
         *
         * @returns {Array} an array of table header objects
         */
        computedTableHeaders() {
            const t = this
            let tableHeaders = t.tableHeaders

            tableHeaders = tableHeaders.filter(header => !header.hiddenByDefault)

            if (t.computedSplitScreenColumns.left < 12) {
                tableHeaders = tableHeaders.filter(header => !header.hideWithWidth)
            }

            return tableHeaders
        },

        /**
         * Computed Table Data
         *
         * Return a sorted array of JSON objects.
         *
         * @returns {Array} Array of processed JSON data
         */
        computedTableData() {
            const t = this
            let tableData = t.tenantsCollectionData

            // Search by Tenant Name
            if (t.searchByTenantName) {
                tableData = tableData.filter(data =>
                    data.tenantName.toUpperCase().match(t.searchByTenantName.toLocaleUpperCase()))
            }

            // Search by Tenant Floor
            if (t.searchByTenantFloor) {
                tableData = tableData.filter(data =>
                    data.tenantFloor.toUpperCase().match(t.searchByTenantFloor.toLocaleUpperCase()))
            }

            // Search by Tenant Contact Name
            if (t.searchByTenantContactName) {
                tableData = tableData.filter(data =>
                    data.tenantContactName.toUpperCase().match(t.searchByTenantContactName.toLocaleUpperCase()))
            }

            // Sort alphabetically
            tableData = tableData.sort((a, b) => a.tenantName > b.tenantName ? 1 : -1)

            return tableData
        },

    },

    methods: {

        /**
         * Get Collection Data
         *
         * Fetch the data from the collection and assign them to the class state.
         *
         * @returns {Promise<void>}
         */
        async getTenantsCollectionData() {
            const t = this
            let tenantsCollectionData = []

            await t.$firebase.db.collection('tenants')
                .onSnapshot(snapshot => {

                    // Clear the array to avoid duplications
                    tenantsCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only push active documents
                        if (!document.hasOwnProperty('delete')) tenantsCollectionData.push(document)

                    })

                    // Assign the documents in one and set isLoaded to true
                    t.tenantsCollectionData = tenantsCollectionData
                    t.isTenantsCollectionDataLoaded = true
                })
        },

        /**
         * Close Right Panel
         *
         * When closing the form, reset it to ensure it opens as new.
         */
        closeRightPanel() {
            const t = this

            t.isFormReadOnly = true
            t.isRightPanelOpen = false
        },

        /**
         * Edit Form
         *
         * Toggle the read only state of the form.
         */
        editForm() {
            const t = this

            t.isFormReadOnly = !t.isFormReadOnly;
        },

        /**
         * Emitted Close Right Panel
         *
         * Take the emitted message and call to close the right panel.
         *
         * @returns {Promise<void>}
         */
        async emittedCloseRightPanel() {
            const t = this

            t.closeRightPanel()
        },

        /**
         * Emitted Delete From Dialog
         *
         * Take the emitted message from the 'deleteIconDialog', and mark the selected document as deleted.
         *
         * @returns {Promise<void>}
         */
        async emittedDeleteFromDialog() {
            const t = this

            const deleteDocument = await t.MIX_deleteDocument('tenants', t.selectedItem.id)

            t.closeRightPanel()

            t.MIX_renderConfirmationAlert(
                deleteDocument,
                'Successfully Deleted Tenant',
                'Error Deleting Tenant')
        },

        /**
         * Open Right Panel
         *
         * Open the CRUD form.
         * If the form is opened using the new button, it will be blank.
         * If the form is opened from the table, it will be assigned here and populated.
         *
         * @param item JSON containing the selected data, if required
         */
        openRightPanel(item) {
            const t = this

            if (!item?.id) t.isFormReadOnly = false
            t.selectedItem = item

            t.isRightPanelOpen = true
        },

    },

    async mounted() {
        const t = this

        await t.getTenantsCollectionData()
    }

}
</script>

<style scoped>
.borderLeft {
    border-left: 4px solid #999999;
    min-height: calc(100vh - 128px);
}
</style>
