<template>

    <v-hover v-slot="{hover}">
        <v-icon :class="hover ? 'editIcon-icon-hover' : isActive ? 'editIcon-icon-active' : 'editIcon-icon'"
                :color="hover||isActive ? 'white' : 'warning'"
                class="icons8-edit"
                size="32"/>
    </v-hover>

</template>

<script>
export default {

    name: "editIcon",

    props: ['isActive'],

}
</script>

<style scoped>
.editIcon-icon {
    border-radius: 50%;
    padding: 8px;
    height: 48px;
    transition: 0.3s;
    width: 48px;
}

.editIcon-icon-hover {
    background: var(--v-warning-base);
    border-radius: 50%;
    cursor: pointer;
    padding: 8px;
    height: 48px;
    transition: 0.3s;
    width: 48px;
}

.editIcon-icon-active {
    background: var(--v-warning-base);
    border-radius: 50%;
    cursor: pointer;
    padding: 8px;
    height: 48px;
    transition: 0.3s;
    width: 48px;
}
</style>
