<!--
REGISTER

Renders the registration form, containing the following:
	- Email (input)
	- Full Name (input)
	- Contact Number (input)
	- Position (input)
	- T&C's (checkbox)
	- Log In (button)
	- Next (button)
	- Register (link)
	- Rest Password (link)
-->
<template>
    <div class="d-flex justify-center pa-4" style="width: 100%">
        <div class="d-flex flex-column align-center" style="max-width: 512px; width: 100%">

            <!--Logo-->
            <div class="d-flex justify-center rounded-lg">
                <v-img :src="require('@/assets/company_logo_light.svg')" max-width="512" class="rounded-lg"/>
            </div>

            <!--Welcome text-->
            <app-text category="text-large" class="justify-center grey--text mt-4" style="letter-spacing: 2px">
                One Central Square SWAPP
            </app-text>

            <v-divider class="my-4" style="width: 100%"/>

            <!--Title-->
            <app-text category="text-large" class="primary--text">Registration</app-text>

            <!--Instruction-->
            <app-text class="mt-4">
                Please use the form below to register as a user.
            </app-text>

            <!--Form-->
            <div style="width: 100%">
                <firebase-auth-register/>
            </div>

            <!--Register-->
            <app-text category="text-default" class="justify-center mb-4">Don't have an account?
                <router-link :to="{ path: 'register' }" replace class="btn btn-link">
                    Register
                </router-link>
            </app-text>

            <!--Reset-->
            <app-text category="text-default" class="justify-center">Forgot Password?
                <router-link :to="{ path: 'reset-password' }" replace class="btn btn-link">
                    Reset password
                </router-link>
            </app-text>

        </div>
    </div>
</template>

<script>
export default {
    name: "Register",
};
</script>
