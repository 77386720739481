<template>
    <v-col cols="12" xs="12" class="pa-0 ">

        <!--Start of USER-INPUT-->
        <v-form>

            <!--Password Criteria-->
            <div @click="isPasswordCriteriaVisible = !isPasswordCriteriaVisible"
                 class="rounded-lg my-4 pa-4"
                 style="border: 1px solid lightgrey">

                <div class="d-flex align-center">
                    <app-text category="text-default">Passwords must meet the following criteria:</app-text>
                    <v-spacer/>
                    <v-icon class="ml-4"
                            :class="isPasswordCriteriaVisible ? 'icons8-collapse-arrow' : 'icons8-expand-arrow'"/>
                </div>

                <ul v-if="isPasswordCriteriaVisible">
                    <li>
                        <app-text category="text-default">Be at least 8 characters long</app-text>
                    </li>
                    <li>
                        <app-text category="text-default">Contain at least 1 upper case character</app-text>
                    </li>
                    <li>
                        <app-text category="text-default">Contain at least 1 lower case character</app-text>
                    </li>
                    <li>
                        <app-text category="text-default">Contain at least 1 number</app-text>
                    </li>
                    <li>
                        <app-text category="text-default">
                            Contain at least 1 special character from
                            <span class="font-weight-bold">? # @ ! £ $ % &</span>
                        </app-text>
                    </li>
                </ul>

            </div>

            <app-input input-type="textInput"
                       append-icon="icons8-envelope"
                       :error="errors.email"
                       :error-messages="errors.emailErrorMessage"
                       label="Email"
                       v-model.trim="email"/>

            <!--Action Buttons (Login | Reset Password)-->
            <v-row no-gutters>

                <v-col cols="12">
                    <app-btn @click.native="MIX_go('/login')"
                             block
                             class="mt-4 grey lighten-1"
                             label="Back to Log In"/>
                </v-col>

                <v-spacer/>

                <v-col cols="12">
                    <app-btn @click.native="validateEmail"
                             block
                             class="mt-4 primary"
                             label="Reset Password"/>
                </v-col>

            </v-row>

        </v-form>
    </v-col>
</template>

<script>
export default {

    name: "ResetPassword",

    data: () => ({
        inputBackgroundColor: "white",
        email: "",
        errors: {
            email: false,
            emailErrorMessage: ''
        },
        isPasswordCriteriaVisible: false,
    }),

    methods: {

        validateEmail() {
            const t = this
            const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


            t.errors.email = false
            t.errors.emailErrorMessage = ''

            if (!t.email.trim()) {
                t.errors.email = true
                t.errors.emailErrorMessage = 'Email required'
            } else if (!emailRegex.test(t.email.trim())) {
                t.errors.email = true
                t.errors.emailErrorMessage = 'Email is not valid'
            }

            if (!Object.values(t.errors).includes(true)) {
                t.resetPassword()
            }
        },

        resetPassword: function () {
            const t = this
            t.email = t.email.toLowerCase()

            t.$firebase.auth
                .sendPasswordResetEmail(t.email)
                .then(function () {
                    // Email sent.
                    t.MIX_alert(1, "A link to reset your password has been sent to your email address. Check your inbox.", null, null)
                })
                .catch(function (error) {
                    t.MIX_alert(-1, error, null, null)
                })

        },
    },

};
</script>
