import {mapActions} from 'vuex';

const fsDialog_mixins = {

	data: () => ({}),

	methods: {
		...mapActions('fsDialog_store', ['ACT_fsDialog']),

		MIX_fsDialog(show, title, dynamicComponent) {
			const payload = {};
			payload.title = title;
			payload.show = show;
			payload.dynamicComponent = dynamicComponent;
			this.ACT_fsDialog(payload);
		},
	}
}

export default {
	install(Vue, options) {
		Vue.mixin(fsDialog_mixins)
	}
};
