<!--
QR CODE for SWAPPING IN/OUT

Renders a QR code for a site (accessible through Sites).
The QR code is also printable with company branding, QR code, site name, site telephone number and portal domain.
-->
<template>
    <v-dialog v-model="qrcodeOptions.showPopup" fullscreen style="position: relative; z-index: 999">
        <v-card>

            <!--Close and Print buttons-->
            <v-card-actions class="noprint justify-center">

                <v-btn color="grey lighten-1" @click="closeQrCode()">Close</v-btn>

                <v-btn color="black white--text" @click="exportCode()">Print</v-btn>

            </v-card-actions>

            <!--Title-->
            <div class="noprint">
                <app-text category="text-medium" class="darkgrey white--text pa-4">
                    Location {{ qrcodeOptions.locationName }} QR CODE
                </app-text>
            </div>

            <!--
            Printable QR Code
              Renders a printable poster containing company information and the QR code
            -->
            <div class="d-flex flex-column align-center">

                <!--Company logo-->
                <v-img :src="require('@/assets/company_logo_white.svg')" contain width="100%" max-height="128"
                       class="my-8"/>

                <!--Instruction-->
                <app-text category="text-xxlarge-bold" class="primary white--text text-center py-4 mb-8"
                          style="width: 100%">SWAPP In/Out
                </app-text>

                <!--QR Code-->
                <v-card-text class="text-center" id="downloadCode">
                    <qrcode-vue :value="qrcodeOptions.value"
                                :size="qrcodeOptions.size"
                                :level="qrcodeOptions.level"
                                :id="qrcodeOptions.id"
                                :renderAs="qrcodeOptions.renderAs"
                                :background="qrcodeOptions.background"
                                :foreground="qrcodeOptions.foreground">
                    </qrcode-vue>
                </v-card-text>

                <!--Site name-->
                <app-text category="text-xxlarge-bold" class="mt-8">{{ qrcodeOptions.siteName }}</app-text>

                <!--Site telephone number-->
                <app-text category="text-xlarge" class="mt-8">{{ qrcodeOptions.siteTelephone }}</app-text>

                <!--Portal domain-->
                <app-text category="text-large" class="mt-8">To SWAPP In / Out download the App from</app-text>
                <app-text category="text-xxlarge-bold" class="primary--text">{{ appNameSpace }}</app-text>

                <div style="height: 1px; background: lightgrey; width: 100%" class="my-8"/>

                <!--Vindico branding-->
                <v-img :src="require('@/assets/vlogo-purple.png')" contain max-height="24"/>
                <app-text category="text-default" class="grey--text mt-2">Created by Vindico</app-text>

            </div>

        </v-card>
    </v-dialog>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import * as htmlToImage from "html-to-image";
import {saveAs} from "file-saver";
import {mapState} from "vuex";
import {appNameSpace} from '../../../package.json'

export default {
    name: 'QrCodeGenerator',

    data: () => ({
        appNameSpace: appNameSpace
    }),

    components: {
        QrcodeVue
    },

    computed: {
        ...mapState('qr_code_generator_store/', {
            qrcodeOptions: state => state.qrcode_generator,
        })
    },

    methods: {
        closeQrCode() {
            this.MIX_generateCode(false, '', 300, "H", "qrcode", "canvas", "#ffffff", "#000000", false, '');
        },
        exportCode() {
            window.print();
        },
        downloadWithVueResource() {
            htmlToImage.toBlob(document.getElementById("downloadCode")).then(function (blob) {
                window.saveAs(blob, "QRcode.png");
            });
        },
    }

}
</script>

<style>
@media print {
    .noprint {
        display: none !important;
        clear: both !important;
    }
}
</style>
