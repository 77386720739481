<template>
    <v-row class="ma-4" no-gutters>

        <!--Title-->
        <page-title icon="icons8-online-support"
                    pageTitle="App Support"
                    description="Have any comment or suggestions? Send us a message and we'll help where we can"
                    :divider="true"/>

        <!--App support form-->
        <v-form style="width: 100%">

            <!--Support type | Asset number-->
            <v-row no-gutters>

                <!--Title-->
                <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                       :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'">
                    <app-input inputType="textInput"
                               :error="errors.appSupportTitle"
                               label="Title"
                               v-model="form.appSupportTitle"/>
                </v-col>

                <!--Type-->
                <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                       :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'">
                    <app-input inputType="select"
                               :error="errors.appSupportType"
                               :items="appSupportTypesOptionsData"
                               label="Support Type"
                               v-model="form.appSupportType"/>
                </v-col>

            </v-row>

            <!--Description-->
            <app-input inputType="textArea"
                       class="mt-4"
                       :error="errors.appSupportDescription"
                       label="Description"
                       placeholder="Try and give as much detail as you can so we can help you out"
                       rows="8"
                       v-model="form.appSupportDescription"/>

            <!--Action buttons-->
            <div class="d-flex justify-end mt-4">

                <app-btn @click.native="validate"
                         color="success"
                         icon="icons8-checkmark-yes"
                         label="Submit"/>

            </div>

        </v-form>

    </v-row>
</template>

<script>
export default {

    name: "appSupport",

    data: () => ({
        form: {
            appSupportType: '',
            appSupportTitle: '',
            appSupportDescription: '',

            createdDateTime: '',
            createdUserData: {},
            modifiedDateTime: '',
            modifiedUserData: {}
        },
        errors: {
            appSupportType: false,
            appSupportTitle: false,
            appSupportDescription: false,
        },
    }),

    methods: {

        /**
         * Validate
         *
         * Validate fields for presence, if there are no errors, call to save.
         */
        validate() {
            const t = this

            t.form.appSupportTitle.trim() ? t.errors.appSupportTitle = false : t.errors.appSupportTitle = true
            t.form.appSupportType.trim() ? t.errors.appSupportType = false : t.errors.appSupportType = true
            t.form.appSupportDescription.trim() ? t.errors.appSupportDescription = false : t.errors.appSupportDescription = true

            if (!Object.values(t.errors).includes(true)) {
                t.saveDocument()
            }
        },

        /**
         * Save Questions
         *
         * Save the document with the collection name and form details.
         *
         * @returns {Promise<void>}
         */
        saveDocument: async function () {
            const t = this

            // Create document
            const createDocumentResult = await t.MIX_createDocument('appSupport', t.form)

            // Call for a confirmation alert
            t.renderConfirmationAlert(createDocumentResult)
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok.
         *  -> 'Error' alert if something when wrong.
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `App Support request sent`, null, null)
                t.clearFieldsOnSuccessfulSubmission()
            } else {
                t.MIX_alert(-1, `Error sending App Support request`, null, null)
            }
        },

        /**
         * Clear Fields On Successful Submission
         *
         * If the submission was successful, clear the fields to assert completeness.
         */
        clearFieldsOnSuccessfulSubmission() {
            const t = this

            t.form.appSupportTitle = ''
            t.form.appSupportType = ''
            t.form.appSupportDescription = ''
        }

    },

    async mounted() {
        const t = this

    }
}
</script>
