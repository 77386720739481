<!--
Unauthorised

Renders a page for user's with accounts that are awaiting approval.
The page is locked down until it is approved.
-->
<template>
    <div class="pa-4" style="width: 100%">

        <!--Page title-->
        <page-title icon="icons8-private" :page-title="computedPageTitle"/>

        <v-divider/>

        <!--Welcome | Information-->
        <v-card class="rounded-lg mt-4 pa-4" flat max-width="512">

            <!--Welcome-->
            <app-text category="text-default-bold">
                Hi {{ computedUserName }},
            </app-text>

            <!--Information-->
            <app-text class="mt-4">
                {{ computedStatusMessage.message }}
            </app-text>

            <!--Instructional text-->
            <app-text class="mt-4">
                {{ computedStatusMessage.instruction }}
            </app-text>

            <!--Instructional text-->
            <app-text v-if="GET_currentUser.userType === 'Tenant' && userStatus === 'PENDING' && !GET_currentUser.userCompanyId"
                      class="mt-4">
                Please select the company you work for from the list below
            </app-text>

            <!--Tenants Selection-->
            <!--WATCHED PROPERTY: This is watched for changes, so the Tenant ID can be set against the current User-->
            <app-input v-if="GET_currentUser.userType === 'Tenant' && userStatus === 'PENDING' && !GET_currentUser.userCompanyId"
                       input-type="select"
                       class="mt-4"
                       :items="tenantsCollectionData"
                       item-text="tenantName"
                       label="Tenants"
                       :return-object="true"
                       v-model="selectedTenant"/>

        </v-card>

    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {

    name: "unauthorised",

    data: () => ({
        selectedTenant: {},
        tenantsCollectionData: [],
        userStatus: 'PENDING',
    }),

    computed: {
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
        }),

        /**
         * Computed Page Title
         *
         * Returns an icon and page title based on the user's status.
         *
         * @returns {{icon: string, title: string}} - icon and title for page header
         */
        computedPageTitle() {
            const t = this
            let pageTitle

            if (t.userStatus.toUpperCase() === 'ARCHIVED') pageTitle = 'Archived'
            if (t.userStatus.toUpperCase() === 'PENDING') pageTitle = 'Pending'
            if (t.userStatus.toUpperCase() === 'REJECTED') pageTitle = 'Rejected'
            if (t.userStatus.toUpperCase() === 'SUSPENDED') pageTitle = 'Suspended'
            if (t.GET_currentUser.delete) pageTitle = 'Deleted'

            return pageTitle
        },

        /**
         * Computed UserName
         *
         * Returns the current user's first name from their stored userName.
         *
         * @returns {name} - the current user's first name.
         */
        computedUserName() {
            const t = this

            return t.GET_currentUser.userName.split(' ')[0]
        },

        /**
         * Computed Status Message
         *
         * Returns an information message based, on the user's status.
         *
         * @returns {string} -
         */
        computedStatusMessage() {
            const t = this
            let message = ''
            let instruction = ''

            if (t.userStatus.toUpperCase() === 'PENDING') {
                message = 'Your account is temporarily restricted while awaiting approval.'
                instruction = 'You will be notified when it is ready.'
            } else if (['ARCHIVED', 'REJECTED', 'SUSPENDED'].includes(t.userStatus.toUpperCase())) {
                message = `Your account has been ${t.userStatus}.`
                instruction = 'Please contact your administrator for further information.'
            } else {
                message = 'Your account has now been approved.'
                t.GET_currentUser.userStatus = 'APPROVED'
                t.MIX_go('/')
            }

            return {message, instruction}
        },

    },

    methods: {

        async getTenantsCollectionData() {
            const t = this
            let collectionData = []

            await t.$firebase.db.collection('tenants')
                .orderBy('tenantName')
                .onSnapshot(snapshot => {

                    collectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        if (!document.hasOwnProperty('delete')) collectionData.push(document)
                    })

                    t.tenantsCollectionData = collectionData
                })
        },

        /**
         * Save Selected Tenant
         *
         * Save the selected Tenant ID to the current User's document.
         *
         * @returns {Promise<void>}
         */
        async saveSelectedTenant() {
            const t = this

            const documentResult = await t.MIX_updateDocumentFieldsById(
                'users',
                t.GET_currentUser.id,
                {userCompanyId: t.selectedTenant.id}
            )

            t.MIX_renderConfirmationAlert(
                documentResult,
                'Successfully Saved Tenant',
                'Error Saving Tenant'
            )
        },

    },

    watch: {

        selectedTenant: {
            handler() {
                const t = this
                t.saveSelectedTenant()
            }
        }

    },

    async mounted() {
        const t = this

        await t.getTenantsCollectionData()

        await t.$firebase.db
            .collection('users')
            .doc(t.GET_currentUser.id)
            .onSnapshot(doc => {

                // Listen for changes to, and set, the user status
                t.userStatus = doc.data().userStatus

                // If the current user is a visitor, automatically forward on to the home page
                if (doc.data().userLevel === 'VU') {
                    t.MIX_go('/')
                }

            })

    }
}
</script>
