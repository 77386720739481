<template>
    <div>

        <v-text-field v-if="inputType === 'textInput'"
                      @input="updateValue($event)"
                      :append-icon="appendIcon"
                      :background-color="backgroundColor ? 'backgroundColor' : 'white'"
                      class="rounded-lg"
                      :clearable="clearable"
                      :disabled="disabled || isFormReadOnly"
                      :error="error"
                      :error-messages="errorMessages"
                      filled
                      flat
                      hide-details="auto"
                      :hint="hint"
                      :label="label"
                      :outlined="!outlined && !isFormReadOnly"
                      :placeholder="placeholder"
                      :prepend-inner-icon="prependInnerIcon"
                      :type="type && type"
                      :value="value"/>

        <v-textarea v-if="inputType === 'textArea'"
                    @input="updateValue($event)"
                    :background-color="backgroundColor ? 'backgroundColor' : 'white'"
                    class="rounded-lg"
                    :disabled="disabled || isFormReadOnly"
                    :error="error"
                    :error-messages="errorMessages"
                    filled
                    flat
                    hide-details="auto"
                    :label="label"
                    :outlined="!outlined && !isFormReadOnly"
                    :placeholder="placeholder && placeholder"
                    :hint="hint && hint"
                    :rows="rows"
                    :value="value"/>

        <v-select v-if="inputType === 'select'"
                  @input="updateValue($event)"
                  :background-color="backgroundColor ? 'backgroundColor' : 'white'"
                  class="rounded-lg"
                  :clearable="clearable"
                  :chips="chips"
                  :deletable-chips="deletableChips"
                  :disabled="disabled || isFormReadOnly"
                  :error="error"
                  :error-messages="errorMessages"
                  filled
                  flat
                  hide-details="auto"
                  :items="items"
                  :item-text="itemText && itemText"
                  item-value="value"
                  :label="label"
                  :multiple="multiple"
                  :outlined="!outlined && !isFormReadOnly"
                  :return-object="returnObject"
                  :rows="rows"
                  :small-chips="smallChips"
                  :value="value"/>

        <v-autocomplete v-if="inputType === 'autoComplete'"
                        :append-icon="appendIcon"
                        :background-color="backgroundColor ? 'backgroundColor' : 'white'"
                        :chips="chips"
                        :clearable="clearable"
                        :deletable-chips="deletableChips"
                        :disabled="disabled || isFormReadOnly"
                        :error="error"
                        :error-messages="errorMessages"
                        :hide-selected="hideSelected"
                        :item-text="itemText && itemText"
                        :item-value="itemValue"
                        :items="items"
                        :label="label"
                        :multiple="multiple"
                        :outlined="!outlined && !isFormReadOnly"
                        :placeholder="placeholder"
                        :prepend-inner-icon="prependInnerIcon"
                        :return-object="returnObject"
                        :small-chips="smallChips"
                        :value="value"
                        class="rounded-lg"
                        color="accent2"
                        filled
                        flat
                        hide-details="auto"
                        @input="updateValue($event)"/>

    </div>
</template>

<script>
export default {
    name: "appInput",

    props: [
        'appendIcon',
        'backgroundColor',
        'clearable',
        'chips',
        'deletableChips',
        'disabled',
        'error',
        'errorMessages',
        'inputType',
        'itemValue',
        'hideSelected',
        'hint',
        'items',
        'isFormReadOnly',
        'itemText',
        'label',
        'multiple',
        'outlined',
        'placeholder',
        'prependInnerIcon',
        'returnObject',
        'rows',
        'smallChips',
        'type',
        'value',
        'vBind',
        'vOn',
    ],

    methods: {
        updateValue(value) {
            this.$emit('input', value)
        }
    }
}
</script>

<style>
/* Not being used. This counter-acts the margin-top added when a text field is un-disabled */
.removeMarginTopFromInput input {
    margin-top: 0 !important;
}
</style>
