<template>
    <div>

        <!--Site name | Action buttons (edit | delete)-->
        <v-row class="d-flex align-center white appbackground pa-4" no-gutters>

            <app-text>{{ form.siteName }}</app-text>

            <v-spacer/>

            <!--Action buttons (edit | delete)-->
            <div class="d-flex align-center">

                <edit-icon v-if="['SA'].includes(GET_currentUser.userLevel) && formMode !== 'New'"
                           @click.native="editItem()"/>

                <!--Edit-->
                <!--<v-icon v-if="['SA'].includes(GET_currentUser.userLevel) && formMode !== 'New'"-->
                <!--        @click.native="editItem()"-->
                <!--        class="icons8-edit"-->
                <!--        :class="formReadOnly ? 'frc-icon' : 'frc-icon-edit-active'"-->
                <!--        :color="formReadOnly ? 'warning' : 'white'"-->
                <!--        size="32"/>-->

                <!--Delete-->
                <v-icon
                    v-if="['SA'].includes(GET_currentUser.userLevel) && formMode !== 'New' && form.delete === undefined"
                    @click.native="deleteItem(form.id)"
                    class="icons8-trash frc-icon"
                    color="error"
                    size="32"/>

            </div>

        </v-row>

        <!--Page content-->
        <v-container fluid class="pa-0">
            <v-row class="ma-0 pa-0" no-gutters>

                <!--Tabs-->
                <!-- Overview | Locations-->
                <v-tabs class="border-bottom-grey" v-model="tabs" :height="buttonSizeDefault">
                    <v-tabs-slider color="accent"/>

                    <!--Overview-->
                    <v-tab href="#overview">
                        <app-text category="text-small">Overview</app-text>
                    </v-tab>

                    <!--Locations-->
                    <v-tab v-if="['SA'].includes(GET_currentUser.userLevel)" href="#locations">
                        <app-text category="text-small">Locations</app-text>
                    </v-tab>

                </v-tabs>

                <!--Tab Content-->
                <!-- Overview | Locations-->
                <v-tabs-items v-model="tabs" class="appbackground" style="width: 100%">

                    <!--Overview tab content-->
                    <v-tab-item value="overview" class="pa-0">

                        <!--Description | Action buttons-->
                        <v-row class="pa-4 pb-0" no-gutters>

                            <!-- Description -->
                            <v-col class="pa-0" cols="12">

                                <!--Info-->
                                <app-text category="text-default">
                                    Use the form below to Edit Site or Site Manager details.<br/>
                                    When you have added a site, you must add at least one location to it in the
                                    Locations tab.
                                </app-text>

                                <!--Missing location error text-->
                                <app-text v-if="!form.locations.length"
                                          category="text-default-bold" class="error--text">
                                    You must add at least one location per site.
                                </app-text>

                                <v-divider class="mt-4"/>

                            </v-col>

                            <!-- Action buttons -->
                            <v-col class="d-flex" cols="12">

                                <!--Undelete-->
                                <appbutton v-if="formMode !== 'New' && form.delete !== undefined"
                                           @click.native="undeleteItem(form.id)"
                                           class="mr-1"
                                           :class="{ 'disabled': formMode !== 'View' }"
                                           icon="icons8-process"
                                           :label="$t(`buttons.undelete`)"
                                           type="grey"/>

                                <v-spacer/>

                            </v-col>

                        </v-row>

                        <!--Form-->
                        <v-row class="ma-4 mt-0" no-gutters>

                            <!--Site name-->
                            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 && 'mr-2'"
                                           :error="errors.siteName"
                                           :error-messages="errors.siteNameErrorMessage"
                                           label="Site Name"
                                           :is-form-read-only="formReadOnly"
                                           v-model.trim="form.siteName"/>
                            </v-col>

                            <!--Site telephone-->
                            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 && 'ml-2'"
                                           :error="errors.siteTelephone"
                                           :error-messages="errors.siteTelephoneErrorMessage"
                                           :is-form-read-only="formReadOnly"
                                           label="Telephone"
                                           type="number"
                                           v-model.trim="form.siteTelephone"/>
                            </v-col>

                            <!--Address-->
                            <v-col class="mt-4" cols="12">
                                <app-text category="text-medium" class="darkgrey--text">
                                    Address
                                </app-text>
                                <v-divider class="mt-2"/>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 && 'mr-2'"
                                           :is-form-read-only="formReadOnly"
                                           label="Address Line 1"
                                           v-model="form.siteAddress.addressLine1"/>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 && 'ml-2'"
                                           :is-form-read-only="formReadOnly"
                                           label="Address Line 2"
                                           v-model="form.siteAddress.addressLine2"/>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 && 'mr-2'"
                                           :is-form-read-only="formReadOnly"
                                           label="Address Line 3"
                                           v-model="form.siteAddress.addressLine3"/>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 && 'ml-2'"
                                           :is-form-read-only="formReadOnly"
                                           label="Town"
                                           v-model="form.siteAddress.town"/>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 && 'mr-2'"
                                           :is-form-read-only="formReadOnly"
                                           label="County"
                                           v-model="form.siteAddress.county"/>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 && 'ml-2'"
                                           :is-form-read-only="formReadOnly"
                                           label="Postcode"
                                           v-model="form.siteAddress.postcode"/>
                            </v-col>

                            <!--Site Manager-->
                            <v-col class="mt-4" cols="12">
                                <app-text category="text-medium" class="darkgrey--text">Site Manager</app-text>
                                <app-text category="text-small" class="grey--text">
                                    You can set the site manager here, but their details will need to be changed in
                                    Staff Directory
                                </app-text>
                                <v-divider class="mt-2"/>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                                <v-select @change="setNewSiteManager"
                                          background-color="white"
                                          class="rounded-lg mt-4"
                                          :class="$vuetify.breakpoint.width >= 600 && 'mr-2'"
                                          :disabled="formReadOnly"
                                          filled
                                          flat
                                          hide-details="auto"
                                          :items="usersData"
                                          item-text="userName"
                                          label="Name"
                                          :outlined="!formReadOnly"
                                          return-object
                                          v-model="form.siteContact.contactName"/>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 && 'ml-2'"
                                           :disabled="true"
                                           :is-form-read-only="formReadOnly"
                                           label="Position"
                                           v-model="form.siteContact.contactPosition"/>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 && 'mr-2'"
                                           :disabled="true"
                                           :is-form-read-only="formReadOnly"
                                           label="Telephone"
                                           v-model="form.siteContact.contactTelephone"/>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 && 'ml-2'"
                                           :disabled="true"
                                           :is-form-read-only="formReadOnly"
                                           label="Mobile"
                                           v-model="form.siteContact.contactMobile"/>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 && 'mr-2'"
                                           :disabled="true"
                                           :is-form-read-only="formReadOnly"
                                           label="Email"
                                           v-model="form.siteContact.contactEmail"/>
                            </v-col>

                            <!-- Notes -->
                            <v-col class="mt-4" cols="12">
                                <app-text category="text-medium" class="darkgrey--text">
                                    Notes
                                </app-text>
                                <v-divider class="mt-2"/>
                            </v-col>
                            <v-col>
                                <app-input input-type="textArea"
                                           class="mt-4"
                                           :is-form-read-only="formReadOnly"
                                           label="Notes"
                                           rows="4"
                                           v-model="form.siteNotes"/>
                            </v-col>

                            <!--Save | Cancel-->
                            <v-col cols="12" class="d-flex justify-end mt-4 mb-0">

                                <v-spacer/>

                                <!--Save-->
                                <app-btn v-if="formMode === 'Edit' || formMode === 'New'"
                                         @click.native="validateForm()"
                                         color="success"
                                         icon="icons8-save"
                                         label="Save"/>

                            </v-col>

                        </v-row>

                        <!--Created and Modified info -->
                        <v-col class="mb-4" cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                               v-if="formMode !== 'New'">

                            <v-divider/>

                            <div>
                                <app-text category="text-small-bold" class="grey--text mt-4">
                                    Created:
                                    <span class="font-weight-regular">
												{{ form.createdUserData.userName
                                        }} - {{ MIX_formatDateTime(form.createdDateTime, 'x', 'DD-MMM-YYYY') }}
											</span>
                                    |
                                    Modified:
                                    <span class="font-weight-regular">
												{{ form.modifiedUserData.userName
                                        }} - {{ MIX_formatDateTime(form.modifiedDateTime, 'x', 'DD-MMM-YYYY') }}
											</span>
                                </app-text>
                            </div>

                        </v-col>

                    </v-tab-item>

                    <!--Locations tab content-->
                    <v-tab-item value="locations" class="pa-0">

                        <!--Overview-->
                        <v-row class="pa-4" no-gutters>

                            <!--Description-->
                            <v-col class="pa-0" cols="12">

                                <!--Info-->
                                <app-text category="text-default">
                                    Use the form below to Add Locations to Sites.
                                </app-text>

                                <v-divider class="my-4"/>

                            </v-col>

                            <!--Add button | Deleted switch-->
                            <v-col class="d-flex align-center">

                                <!--Add-->
                                <app-btn
                                    @click.native="MIX_location(true, 'sites', form.id, form.locations, {id: form.id, siteName: form.siteName, siteManager: form.siteManager, siteArea: form.siteArea, siteSector: form.siteSector, sitePostocode: form.siteAddress.sitePostcode})"
                                    class="mr-2"
                                    color="success"
                                    icon="icons8-edit-property"
                                    label="Add New Location"/>

                                <v-spacer/>

                                <!--Deleted switch-->
                                <div v-if="['DA'].includes(GET_currentUser.userLevel)" class="d-flex">
                                    <app-text category="text-default-bold" class="grey--text">DELETED</app-text>
                                    <v-switch @change="getLocations(GET_openItem)"
                                              class="primary--text ml-4"
                                              inset
                                              v-model="showDeletedLocations"/>
                                </div>

                            </v-col>

                        </v-row>

                        <!--Table-->
                        <v-data-table class="rounded-lg mx-4"
                                      :headers="locationsHeaders"
                                      :items="locationsTableData"
                                      :items-per-page="10">

                            <!--Location name-->
                            <template v-slot:[`item.locationName`]="{ item }">
                                <app-text category="text-default">{{ item.locationName }}</app-text>
                            </template>

                            <!--Staff-->
                            <template v-slot:[`item.staff`]="{ item }">
                                <app-text category="text-default" class="justify-center">
                                    {{ noOfUsersAtLocation(item.id, 'Staff') }}
                                </app-text>
                            </template>

                            <!--Total-->
                            <template v-slot:[`item.totalCount`]="{ item }">
                                <app-text category="text-default" class="justify-center">
                                    {{ noOfUsersAtLocation(item.id, '') }}
                                </app-text>
                            </template>

                            <!--Actions-->
                            <template v-slot:[`item.actions`]="{ item }">

                                <!--QR Poster-->
                                <v-icon @click="openQrCode(item.id, item, form.siteName, form.siteTelephone)"
                                        class="icons8-qr-code"
                                        color="success"
                                        size="32"/>

                                <!--Delete-->
                                <v-icon
                                    @click="MIX_deleteCollectionArray(item.id, item, 'locations', 'sites', form.id)"
                                    class="icons8-trash ml-4"
                                    color="error"
                                    size="32"/>

                            </template>

                        </v-data-table>

                    </v-tab-item>

                </v-tabs-items>

            </v-row>
        </v-container>

    </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'; // Map the Getters from the Vuex Store

export default {
    name: 'Site',

    data: () => ({
        collection: 'sites',
        collectionTitle: 'Site',
        collectionItem: 'site',
        collectionItemTitle: 'Site',
        errors: {
            siteName: false,
            siteNameErrorMessage: '',
            locations: false,
            siteTelephone: false,
            siteTelephoneErrorMessage: '',
        },
        form: {
            id: '',
            siteName: '',
            siteTelephone: '',
            siteAddress: {
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                town: '',
                county: '',
                postcode: '',
            },
            siteContact: {
                contactName: '',
                contactPosition: '',
                contactTelephone: '',
                contactMobile: '',
                contactEmail: ''
            },
            siteManager: '',
            siteNotes: '',
            locations: [],
            createdUserData: {},
            createdDateTime: '',
            modifiedUserData: {},
            modifiedDateTime: '',
        },
        formBackground: 'grey lighten-3',
        formMode: '',
        formReadOnly: true,
        locationsTableData: [],
        showDeletedLocations: false,
        tabs: 'overview',
        usersData: [],
        usersTableData: [],
    }),

    computed: {
        ...mapGetters({
            GET_openItem: 'GET_openSites',
            GET_panelLayout: 'GET_panelLayout', // Panel Layout used for Layout Structure
            GET_openSites: 'GET_openSites',
            GET_confirmBoxResult: 'confirmBox_store/GET_confirmBoxResult', // Get Confirm Box Result
            GET_currentUser: 'GET_currentUser',
        }),

        locationsHeaders() {
            return [
                {text: 'Name', value: 'locationName', align: 'left', sortable: false, hidden: false, hide: false},
                {text: 'Staff', value: 'staff', align: 'center', sortable: false, hidden: false, hide: false},
                {text: 'Total', value: 'totalCount', align: 'center', sortable: false, hidden: false, hide: false},
                {text: 'Actions', value: 'actions', align: 'center', sortable: false, hidden: false, hide: false},
            ]
        },
    },

    methods: {
        ...mapActions({'ACT_openItem': 'ACT_openSites', 'ACT_openSites': 'ACT_openSites'}),

        /**
         * Select New Site Manager
         */
        setNewSiteManager() {
            const t = this
            const selectedManager = t.form.siteContact.contactName

            t.form = {
                ...t.form,
                siteContact: {
                    contactName: selectedManager.userName,
                    contactPosition: selectedManager.userPosition,
                    contactTelephone: selectedManager.userTelephone,
                    contactMobile: '',
                    contactEmail: selectedManager.userEmail
                },
                siteManager: selectedManager.userName,
            }
        },

        /**
         * Get Users Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Users collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the usersData array.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('users')
            collection.onSnapshot(snapshot => {

                // Clear the users data to avoid duplications
                t.usersData = []

                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.usersData.push(document)
                    }
                })
            })
        },

        /**
         * Validate
         *
         * Validates the required fields for presence only.
         * If any of the fields are missing mark them in an errors object.
         * When there are no errors left, save the record.
         */
        validateForm() {
            const t = this

            t.errors.siteName = false
            t.errors.siteNameErrorMessage = ''
            t.errors.siteTelephone = false
            t.errors.siteTelephoneErrorMessage = ''

            // Site Name
            if (!t.form.siteName.trim()) {
                t.errors.siteName = true
                t.errors.siteNameErrorMessage = 'Site Name required'
            }

            // Telephone
            if (t.form.siteTelephone.trim()) {

                // Landline number must start 01, 02 or 03 and be either 10 or 11 digits
                if (['1', '2', '3'].includes(t.form.siteTelephone.trim()[1]) && (t.form.siteTelephone.trim().length < 10 || t.form.siteTelephone.trim().length > 11)) {
                    t.errors.siteTelephone = true
                    t.errors.siteTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
                }
                // Mobile number must start 07 and be 11 digits
                else if (['7'].includes(t.form.siteTelephone.trim()[1]) && t.form.siteTelephone.trim().length !== 11) {
                    t.errors.siteTelephone = true
                    t.errors.siteTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
                }
                // Number must start 01, 02, 03 or 07
                else if (!['0'].includes(t.form.siteTelephone[0]) || ['0', '4', '5', '6', '8', '9'].includes(t.form.siteTelephone[1])) {
                    t.errors.siteTelephone = true
                    t.errors.siteTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
                }

            }

            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                t.saveItem()
            }
        },

        async openItem() {
            const t = this

            const openItem = await t.GET_openItem; // Get Open Item Data

            switch (openItem.process) {
                case 'New':
                    t.formMode = 'New'
                    t.formReadOnly = false
                    t.formBackground = 'white'
                    break
                case 'View':
                    const item1 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
                    t.form = item1.data
                    t.formMode = 'View'
                    t.formReadOnly = true
                    t.formBackground = 'grey lighten-3'
                    break
                case 'Edit':
                    const item2 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
                    t.form = item2.data
                    t.formMode = 'Edit'
                    t.formReadOnly = false
                    t.formBackground = 'white'
                    break
            }

        },

        async undeleteItem(id) {
            const t = this

            const undeleteResult = await t.MIX_undeleteDocument(t.collection, id)

            if (undeleteResult.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} Restored`, undeleteResult.data, null)
            } else {
                t.MIX_alert(-1, `Error Restoring ${t.collectionItemTitle}`, null, undeleteResult.error)
                console.log(`undeleteResult.error = ${JSON.stringify(undeleteResult.error, null, 2)}`)
            }

            await t.refreshItemView();
        },

        deleteItem(id) {
            const t = this
            const width = t.$vuetify.breakpoint.width <= 425 ? '' : '400'

            t.MIX_confirmBox(
                {
                    show: true,
                    collection: t.collection,
                    id: id,
                    title: `Delete ${t.collectionItemTitle}`,
                    description: `Are you sure you want to delete this ${t.collectionItemTitle}: <br /><div class="mt-4"><strong>${t.form[t.collectionItem + 'Name']}</strong>?</div>`,
                    width,
                    trueButtonText: 'Yes',
                    trueButtonValue: 'YES',
                    falseButtonText: 'No',
                    falseButtonValue: 'NO',
                }
            )
        },

        editItem() {
            const t = this

            if (t.formMode === 'View') {
                t.formMode = 'Edit'
                t.formReadOnly = false
                t.formBackground = 'white'
            } else if (t.formMode === 'Edit') {
                t.formMode = 'View'
                t.formReadOnly = true
                t.formBackground = 'grey lighten-3'

                t.refreshItemView(t.form.id)
            }

        },

        cancelItem() {
            const t = this

            t.formMode = 'View'
            t.formReadOnly = true
            t.formBackground = 'grey lighten-3'

            t.refreshItemView(t.form.id)
        },

        async saveItem() {
            const t = this

            // If this is a new document, create it
            if (t.formMode === 'New') {

                const createDocumentResult = await t.MIX_createDocument(t.collection, t.form)

                if (createDocumentResult.code === 1) {
                    t.MIX_alert(1, `${t.collectionItemTitle} Saved`, null, null)

                    t.formMode = 'View'
                    t.formReadOnly = true
                    t.formBackground = 'grey lighten-3'

                    await t.refreshItemView(createDocumentResult.data.id)

                } else {
                    t.MIX_alert(-1, `This site already exists`, null, null)
                }
            }

            // If this is editing a document, update it
            else if (t.formMode === 'Edit') {

                const updateDocumentResult = await this.MIX_updateDocument(t.collection, t.form)

                if (updateDocumentResult.code === 1) {
                    t.MIX_alert(1, `Site ${t.form.clientName} was Updated`, updateDocumentResult.data, null)

                    t.formMode = 'View'
                    t.formReadOnly = true
                    t.formBackground = 'grey lighten-3'

                    await t.refreshItemView(t.form.id)

                } else {
                    t.MIX_alert(-1, 'ERROR - Could not update Site', null, updateDocumentResult.error)
                }
            }
        },

        async refreshItemView(itemId) {
            const t = this

            t.form.id = itemId
            const itemResult = await t.MIX_readDocumentById(t.collection, t.form.id)
            const payload = {}
            payload.process = 'View'
            payload.popup = false
            payload.data = itemResult.data
            t.ACT_openItem(payload)

            await t.openItem()
        },

        async getLocations(openSite) {
            const t = this

            if (JSON.stringify(openSite) !== '{}') {
                t.$firebase.db.collection('locations').where("site.id", "==", openSite.data.id)
                    .onSnapshot(function (querySnapshot) {
                        t.locationsTableData = []
                        querySnapshot.forEach(function (doc) {
                            const document = doc.data()
                            document.id = doc.id
                            if (!document.hasOwnProperty('delete')) {
                                t.locationsTableData.push(document);
                            } else if (t.showDeletedConcerns) {
                                t.locationsTableData.push(document)
                            } else {
                                // Do not Show Concern
                            }
                        })
                    })
            }
        },

        async getUsers(openSite) {
            const t = this

            if (JSON.stringify(openSite) !== '{}') {

                t.$firebase.db.collection('users').where("lastSite.siteId", "==", openSite.data.id)
                    .onSnapshot(function (querySnapshot) {

                        // Clear the array to avoid data duplication
                        t.usersTableData = []

                        querySnapshot.forEach(function (doc) {

                            const document = doc.data()
                            document.id = doc.id

                            t.usersTableData.push(document)
                        })
                    })
            }
        },

        noOfUsersAtLocation(locationId, userType) {
            let count = 0

            // If a user type is specified
            if (userType !== '') {
                for (let i = 0; i < this.usersTableData.length; i++) {
                    if (locationId === this.usersTableData[i].lastLocation.locationId && this.usersTableData[i].swappStatus === 1 && this.usersTableData[i].userType === userType) {
                        count++
                    }
                }
            } else {
                for (let i = 0; i < this.usersTableData.length; i++) {
                    if (locationId === this.usersTableData[i].lastLocation.locationId && this.usersTableData[i].swappStatus === 1) {
                        count++
                    }
                }
            }

            return count
        },

        openQrCode(id, item, siteName, siteTelephone) {
            const t = this

            t.MIX_generateCode(true, id, 300, "H", "qrcode", "canvas", "#ffffff", "#000000", true, item.locationName, siteName, siteTelephone)
        },

    },

    watch: {
        // GET CONFIRMATION RESULT (CUSTOM)
        GET_confirmBoxResult: {
            handler: async function () {
                const t = this

                if (t.GET_confirmBoxResult.data.collection === t.collection) {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {

                        const deleteResult = await t.MIX_deleteDocument(t.GET_confirmBoxResult.data.collection, t.GET_confirmBoxResult.data.id)

                        if (deleteResult.code === 1) {
                            t.MIX_alert(1, `${t.collectionItemTitle} Deleted`, deleteResult.data, null)
                            t.ACT_panelLayout({leftPanel: 12, rightPanel: 0, show: false, dynamicComponent: ''})
                        } else {
                            t.MIX_alert(-1, `Error Deleting ${t.collectionItemTitle}`, null, deleteResult.error)
                        }
                    }
                } else {
                    // Do nothing
                }
            }, deep: true
        },
    },

    async mounted() {
        const t = this

        // Fetch collection data
        await t.getUsersCollectionData()

        const openItem = await t.GET_openItem // Get Open Project Data
        t.formMode = openItem.process // Show Form in View Mode
        if (openItem.process === 'New') {
            t.formReadOnly = false
            t.formBackground = 'white'
        } else {
            t.form = openItem.data
            t.formReadOnly = true
            t.formBackground = 'grey lighten-3'
        }

        // GET LOCATIONS
        await t.getLocations(openItem)
        await t.getUsers(openItem)

    }
}
</script>

<style scoped>
.disabled {
    pointer-events: none;
    cursor: not-allowed;
}
</style>
